<!-- <app-navbar></app-navbar> -->
<div class="container" style="padding: 0px; margin: 0px; width: 100%; height: 100%">
  <div class="header">
    <app-navbar></app-navbar>
  </div>
  <div class="content">
    <div class="row" style="margin-top: 5px; margin-left: 5px; margin-right: 5px">
      <div class="col-12 ">
        <div class="row" style=" margin-left: 5px; margin-right: 5px">
          <div class="col-12 col-sm-2 " style="padding-right: 5px; padding-left: 5px">
            <div class="card" style="padding: 3px !important;">
              <div *ngFor="let x of SelectTheme">
                <div class="radio" style="
                    border: 1px solid rgba(255, 255, 255, 0.38);
                    border-radius: 6px;
                    /* box-shadow: 5px 5px 14px #3f3f3f, -5px -5px 14px black; */
                  " [ngClass]="SelectedTheme == x ? 'btn-th-active' : 'btn-th'">
                  <label><input type="radio" class="radio" [(ngModel)]="SelectedTheme" value="{{ x }}"
                      (change)="onItemChange($event)" />
                    <span style="font-size: 12px;">{{ x }}</span></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4" style="padding-right: 5px; padding-left: 5px">
            <div class="card"
              *ngIf="SelectedTheme == 'Dashboard' ||SelectedTheme ==  'KPI Validation' ||SelectedTheme ==  'AFR 4.0'
            ||SelectedTheme ==  'SOP & Alerts' || SelectedTheme == 'CO Savings'||SelectedTheme ==  'File Upload'|| SelectedTheme == 'Clinker Quality' ||SelectedTheme ==  'Kiln 4.0' ">
              <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">{{ SelectedTheme }}</div>
              </div>

              <div class="row">
                <div class="col-md-12" style="display: grid" *ngIf="imageToggle == 1">
                  <img class="img-fluid" src="../../../assets/img/pyroenq1.7.drawio.svg" alt="Pyro Process flow"
                    style="height: 70vmin; margin: auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12  col-sm-6 " style="padding-right: 5px; padding-left: 5px">
            <div class="card"
              *ngIf="SelectedTheme == 'Dashboard' ||SelectedTheme ==  'KPI Validation' ||SelectedTheme ==  'AFR 4.0'
            ||SelectedTheme ==  'SOP & Alerts' || SelectedTheme == 'CO Savings'||SelectedTheme ==  'File Upload'|| SelectedTheme == 'Clinker Quality' ||SelectedTheme ==  'Kiln 4.0'  || SelectedTheme == 'User Management' ">
              <!-- Theme 2 -->
              <div *ngIf="SelectedTheme == 'Dashboard'" style="height: 81vmin; overflow-y: scroll">
                <app-theme1></app-theme1>
              </div>

              <div *ngIf="SelectedTheme == 'KPI Validation'" style="height: 81vmin; overflow-y: scroll">
                <app-theme11></app-theme11>
              </div>
              <!-- Theme 4  -->
              <div *ngIf="SelectedTheme == 'AFR 4.0'" style="height: 81vmin; overflow-y: scroll">
                <app-theme2 *ngIf="this.dataToggle == 1"></app-theme2>
                <app-theme4 *ngIf="this.dataToggle == 2"></app-theme4>
                <div style="
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-start;
                  ">
                  <button class="btn1-button" style="
                      font-size: 1.7vmin;width: 21vmin;height: 5vmin;
                      background-color: #302c2c;
                      outline: unset;
                      float: right;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                    " (click)="onItemChangecontent($event)" *ngIf="this.dataToggle == 1">
                    Recommendation
                  </button>
                </div>
                <div style="
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-start;
                    /* padding: 1%; */
                  ">
                  <button class="btn1-button" style="
                      font-size: 1.7vmin;width: 17vmin;

                      height: 5vmin;
                      background-color: #302c2c;
                      outline: unset;
                      float: right;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                    " (click)="onItemChangeconten1t($event)" *ngIf="this.dataToggle == 2">
                    Back
                  </button>
                </div>
              </div>
              <div *ngIf="SelectedTheme == 'SOP & Alerts'" style="height: 81vmin; overflow-y: scroll">
                <app-theme10></app-theme10>
              </div>
              <!-- Theme 3  -->
              <div *ngIf="SelectedTheme == 'CO Savings'" style="height: 81vmin; overflow-y: scroll">
                <app-theme3></app-theme3>
              </div>
              <!-- Theme 5 -->
              <div *ngIf="SelectedTheme == 'File Upload'" style="height: 81vmin; overflow-y: scroll">
                <app-theme5></app-theme5>
              </div>
              <div *ngIf="SelectedTheme == 'User Management'" style="height: 81vmin; overflow-y: scroll">
                <app-theme6></app-theme6>
              </div>
              <!-- Theme 7 -->
              <div *ngIf="SelectedTheme == 'Clinker Quality'" style="height: 81vmin; overflow-y: scroll">
                <app-theme7></app-theme7>
              </div>
              <div *ngIf="SelectedTheme == 'Kiln 4.0'" style="height: 81vmin; overflow-y: scroll">
                <app-theme8 *ngIf="this.kilnToggle == 1"></app-theme8>
                <app-theme9 *ngIf="this.kilnToggle == 2"></app-theme9>
                <div style="
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-start;
                  ">
                  <button class="btn1-button" style="
                    font-size: 1.7vmin;width: 21vmin;

height: 5vmin;
                      background-color: #302c2c;
                      outline: unset;
                      float: right;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                    " (click)="onItemChangecontentkiln($event)" *ngIf="this.kilnToggle == 1">
                    Recommendation
                  </button>
                </div>
                <div style="
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-start;
                    /* padding: 1%; */
                  ">
                  <button class="btn1-button" style="
                      font-size: 1.7vmin;width: 17vmin;

height: 5vmin;
                      background-color: #302c2c;
                      outline: unset;
                      float: right;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                    " (click)="onItemChangeconten1tkiln($event)" *ngIf="this.kilnToggle == 2">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-10 " *ngIf="SelectedTheme=='GreenOps - Data Config.'"
            style="padding-right: 5px; padding-left: 5px">
            <div class="card">
              <div class="col-md-10"
                style="display:flex;flex-direction: row;justify-content: space-between;margin-left: 8%;margin-right: 8%;">
                <div class="col-md-3" style="height:40px ;">
                  <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;text-align: center;">
                    <label style="color: #fff;">Data Config</label>
                  </div>
                </div>
                <div class="col-md-3" style="height:40px ;">
                  <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;text-align: center;">
                    <label style="color: #fff;">Data Processing</label>
                  </div>
                </div>
                <div class="col-md-3" style="height:40px ;">
                  <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;text-align: center;">
                    <label style="color: #fff;">Report</label>
                  </div>
                </div>
              </div>
              <div class="col-md-12" style="display:grid;">
                <img class="img-fluid" src="../../../assets/images/greenopsi.svg" alt="Pyro Process flow"
                  style="width:100%;height: 450px;">
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-10 " *ngIf="SelectedTheme=='Validation - Workflow'"
            style="padding-right: 5px; padding-left: 5px">
            <div class="card">
                  <div class="col-md-12" style="display:grid;">
                      <section>
                          <div class="mainGridRgtTitl"
                              style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;margin-bottom: 1%;">
                              Validation - Workflow
          
                          </div>
                          <div style="
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                      ">
                              <p style="
                          margin: 0px;
                          margin-left: 10px;
                          font-weight: 400;
                          font-size: 20px;
                          color: #fff;
                        ">
                                  Status:
                              </p>
                              &nbsp;&nbsp;
                              <button style="
                          height: 10px;
                          width: 17px;
                          left: 66px;
                          top: 860px;
                          margin-top: 11px;
                          border-radius: 10px;
                          border-width: 0px;
                          /* background: #0eab73; */
                        " [ngStyle]="{
                          background:
                            this.colorMix1 == 'G' ? '#39FF14' : '#0a3207'
                        }"></button>&nbsp;&nbsp;
                              <button style="
                          height: 10px;
                          width: 17px;
                          left: 66px;
                          top: 860px;
                          margin-top: 11px;
                          border-radius: 10px;
                          border-width: 0px;
                          /* background: #e5b027; */
                        " [ngStyle]="{
                          background:
                            this.colorMix1 == 'Y' ? '#e5b027' : '#332f00'
                        }"></button>&nbsp;&nbsp;
                              <button style="
                          height: 10px;
                          width: 17px;
                          left: 66px;
                          top: 860px;
                          margin-top: 11px;
                          border-radius: 10px;
                          border-width: 0px;
                          /* background: #c7152a; */
                        " [ngStyle]="{
                          background: this.colorMix1 == 'R' ? '#c7152a' : '#300'
                        }"></button>&nbsp;&nbsp;
          
                          </div>
                          <div class="row">
                              <div class="col-md-2" style="height:200px ;">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Data Capture</label>
                                      <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                          style="width:40px;height: 40px;"></label>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Review</label>
                                      <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                          style="width:40px;height: 40px;"></label>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Computation with Soft Sensors</label>
                                      <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                          style="width:40px;height: 40px;"></label>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Review</label>
                                      <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                          style="width:40px;height: 40px;"></label>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Approval</label>
                                      <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                          style="width:40px;height: 40px;"></label>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="red" style="border-radius: 6px;
          box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;height:200px ;">
                                      <label>Ready</label>
                                      <label><img  src="assets\images\tick-n.gif" alt="Pyro Process flow"
                                          style="width:60px;height: 60px;"></label>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <!-- <div class="col-md-12">
                                  <label style="display: flex;flex-direction:row;justify-content:center;color:#fff">Report Validation</label>
                                  
                               
                              </div> -->
                          </div>
          
                          <!-- <div style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            
                            /* padding: 1%; */
                            margin-top: 2%;
                          ">
                        <button mat-raised-button color="primary" style="width: 150px;margin-left: 15px; " (click)="Validationcheck('G')">
                          Submission to <br/>Customer Review
                        </button>
                        <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                          Customer <br/>Review
                        </button>
                        <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                          Computing<br/> Outcome
                        </button>
                        <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                          Report <br/>Preparation
                        </button>
                        <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                          Customer <br/>Review
                        </button>
                        <button mat-raised-button color="primary" style="width: 150px; "  (click)="Validationcheck('G')">
                          Final <br/>Submitted
                        </button>
                      </div> -->
          
                      </section>
                  </div>
            </div>
          </div>

          <div class="col-12 col-sm-10 " *ngIf="SelectedTheme=='Reports Extraction'" 
            style="padding-right: 5px; padding-left: 5px">
            <div class="card">
              <div class="col-md-12"
            >
              <div class="asImgBoxTop pt-2">
                  <div class="asImgBoxTopTxt">Reports Extraction</div>
      
              </div>
              <div class="col-md-12" style="display:grid;">
                  <div class="row" style="padding-top: 15px;">
                      <div (click)="downloadExcel();" class="col-md-3" style="display:grid;">
                          <img src="assets\images\indian-flag.PNG" alt="Pyro Process flow"
                              style="width:100%;height: 100px;cursor:pointer">
                          <span style="color: #fff;">BS & SR</span>
                      </div>
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\us-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">Mandatory Reporting of GHG Rule</span>
                      </div>
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\uk-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">Guidance on how to measure your GHG Emmisions</span>
      
                      </div>
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\japan-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">Tokyo Emission Trading Scheme, Mandatory GHG Accounting and Reporting
                              System </span>
                      </div>
      
                      <!-- <div class="col-md-3">
                          <div class="red"  style="border-radius: 6px;
      box-shadow: inset 7px 7px 14px black,
              inset -7px -7px 14px #5a5a5a;">
                          </div>
                      </div> -->
      
                  </div>
                  <div class="row" style="padding-top: 15px;">
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\australia-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">NGER Mandatory Reporting</span>
      
                      </div>
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\nz-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">Guidance for Voluntary Corporate GHG Reporting </span>
      
                      </div>
                      <div class="col-md-3" style="display:grid;">
                          <img src="assets\images\canada-flag.png" alt="Pyro Process flow"
                              style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                          <span style="color: #fff;">GHG Emission Reporting Scheme</span>
      
                      </div>
      
                  </div>
      
                  <!-- <img class="img-fluid" src="assets\images\pillars.png" alt="Pyro Process flow"
                          style="width:100%; margin:auto;"> -->
              </div>
      
      
          </div>
            </div>
          </div>


          <div *ngIf="SelectedTheme=='Emission A/c - Cement'" class="col-12 col-sm-10">
           
          <app-theme12></app-theme12>
      
        </div>
        <div *ngIf="SelectedTheme=='Emission A/c - O&G'" class="col-12 col-sm-10">
           
          <app-theme15></app-theme15>
      
        </div>
        <div *ngIf="SelectedTheme=='Emission Analytics - Cement'" class="col-12 col-sm-10">
          <app-theme13></app-theme13>
        </div>
        <div *ngIf="SelectedTheme=='Emission Analytics - O&G'" class="col-12 col-sm-10">
          <app-theme16></app-theme16>
        </div>
        <div *ngIf="SelectedTheme=='Energy, Water & Wastage'" class="col-12 col-sm-10">
      
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                      <div class="row"> 
                        <div class="col-md-12">
                        <div class="asImgBoxTop pt-2">
                          <div class="asImgBoxTopTxt">{{ SelectedTheme }}</div>
                        </div>
                      </div>
                        <div class="col-md-12" style="display: grid" *ngIf="imageToggle == 1">
                          <img class="img-fluid" src="../../../assets/img/pyroenq1.7.drawio.svg" alt="Pyro Process flow"
                            style="height: 70vmin; margin: auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                      <div class="col-md-6">
                      <app-theme14></app-theme14>
                    </div>
                  </div>
        </div>
      




        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>

  <!-- <app-footer></app-footer> -->