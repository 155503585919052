<section style="display: flex;flex-direction: row;">
<div class="card" style="width: 50%;margin-right: 5px;margin-left: -10px;">
    <div class="row">
        <div class="col-12 col-md-12">
            <div>
                <div class="mainGridRgtTitl2">
                    <div class="row">
                        <div class="col-md-12 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                            GHG - Scope1 (Tons)
                        </div>
                        <!-- <div class="col-md-5" style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                            Intensity
                            <span *ngFor="let items2 of s2Inten"
                                style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items2.scope2_intensity}}</span>
                        </div> -->
                    </div>
                </div>
            </div>


            <div class="row" style="padding: 1%;">
                <div class="col-md-12">
                    <table class="tableStyle1">
                        <tr>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                Parameter</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                CH<sub>4</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                CO<sub>2</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                N<sub>2</sub>O</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                HFC</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                PFC</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                SF<sub>6</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                NF<sub>3</sub></th>
                        <tr *ngFor="let item of ghgs1">
                            <!-- <td>{{ item.id }}</td> -->
                            <!-- <td>{{ item.obs_time }}</td> -->
                            <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                            <td>{{ item.ch4 }}</td>
                            <td>{{ item.co2 }}</td>

                            <td>{{ item.n2o }}</td>
                            <td>{{ item.hfc }}</td>
                            <td>{{ item.pfc }}</td>
                            <td>{{ item.sf6 }}</td>
                            <td>{{ item.nf3 }}</td>
                        </tr>

                    </table>
                </div>
            </div>

         
        </div>
        <div class="col-12 col-md-12">
            <div class="mainGridRgtTitl2">
                <div class="row">
                    <div class="col-md-12 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                        GHG - Scope2 (Tons)
                    </div>
                    <!-- <div class="col-md-5" style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                        Intensity
                        <span *ngFor="let items2 of s2Inten"
                            style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items2.scope2_intensity}}</span>
                    </div> -->
                </div>
            </div>
            <div class="row" style="padding: 1%;">
                <div class="col-md-12">

                    <table class="tableStyle1">
                        <tr>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                Parameter</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                CH<sub>4</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                CO<sub>2</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                N<sub>2</sub>O</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                HFC</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                PFC</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                SF<sub>6</sub></th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                NF<sub>3</sub></th>
                        </tr>
                        <tr *ngFor="let item of ghgs2">
                            <!-- <td>{{ item.id }}</td> -->
                            <!-- <td>{{ item.obs_time }}</td> -->
                            <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                            <td>{{ item.ch4 }}</td>
                            <td>{{ item.co2 }}</td>

                            <td>{{ item.n2o }}</td>
                            <td>{{ item.hfc }}</td>
                            <td>{{ item.pfc }}</td>
                            <td>{{ item.sf6 }}</td>
                            <td>{{ item.nf3 }}</td>
                        </tr>
                
                    </table>
                </div>
            </div>
        </div>
      
    </div>
</div>
<div class="card " style="width: 50%; margin-left: 5px;">
    <div class="row">
        <div class="col-12 col-md-12">
            <div>
                <div class="mainGridRgtTitl">
                    <div class="row">
                        <div class="col-md-12 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;padding-bottom: 10px !important;">
                            Non - GHG (Tons)
                        </div>
                       
                    </div>
                </div>
            </div>


            <div class="row" style="padding: 1%;">
                <div class="col-md-12">
                    <table class="tableStyle1">
                        <tr>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                Parameter</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">NOx
                            </th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                SOx</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                PM</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                POP</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                VOC</th>
                            <th colspan="1"
                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                HAP</th>
                            <!-- <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th> -->
                        </tr>
                        <tr *ngFor="let item of nghg">
                            <!-- <td>{{ item.id }}</td> -->
                            <!-- <td>{{ item.obs_time }}</td> -->
                            <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                            <td>{{ item.nox }}</td>
                            <td>{{ item.sox }}</td>
                            <td>{{ item.pm }}</td>
                            <td>{{ item.voc }}</td>
                            <td>{{ item.poc }}</td>
                            <td>{{ item.hap }}</td>
                        </tr>
                    </table>
                </div>
            </div>

           
        </div>
       
        <div class="col-12 col-md-12">
        <div>
            <div class="mainGridRgtTitl">
                <div class="row">
                    <div class="col-md-12 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                        Compliance
                    </div>
                    <!-- <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                    Intensity
                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">--</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row" style="padding: 1%;">
            <div class="col-md-12">

                <table class="tableStyle1">

                    <tr>
                        <th style="font-size: 16px;color: yellow;">Prevention and Control of Pollution</th>
                        <td style="font-size: 16px;">✓</td>

                    </tr>
                    <tr>
                        <th style="font-size: 16px;color: yellow;">Environment protection act and rules</th>
                        <td style="font-size: 16px;">✓</td>

                    </tr>





                </table>
            </div>
        </div>
    </div>

    </div>
</div>

</section>