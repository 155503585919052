<section style="display: flex;flex-direction: row;">
    <div class="card" style="width: 50%;margin-right: 5px;margin-left: -10px;">
        <div class="row">
            <div class="col-md-12"
          >
            <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">Methane Reduction - O&G </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div  class="col-md-12" >
                        <p class="mainGridtitle4" style="padding-bottom: 0px !important;"> Emission Summary</p>
                  <table class="tableStyle1">
                    <tr>
                        <th colspan="1"
                            style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                            Emission Source</th>
                        <th colspan="1"
                            style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Before GreenOps (Tons)
                        </th>
                        <th colspan="1"
                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">After GreenOps (Tons)
                    </th>
                    <th colspan="1"
                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (Tons)
                </th>
            
                    </tr>
                    <tr *ngFor="let item of EmissionReducdata">
                        <th style="font-size: 16px;color: yellow;">Pneumatic control</th>
                        <td>{{ item.pneumatic_before_greenops }}</td>
                        <td>{{ item.pneumatic_after_greenops }}</td>
                        <td>{{ item.pneumatic_savings }}</td>
                    </tr>
                    <tr *ngFor="let item of EmissionReducdata">
                      <th style="font-size: 16px;color: yellow;">Pumps</th>
                      <td>{{ item.pumps_before_greenops }}</td>
                      <td>{{ item.pumps_after_greenops }}</td>
                      <td>{{ item.pumps_savings}}</td>
                  </tr>
                  <tr *ngFor="let item of EmissionReducdata">
                    <th style="font-size: 16px;color: yellow;">Other Equipments</th>
                    <td>{{ item.other_euipt_before_greenops }}</td>
                    <td>{{ item.other_euipt_after_greenops }}</td>
                    <td>{{ item.other_euipt_savings }}</td>
                </tr>
                <tr *ngFor="let item of EmissionReducdata">
                  <th style="font-size: 16px;color: yellow;">Compressor station</th>
                  <td>{{ item.compressor_before_greenops }}</td>
                  <td>{{ item.compressor_after_greenops }}</td>
                  <td>{{ item.compressor_savings }}</td>
              </tr>
              <tr *ngFor="let item of EmissionReducdata">
                <th style="font-size: 16px;color: yellow;">Pipeline leaks</th>
                <td>{{ item.pipeline_leaks_before_greenops }}</td>
                <td>{{ item.pipeline_leaks_after_greenops }}</td>
                <td>{{ item.pipeline_leaks_savings }}</td>
            </tr>
                <!-- Compressor station -->
                <!-- Pipeline leaks -->
                </table>
            </div>
                </div>
            </div>
         
            <!-- <div style="width: 100%;padding-top: 3%;">
                <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                    style="width:100%; margin:auto; height: 542px;">
            </div> -->
        </div>
          
        </div>
    </div>
    <div class="card " style="width: 50%; margin-left: 5px;">
        <div class="row">
            <div class="col-md-12">

                <div class="mainGridRgtTitl"
                  style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">
                  Methane Reduction 
                  <span style="float: right;">
                  <select  class="mx-2 selectParamM2" style="font-size: 14px;" (click)="changeDropdown($event)">
                    <option value="1">Pipeline leaks</option>
                    <option value="2"> Pneumatic control</option>
                    <option value="3">Pumps </option>
                    <option value="4"> Compressor station</option>
                    <option value="5">Other Equipments </option>
                  </select>
                </span>
                </div>

          
                <div class="theme1PlotBox">
                  <div class="row">
                    <div  *ngIf="this.selectone == 1" class="col-md-7 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                     Emission Savings - Pipeline Leaks
                    </div>
                    <div  *ngIf="this.selectone == 2" class="col-md-7 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Savings - Pneumatic control
                     </div>
                     <div   *ngIf="this.selectone == 3" class="col-md-7" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Savings - Pumps
                     </div>
                     <div  *ngIf="this.selectone == 4" class="col-md-7" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Savings - Compressor station
                     </div>
                     <div  *ngIf="this.selectone == 5" class="col-md-7 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Savings - Other Equipments
                     </div>
                    <div *ngFor="let sval of Valuesdaywise  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;float: right;">
                      <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 15px;">{{sval.co2_saving_per_ton}}</span> kg/Ton of kiln feed
                     </div>
                  </div>
                  <div class="theme1PlotBoxGraph">
                    <span *ngIf="this.selectone == 1"><div id="methanePipeDiv"></div></span>
                    <span *ngIf="this.selectone == 2"><div id="methanePmaticDiv"></div></span>
                    <span *ngIf="this.selectone == 3"><div id="methanePumpsDiv"></div></span>
                    <span *ngIf="this.selectone == 4"><div id="methaneCompressorDiv"></div></span>
                    <span *ngIf="this.selectone == 5"><div id="methaneOtherDiv"></div></span>
                    <!-- </div> -->
                  </div>
                  
                </div>
                <div class="theme1PlotBox">
                  <div class="row">
                    <div *ngIf="this.selectone == 1" class="col-md-8" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Forecast - Pipeline Leaks
                    </div>
                    <div *ngIf="this.selectone == 2" class="col-md-8" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Forecast - Pneumatic control
                    </div>
                    <div *ngIf="this.selectone == 3" class="col-md-8" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Forecast - Pumps
                    </div>
                    <div *ngIf="this.selectone == 4" class="col-md-8" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Forecast - Compressor station
                    </div>
                    <div *ngIf="this.selectone == 5" class="col-md-8" style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                      Emission Forecast - Other Equipments
                    </div>
                    <div *ngFor="let sval of Valuesdaywise  "  class="col-md-3"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{sval.co_saving_per_ton}}</span> kg/Ton of kiln feed
                    </div>
                  </div>
                  <div class="theme1PlotBoxGraph">
                 
                      <span *ngIf="this.selectone == 1"><div id="methanePipeFCDiv"></div></span>
                      <span *ngIf="this.selectone == 2"><div id="methanePnuematicFCDiv"></div></span>
                      <span *ngIf="this.selectone == 3"><div id="methanePumpsFCDiv"></div></span>
                      <span *ngIf="this.selectone == 4"><div id="methanecompressorFCDiv"></div></span>
                      <span *ngIf="this.selectone == 5"><div id="methaneotherFCDiv"></div></span>
            
                    <!-- </div> -->
                  </div>
                  
                </div>
              
            
            
              </div>
        </div>
    </div>
    
    </section>