<section>
  <div *ngIf="this.kilnToggle1 == 1">
    <div class="mainGridRgt11" style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        border-bottom: 2px solid;
      ">
      <div class="asImgBoxTop pt-2">
        <div class="asImgBoxTopTxt">Kiln-Critical Parameters</div>
      </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white; font-size: 2vmin">Date</mat-label>
            <input style="padding-top: 0.9vmin;
            color: white;
            font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
        </div>
        <button title="Select the date and click." style="
            font-size: 2.3vmin;
            width: 11vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            /* margin-left: 4%; */
            /* padding-top: 2px; */
            border: 1px solid rgba(255, 255, 255, 0.38);
            height: 5vmin;
            border-radius: 0.9375em !important;
            display: flex;
            justify-content: center;
            align-items: center;
          " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
          Apply
        </button>
      </div>
      <!-- <div  style="display: flex;margin-left: 15px;margin-right: 2px; font-size: 16px; font-weight: 600;">Status:
        <span style="margin-left:6px;margin-right:6px;" [ngStyle]="{'color': coloval}" >  {{checkstatus}}</span>
      </div> -->
    </div>
    <div *ngIf="!isLoading" style="
        font-size: 13px;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        /* height: 425px; */

        /* overflow:scroll; */
      ">
      <div style="display: flex; flex-direction: row; justify-content: flex-end">
        <!-- <div style="display: flex">
          <button
            class="btn1-button"
            style="
              font-size: 12px;
              width: 120px;
              background-color: #302c2c;
              outline: unset;
              float: right;
              border: 1px solid rgba(255, 255, 255, 0.38);
            "
            (click)="onItemChangekilnsim($event)"
            *ngIf="this.kilnToggle1 == 1"
          >
            Simulator
          </button>
        </div> -->
        <div style="display: flex">
          <p style="
              margin: 0px;
              margin-left: 10px;
              font-weight: 400;
              font-size: 2vmin;
              color: #fff;
            ">
            Alert Level:
          </p>
          &nbsp;&nbsp;
          <button style="
              height: 1.7vmin;
              width: 3vmin;
              left: 66px;
              top: 860px;
              margin-top: 0.8vmin;
              border-radius: 10px;
              border-width: 0px;
              /* background: #0eab73; */
            " [ngStyle]="{
              background: this.colorMix1 == 'G' ? '#39FF14' : '#0a3207'
            }"></button>&nbsp;&nbsp;
          <button style="
              height: 1.7vmin;
              width: 3vmin;
              left: 66px;
              top: 860px;
              margin-top: 0.8vmin;
              border-radius: 10px;
              border-width: 0px;
              /* background: #e5b027; */
            " [ngStyle]="{
              background: this.colorMix1 == 'Y' ? '#e5b027' : '#332f00'
            }"></button>&nbsp;&nbsp;
          <button style="
              height: 1.7vmin;
              width: 3vmin;
              left: 66px;
              top: 860px;
              margin-top: 0.8vmin;
              border-radius: 10px;
              border-width: 0px;
              /* background: #c7152a; */
            " [ngStyle]="{
              background: this.colorMix1 == 'R' ? '#c7152a' : '#300'
            }"></button>&nbsp;&nbsp;
        </div>
      </div>

      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">RealTime Parameters</p>
          <span style="color: #fff; font-size: 2vmin">
            <tr *ngFor="let vval of ActualForecastKilnRCdata">
              <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
            </tr>
          </span>
        </div>
        <div class="row" style="padding: 1%">
          <div class="col-md-4">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Main Coal (TPH)</label>
              <span *ngIf="ActualForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ActualForecastKilnRCdata">
                  <label class="per" *ngIf="vval.main_coal_tph < 0">0.00</label>
                  <label class="per" *ngIf="vval.main_coal_tph >= 0">
                    {{ vval.main_coal_tph }}
                  </label>
                </div>
              </span>
              <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Kiln Speed (RPM)</label>
              <span *ngIf="ActualForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ActualForecastKilnRCdata">
                  <label class="per" *ngIf="vval.kiln_speed < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_speed >= 0">
                    {{ vval.kiln_speed }}
                  </label>
                </div>
              </span>
              <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">PA Fan Speed (RPM)</label>
              <span *ngIf="ActualForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ActualForecastKilnRCdata">
                  <label class="per" *ngIf="vval.pa_fan_speed < 0">0.00</label>
                  <label class="per" *ngIf="vval.pa_fan_speed >= 0">
                    {{ vval.pa_fan_speed }}
                  </label>
                </div>
              </span>
              <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">Recommendation Values</p>
          <span style="color: #fff; font-size: 2vmin">
            <tr *ngFor="let vval of ForecastKilnRCdata">
              <td>Time -&nbsp;{{ vval.obs_time }}</td>
            </tr>
          </span>
        </div>

        <div class="row" style="padding: 1%">
          <div class="col-md-6">
            <div class="red2" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Main Coal (TPH)</label>
              <span *ngIf="ForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ForecastKilnRCdata">
                  <label class="per" *ngIf="vval.mc_tph < 0">0.00</label>
                  <label class="per" *ngIf="vval.mc_tph >= 0">
                    {{ vval.mc_tph }}
                  </label>
                </div>
                <span style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                  " (click)="openDialogMC()">
                  <img src="../../../../assets/icons/charticon.png"
                    style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin" />
                  <p style="
                      margin-bottom: 0px;
                      margin-left: 5px;
                      text-decoration: underline;
                      font-size: 1.5vmin;
                      cursor: pointer;
                    ">
                    View Chart
                  </p>
                </span>
              </span>
              <div *ngIf="ForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="red2" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Kiln Speed (RPM)</label>
              <span *ngIf="ForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ForecastKilnRCdata">
                  <label class="per" *ngIf="vval.kiln_speed < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_speed >= 0">
                    {{ vval.kiln_speed }}
                  </label>
                </div>
                <span style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                  " (click)="openDialogKS()">
                  <img src="../../../../assets/icons/charticon.png"
                    style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin" />
                  <p style="
                      margin-bottom: 0px;
                      margin-left: 5px;
                      text-decoration: underline;
                      font-size: 1.5vmin;
                      cursor: pointer;
                    ">
                    View Chart
                  </p>
                </span>
              </span>
              <div *ngIf="ForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-12" style="padding-top: 10px">
            <div class="red2" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">PA Fan Speed (RPM)</label>
              <span *ngIf="ForecastKilnRCdata.length != 0">
                <div *ngFor="let vval of ForecastKilnRCdata">
                  <label class="per" *ngIf="vval.pa_fan_rpm < 0">0.00</label>
                  <label class="per" *ngIf="vval.pa_fan_rpm >= 0">
                    {{ vval.pa_fan_rpm }}
                  </label>
                </div>
                <span style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                  " (click)="openDialogPA()">
                  <img src="../../../../assets/icons/charticon.png"
                    style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin" />
                  <p style="
                      margin-bottom: 0px;
                      margin-left: 5px;
                      text-decoration: underline;
                      font-size: 1.5vmin;
                      cursor: pointer;
                    ">
                    View Chart
                  </p>
                </span>
              </span>
              <div *ngIf="ForecastKilnRCdata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 1vmin;
          margin-bottom: 1vmin;
        ">
        <div>
          <button class="btn1-button" style="
             font-size: 1.7vmin;width: 17vmin;

height: 5vmin;
              background-color: #302c2c;
              outline: unset;
              float: right;
              border: 1px solid rgba(255, 255, 255, 0.38);
            " (click)="onItemChangekilnsim($event)" *ngIf="this.kilnToggle1 == 1">
            Simulator
          </button>
        </div>
        <div>
          <span><button *ngIf="!disablePrevButton" style="
                font-size: 2.2vmin;
                width: 12vmin;
                height: 5vmin;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                border: 1px solid rgba(255, 255, 255, 0.38);
                align-items: center;
                justify-content: center;
                display: flex;
              " mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onPrevClick()">
              Previous
            </button></span>
        </div>
        <button *ngIf="!disableNextButton" style="
            font-size: 2.2vmin;
            width: 12vmin;
            height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          " mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onNextclick()">
          Next
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="this.kilnToggle1 == 2">
    <div class="mainGridRgt11" style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        border-bottom: 2px solid;
      ">
      <div class="asImgBoxTop pt-2 pb-1">
        <div class="asImgBoxTopTxt">Kiln-Simulator</div>
      </div>
    </div>
    <span *ngIf="!isLoading" style="
        /* height: 425px; */

        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
       /* padding-top: 10px; */
      ">

      <div class="red1">
        <div class="row" style="padding: 2px 1% 2px 1%;">
          <div class="col-md-12">
            <div style="
                display: flex;
                justify-content: end;
                align-items: center;
                padding-bottom:5px;
              ">
              <table class="tableStyle2">
                <tr *ngFor="let vval of ActualForecastKilnRCdata">
                  <td>Date -&nbsp;{{ vval.obs_time | date : "MM/dd/yyyy" }}</td>
                  <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
                </tr>
              </table>
            </div>

            <table class="tableStyle1">
              <tr>
                <th class="table-head" colspan="1">Parameters</th>
                <th colspan="1" class="table-head">
                  AFR (M<sup>3</sup>)
                </th>
                <th colspan="1" class="table-head">
                  MC (TPH)
                </th>
                <th colspan="1" class="table-head">
                  PC (TPH)
                </th>
                <th colspan="1" class="table-head">
                  PA Fan (RPM)
                </th>
                <th colspan="1" class="table-head">
                  PCT (°C)
                </th>
                <th colspan="1" class="table-head">
                  BZT (°C)
                </th>
              </tr>
              <tr *ngFor="let vval of KilnRealtimeTable">
                <th class="table-head1"> Real Time Parameters</th>
                <td class="table-data">{{ vval.f_cao }}</td>
                <td class="table-data">{{ vval.main_coal_tph }}</td>
                <td class="table-data">{{ vval.pc_coal_tph }}</td>
                <td class="table-data">{{ vval.pa_fan_speed }}</td>
                <td class="table-data">{{ vval.kiln_speed }}</td>
                <td class="table-data">{{ vval.burning_zone_temp }}</td>
              </tr>
              <tr *ngFor="let vval of KilnRecommendedTable">
                <th class="table-head1"> GreenOps Recommendation</th>
                <td class="table-data">{{ vval.free_lime }}</td>
                <td class="table-data">{{ vval.main_coal_tph }}</td>
                <td class="table-data">{{ vval.pc_coal_tph }}</td>
                <td class="table-data">{{ vval.pa_fan_speed }}</td>
                <td class="table-data">{{ vval.kiln_speed }}</td>
                <td class="table-data">{{ vval.burning_zone_temp }}</td>
              </tr>
            </table>

          </div>
        </div>
      </div>
      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">Simulator Inputs (User Inputs)</p>
          <!-- <span style="color: #fff;font-size: 2vmin;">
            <tr *ngFor="let vval of ForecastKilnRCdata">
              <td>Time -&nbsp;{{ vval.obs_time }}</td>
            </tr>
          </span> -->
          <button style="
             font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
              cursor: pointer;
              background-color: #302c2c;
              outline: unset;
              border: 1px solid rgba(255, 255, 255, 0.38);
              align-items: center;
              justify-content: center;
              display: flex;
            " (click)="Submit()" [disabled]="
              !selectedMainCoal &&
              !selectedPcCoal &&
              !selectedKilnSpeed &&
              !selectedPaFanSpeed
            " mat-raised-button class="get-sm-btn btn1" color="primary">
            Apply
          </button>
        </div>

        <div class="row" style="padding: 1%">
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">Main Coal (TPH)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [(ngModel)]="selectedMainCoal">
                    <option *ngFor="let option of options" >{{ option }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">PC Coal (TPH)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [(ngModel)]="selectedPcCoal">
                    <option *ngFor="let option of options1" >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 10px">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">Kiln Speed (RPM)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [(ngModel)]="selectedKilnSpeed">
                    <option *ngFor="let option of options2" >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 10px">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">PA Fan Speed (RPM)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [(ngModel)]="selectedPaFanSpeed">
                    <option *ngFor="let option of options3" >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="col-md-12"
            style="padding-top: 10px; display: flex; justify-content: center"
          >
          <div class="col-md-12" style="padding-top: 10px; display: flex; justify-content: center;">
            <div style="padding-top: 10px;">

              <button (click)="Submit()" [disabled]="!selectedMainCoal && !selectedPcCoal && !selectedKilnSpeed && !selectedPaFanSpeed" mat-raised-button color="primary">Apply</button>
  
        </div>
        </div>
          </div> -->
        </div>
      </div>
      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">Simulator Output (Model Outcome)</p>
          <span style="color: #fff; font-size: 2vmin">
            <tr>
              <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                </td> -->
              <!-- <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td> -->
            </tr>
          </span>
        </div>
        <div class="row" style="padding: 1%">
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Free Lime (%)</label>
              <span>
                <div>
                  <label class="per">
                    {{ SimulationData2 }}
                  </label>
                </div>
              </span>

              <!-- <span *ngIf="SimulationData.length == 0 || null">
                <div>
                <label class="per">
                  NO Data
               </label>
              </div>
               </span> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Burning Zone Temperature (°C)</label>
              <span>
                <div>
                  <label class="per">
                    {{ SimulationData1 }}
                  </label>
                </div>
              </span>

              <!-- <span *ngIf="SimulationData.length == 0 || null">
              <div>
              <label class="per">
                NO Data
             </label>
            </div>
             </span> -->
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="
            display: flex;
            justify-content: space-between;
            padding-top:2px;
            padding-bottom:3px
          ">
          <div>
            <button style="
                font-size: 2.2vmin;
    width: 12vmin;
    height: 5vmin;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                border: 1px solid rgba(255, 255, 255, 0.38);
                align-items: center;
                justify-content: center;
                display: flex;
              " *ngIf="!disablePrevButton" mat-raised-button class="get-sm-btn btn1" color="primary"
              (click)="onPrevClick()">
              Previous
            </button>
          </div>

          <div>
            <button style="
                font-size: 2.2vmin;
    width: 12vmin;
    height: 5vmin;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                border: 1px solid rgba(255, 255, 255, 0.38);
                align-items: center;
                justify-content: center;
                display: flex;
              " *ngIf="!disableNextButton" mat-raised-button class="get-sm-btn btn1" color="primary"
              (click)="onNextclick()">
              Next
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>
  <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img src="../../../../assets/icons/Spinner.gif" style="width: 10vmin" />
      <p style="text-align: center; color: #fff">Loading...</p>
    </div>
  </div>
</section>