<div style="text-align:center;">   
  <div class="col-sm-12">
     <div class="row" style="border-radius: 5px; border: 1px solid white;"> 
       <div class="col card-heading" style="font-weight: bold; font-size: 16px; color: #0164a8; padding: 2%; border-bottom: 1px solid white; background-color: white;">
     
       </div>
     
     </div>
  </div>
</div>

