<section>
    <div>
      <div class="mainGridRgtTitl"
        style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">
        Carbon Savings
        <select class="global_dropdown" style=" max-width: max-content;
        float: right;
        padding: 10px;  
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
       
        " (change)="changeDropdown($event)">
          <option value="1660847400">
            Aug 19th 2022
          </option>
          <option value="1660933800">
            Aug 20th 2022
          </option>
          <option value="1661020200">
            Aug 21th 2022
          </option>
          <option value="1661106600">
            Aug 22th 2022
          </option>
        </select>
      </div>
   <!-- <div class="col-md-12">
        <span style=" color: #fff; float: right;">
          <tr *ngFor="let vval of AFRdata  ">
            <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm'}}
            </td>
          </tr>
        </span>
      </div> -->
     <div class="row" style="padding: 1%;">
        <div class="col-md-6">
          <div class="red" *ngFor="let vval of Coaldata  ">
            <label for="temp">Daily CO (Kg)</label>
            <div *ngIf="vval.daily_co">
              <label class="per">{{vval.daily_co}}</label>&nbsp;&nbsp;&nbsp;
              <!-- <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" /> -->
            </div>
            <div *ngIf="!vval.daily_co"><label class="per">No data</label></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="red" *ngFor="let vval of Coaldata  ">
            <label for="temp">CO Reduced (Kg)</label>
            <div *ngIf="vval.co_reduction">
              <label class="per">{{vval.co_reduction}}</label>&nbsp;&nbsp;&nbsp;
              <img src="../../../../assets/images/greenarrowdown.gif" style="width: 30px;" />
            </div>
            <div *ngIf="!vval.co_reduction"><label class="per">No data</label></div>
          </div>
        </div>
      </div>
      <!-- <div style="overflow-y: scroll;    max-height: 399px;
      overflow-x: hidden;
      padding: 10px;"> -->
      <div class="theme1PlotBox">
        <div class="row">
          <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
           Coal Savings
          </div>
          <div class="col-md-5">
            <!-- <select class="global_dropdown" style=" max-width: max-content;
            float: right;
            padding: 10px;  
            font-size: 13px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 1%;margin-top: 1%;" (change)="changeDropdown($event)">
        <option value="1660847400">
          Aug 19th 2022
        </option>
        <option value="1660933800">
          Aug 20th 2022
        </option>
        <option value="1661020200">
          Aug 21th 2022
        </option>
        <option value="1661106600">
          Aug 22th 2022
        </option>
      </select> -->
          </div>
        </div>
        <div class="theme1PlotBoxGraph">
          <div id="ppediv">
  
          </div>
        </div>
        
      </div>
      <div class="theme1PlotBox">
        <div class="row">
          <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
            CO Savings
          </div>
          <div class="col-md-5">
            <!-- <select class="global_dropdown" style=" max-width: max-content;
            float: right;
            padding: 10px;  
            font-size: 13px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 1%;margin-top: 1%;" (change)="changeDropdown($event)">
        <option value="1660847400">
          Aug 19th 2022
        </option>
        <option value="1660933800">
          Aug 20th 2022
        </option>
        <option value="1661020200">
          Aug 21th 2022
        </option>
        <option value="1661106600">
          Aug 22th 2022
        </option>
      </select> -->
          </div>
        </div>
        <div class="theme1PlotBoxGraph">
          <div id="ppediv1">
  
          </div>
        </div>
        
      </div>
    <!-- </div> -->
  
  
     
      <!-- <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
      margin-top: 1%;
  ">
  <div>
    <span *ngIf="this.selectedDatenext>'1660847400'"><button mat-raised-button color="primary"
        (click)="onPreviousclick()"> Previous</button></span>
    <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button  disabled style="border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;">
        Previous</button></span>
  </div>
  <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
  </div> -->
  
  
    </div>
  </section>
 