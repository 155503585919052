import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APiService } from 'src/app/core/balco.service';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';

@Component({
  selector: 'app-theme12',
  templateUrl: './theme12.component.html',
  styleUrls: ['./theme12.component.scss']
})
export class Theme12Component implements OnInit {
  Valuesdaywise: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now() - 86400000);
  minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;




  today: number = Date.now();
  kips: any;
  name: string = '';
  file: any;
  selectedFile = null;
  selectedFileType = 'kiln_quality';
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  fileName = '';
  response: string;
  apiresp: any;
  // fileType:any;

  // simpleForm: FormGroup;
  submitted = true;
  alert: boolean
  files: any;
  error: String
  data: String
  res: string;
  nghg: any;
  s3Inten: any;
  constructor(private plotChartsService: PlotChartsService, private ApicallService: APiService, public dialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.getScope1();
    this.getScope2();
    this.getNonG();
    this.getScope3();
    this.getGHG1();
    this.getGHG2();
  }




  getScope1() {
    this.ApicallService.getIntensityS1().subscribe(
      (response: any) => {
        this.s1Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getScope2() {
    this.ApicallService.getIntensityS2().subscribe(
      (response: any) => {
        this.s2Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getScope3() {
    this.ApicallService.getIntensityS3().subscribe(
      (response: any) => {
        this.s3Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getNonG() {
    this.ApicallService.getNonGHG().subscribe((response) => {
      this.nghg = response;
      console.log("values", this.nghg)
    },
      (err) => {
        // (err);

      }
    )
  }

  getGHG1() {
    this.ApicallService.getGHGs1().subscribe(
      (data: any) => {
        this.ghgs1 = data;
        // console.log(this.ghgs1, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getGHG2() {
    this.ApicallService.getGHGs2().subscribe(
      (data: any) => {
        this.ghgs2 = data;
        console.log(this.ghgs2, "data")
      },
      (err) => {
        // (err);

      }
    )
  }


 
  changeDropdown(types: any) {
    this.selectedFileType = types.target.value
    // console.log(this.selectedFileType, "jhv")

  }
  getFile(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log("file", this.selectedFile)
  }

  submitData() {
    let type = this.selectedFileType;
    let fd = new FormData();
    // console.log(fd);
    fd.append('files', this.selectedFile, this.selectedFile.name);
    // console.log( fd.append('files' , this.selectedFile, this.selectedFile.name));
    this.ApicallService.uploadFiles(type, fd).subscribe(
      (response) => {
        this.apiresp = response.body;
        setTimeout(() => {
          this.apiresp = '';
        }, 2000);

        console.log(this.apiresp);
        if (response.status == 200) {
          this.response = "Uploaded successfully."
          setTimeout(() => {
            this.res = '';
          }, 2000);

        }
        if (response.status == 204) {
          this.res = "file is empty please input the data."
          setTimeout(() => {
            this.res = '';
          }, 2000);
        }
        if (response.status == 409) {
          this.apiresp = response;
          console.log(response);
          setTimeout(() => {
            this.apiresp = '';
          }, 2000);
        }
        if (response.status == 206) {
          this.res = "Type is Missing."
          setTimeout(() => {
            this.res = '';
          }, 2000);
        }
      }, (error) => {
        console.log(error);

        this.error = 'Uploaded File not matched with type'
        setTimeout(() => {
          this.error = '';
        }, 2000);

      }
    )
  }
}
