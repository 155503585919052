<section>
  <div
    class="mainGridRgt11"
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      border-bottom: 2px solid;
    "
  >
    <div class="asImgBoxTop pt-2 pb-2">
      <div class="asImgBoxTopTxt">Soft Sensor</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
          <input style="padding-top: 0.9vmin;
          color: white;
          font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate ></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
         font-size: 2.3vmin;
    width: 11vmin;
    cursor: pointer;
    background-color: #302c2c;
    outline: unset;
    /* margin-left: 4%; */
    /* padding-top: 2px; */
    border: 1px solid rgba(255, 255, 255, 0.38);
    height: 5vmin;border-radius: 0.9375em !important;display: flex;justify-content: center;align-items: center;
    " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
        Apply
      </button>
    </div>
  </div>
  <div
    *ngIf="!isLoading"
    style="
      font-size: 13px;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
    "
  >
    <span
      style="
        flex-direction: column;
        display: flex;
        justify-content: space-between;
      "
    >
      <div class="red1">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <p class="mainGridtitle1">RealTime Parameters</p>
          <span style="color: #fff;font-size:2vmin;">
            <tr *ngFor="let vval of Recommendeddata">
              <td>Time -&nbsp;{{ vval.obs_time }}</td>
            </tr>
          </span>
        </div>

        <div class="row" style="padding: 1%">
          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Kiln Speed (RPM)</label>
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.kiln_speed < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_speed >= 0">
                    {{ vval.kiln_speed }}
                  </label>
                </div>
              </span>
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp"
                >PA Fan Speed (RPM)</label
              >
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.pa_fan_speed < 0">0.00</label>
                  <label class="per" *ngIf="vval.pa_fan_speed >= 0">
                    {{ vval.pa_fan_speed }}
                  </label>
                </div>
              </span>
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Kiln Feed (TPH)</label>
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.kiln_feed < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_feed >= 0">
                    {{ vval.kiln_feed }}
                  </label>
                </div>
              </span>
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1%">
          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp"
                >PH Fan Speed (RPM)</label
              >
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.ph_fan_rpm < 0">0.00</label>
                  <label class="per" *ngIf="vval.ph_fan_rpm >= 0">
                    {{ vval.ph_fan_rpm }}
                  </label>
                </div>
              </span>
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">PC Coal (TPH)</label>
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.pc_coal_tph < 0">0.00</label>
                  <label class="per" *ngIf="vval.pc_coal_tph >= 0">
                    {{ vval.pc_coal_tph }}
                  </label>
                </div>
              </span>
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div
              class="red"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Main Coal (TPH)</label>
              <span *ngIf="Recommendeddata.length != 0">
                <div *ngFor="let vval of Recommendeddata">
                  <label class="per" *ngIf="vval.main_coal_tph < 0">0.00</label>
                  <label class="per" *ngIf="vval.main_coal_tph >= 0">
                    {{ vval.main_coal_tph }}
                  </label>
                </div></span
              >
              <div *ngIf="Recommendeddata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="red1">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <p class="mainGridtitle1">Forecasting Values</p>
          <span style="color: #fff;font-size:2vmin;">
            <tr *ngFor="let vval of ForecastKilndata">
              <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                </td> -->
              <td>Time -&nbsp;{{ vval.obs_time }}</td>
            </tr>
          </span>
        </div>

        <div class="row" style="padding: 1%">
          <div class="col-md-6">
            <div
              class="red2"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Free Lime (%)</label>
              <span *ngIf="ForecastKilndata.length != 0">
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.free_lime < 0">0.00</label>
                  <label class="per" *ngIf="vval.free_lime >= 0">
                    {{ vval.free_lime }}
                  </label>
                </div>
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                  "
                  (click)="openDialogfreelime()"
                >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
                    font-size:1.5vmin;
                  "
                >
                  View Chart
                </p>
                </span>
              </span>
              <div *ngIf="ForecastKilndata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="red2"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp"
                >Lime Staturation Factor Ratio</label
              >
              <span *ngIf="ForecastKilndata.length != 0">
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.lsf < 0">0.00</label>
                  <label class="per" *ngIf="vval.lsf >= 0">
                    {{ vval.lsf }}
                  </label>
                </div>
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                  "
                  (click)="openDialogLsf()"
                >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
                    font-size:1.5vmin;
                  "
                >
                  View Chart
                </p>
                </span>
              </span>
              <div *ngIf="ForecastKilndata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 15px">
            <div
              class="red2"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Silica Ratio</label>
              <span *ngIf="ForecastKilndata.length != 0">
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.sr < 0">0.00</label>
                  <label class="per" *ngIf="vval.sr >= 0">
                    {{ vval.sr }}
                  </label>
                </div>
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                  "
                  (click)="openDialogSR()"
                >
                <img
                src="../../../../assets/icons/charticon.png"
               style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
              />
              <p
                style="
                  margin-bottom: 0px;
                  margin-left: 5px;
                  text-decoration: underline;
                  font-size:1.5vmin;
                "
              >
                View Chart
              </p>
                </span>
              </span>
              <div *ngIf="ForecastKilndata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 15px">
            <div
              class="red2"
              style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              "
            >
              <label for="temp">Alumina Modulus Ratio</label
              >
              <span *ngIf="ForecastKilndata.length != 0">
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.af < 0">0.00</label>
                  <label class="per" *ngIf="vval.af >= 0">
                    {{ vval.af }}
                  </label>
                </div>
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                  "
                  (click)="openDialogAF()"
                >
                <img
                src="../../../../assets/icons/charticon.png"
               style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
              />
              <p
                style="
                  margin-bottom: 0px;
                  margin-left: 5px;
                  text-decoration: underline;
                  font-size:1.5vmin;
                "
              >
                View Chart
              </p>
                </span>
              </span>
              <div *ngIf="ForecastKilndata.length == 0 || null">
                <label class="per">No data</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 1%;
          margin-top: 1%;
        "
      >
        <div>
          <span
            ><button
              style="
                 font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                border: 1px solid rgba(255, 255, 255, 0.38);
                align-items: center;
                justify-content: center;
                display: flex;
              "
              *ngIf="!disablePrevButton"
              mat-raised-button
              class="get-sm-btn btn1"
              color="primary"
              (click)="onPrevClick()"
            >
              Previous
            </button></span
          >
        </div>
        <button
          style="
            font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          "
          *ngIf="!disableNextButton"
          mat-raised-button
          class="get-sm-btn btn1"
          color="primary"
          (click)="onNextclick()"
        >
          Next
        </button>
      </div>
    </span>
  </div>

  <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img src="../../../../assets/icons/Spinner.gif" style="width: 10vmin" />
      <p style="text-align: center; color: #fff; font-size: 3vmin">
        Loading...
      </p>
    </div>
  </div>
</section>
