<section>
  <div class="mainGridRgt11"
    style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
    <div class="asImgBoxTop pt-2">
      <div class="asImgBoxTopTxt">Alert Table</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
          <input style="padding-top: 0.9vmin;
          color: white;
          font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate ></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
         font-size: 2.3vmin;
         width: 11vmin;
    cursor: pointer;
    background-color: #302c2c;
    outline: unset;
    /* margin-left: 4%; */
    /* padding-top: 2px; */
    border: 1px solid rgba(255, 255, 255, 0.38);
    height: 5vmin;border-radius: 0.9375em !important;
    display:flex;
    justify-content:center;
    align-items:center
    " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
        Apply
      </button>
    </div> 
    <!-- <div class="date-picker" style="width: 20%">
          <button title="Click to download the reports pdf." style="
                font-size: 16px;
                width: 50px;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                margin-left: 4%;
                padding-top: 2px;
                border: 1px solid rgba(255, 255, 255, 0.38);
                height: 35px;
              " class="get-sm-btn" (click)="getTableGraphValues();getTableGraphValues1();">
            <img src="../../../../assets/images/pdfimg.png" style="width:100%;height: 100%;" />
          </button>
        </div> -->
    <!-- </div> -->
  </div>

  <div *ngIf="!isLoading"
    style="font-size: 13px;display: flex;flex-direction: column; justify-content: space-between;">
    <div class="red1">
      <div class="row" style="padding: 1%;">
        <div class="col-md-12">
          <div style="display: flex;flex-direction:row;justify-content:space-between;align-items:center">
          <p class="mainGridtitle1" style="font-size: 2.5vmin;font-weight: 600;margin: 0px;">Alert Table</p>
          <select class="select-dropdown" name="select" id="" style="
                        padding: 5px;
                        border-radius: 4px;
                        border: 1px solid rgba(255, 255, 255, 0.38);
                        font-weight: 600;
                        font-size: 2vmin;
                        width: 18vmin;
                        margin-bottom:10px;
                        box-shadow: 3px 3px 5px #5a5a5a;
                      "(change)="changeDropdownTabel($event)" >
                  <option value="1">Clinker Quality</option>
                  <option value="2">Kiln</option>
                </select>
              </div>
          <table class="tableStyle1" style="overflow-x:scroll;" *ngIf="SopAlerts.length != 0">
            <tr *ngIf="this.tabelID == 1">
              <th colspan="1" class="table-head">Recorded
                Time</th>
              <th colspan="1" class="table-head">Clinker
                Quality</th>
              <!-- <td class="table-data">{{vval.obs_time}}</td>
                <td class="table-data">{{vval.value}}</td> -->
            </tr>

            <tr *ngIf="this.tabelID == 2">
              <th colspan="1" class="table-head">Recorded
                Time</th>
              <th colspan="1" class="table-head">Kiln</th>
              <!-- <td class="table-data">{{vval.obs_time}}</td>
                <td class="table-data">{{vval.value}}</td> -->
            </tr>
            <tr *ngFor="let vval of SopAlerts  ">
              <!-- <th style="font-size: 16px;color: yellow;">Main Coal(TPH)</th> -->
              <td class="table-data">{{vval.obs_time| date:'short'}}</td>
              <td class="table-data">{{vval.value}}</td>
              <!-- <td class="table-data">{{vval.coal_savings}}</td> -->
            </tr>
            <!-- <tr>
                <th style="font-size: 16px;color: yellow;">Kiln speed(RPM)</th>
                <td class="table-data">{{vval.kiln_actual}}</td>
                <td class="table-data">{{vval.kiln_optimized}}</td>
                <td class="table-data">{{vval.kiln_savings}}</td>
              </tr> -->
          </table>

          <table class="tableStyle1" *ngIf="SopAlerts.length == 0">
            <tr>
              <th colspan="1" class="table-head">Recorded
                Time</th>
              <th colspan="1" class="table-head">Clinker
                Quality</th>
              <!-- <td class="table-data">{{vval.obs_time}}</td>
                <td class="table-data">{{vval.value}}</td> -->
            </tr>
            <tr>
              <!-- <th style="font-size: 16px;color: yellow;">Main Coal(TPH)</th> -->
              <td colspan="2">No Data</td>
              <!-- <td class="table-data">{{vval.coal_savings}}</td> -->
            </tr>
            <!-- <tr>
                <th style="font-size: 16px;color: yellow;">Kiln speed(RPM)</th>
                <td class="table-data">{{vval.kiln_actual}}</td>
                <td class="table-data">{{vval.kiln_optimized}}</td>
                <td class="table-data">{{vval.kiln_savings}}</td>
              </tr> -->
          </table>
        </div>
      </div>
    </div>


    <div class="red1">
      <div class="row" style="padding: 1%;">
        <div class="col-md-12">
          <div style="display:flex ;flex-direction: row;justify-content: space-between;">
            <p class="mainGridtitle1" style="font-size: 2.5vmin;font-weight: 600;margin: 0px;">SOP Table</p>
            <span style=" color: #fff;">
              <tr *ngIf="SopTable.length != 0">
                <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                          </td> -->
                <td *ngFor="let vval of SopTable" style="font-size: 2.5vmin;">Time -&nbsp;{{vval.obs_time | date: 'HH:mm' }}
                </td>
              </tr>
              <tr *ngIf="SopTable.length == 0">
                <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                          </td> -->
                <td *ngFor="let vval of SopTable  ">No Data
                </td>
              </tr>
            </span>
          </div>
          <table class="tableStyle1" style="overflow-x:scroll;" *ngIf="SopTable.length != 0">
            <tr>
              <th class="table-head" colspan="3">Constrained
                Value</th>
              <!-- <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th> -->
            </tr>
            <tr>
              <td class="table-head1">Recorded Time</td>
              <td class="table-head1">Temperature (°C)</td>
              <td class="table-head1">CO (PPM)</td>
              <!-- <td class="table-head1">Coal (TPH)</td>
                  <td class="table-head1">AFR (M3/Hr)</td>
                </tr> -->
            <tr *ngFor="let vval of SopTable  ">
              <td class="table-data">{{vval.obs_time| date:'short'}}</td>
              <td class="table-data">{{vval.constrained_temp}}</td>
              <td class="table-data">{{vval.constrained_co}}</td>
              <!-- <td>- &nbsp;{{vval.coal_decreased}}</td>
                   <td> + &nbsp;{{vval.afr_increased}}</td> -->
            </tr>
          </table>

          <table class="tableStyle1" *ngIf="SopTable.length == 0">
            <tr>
              <th colspan="3" class="table-head">Constrained
                Value</th>
              <!-- <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th> -->
            </tr>
            <tr>
              <td class="table-head1">Recorded Time</td>
              <td class="table-head1">Temperature (°C)</td>
              <td class="table-head1">CO (PPM)</td>
              <!-- <td class="table-head1">Coal (TPH)</td>
                  <td class="table-head1">AFR (M3/Hr)</td>
                </tr> -->
            <tr>
              <td colspan="3">No Data</td>
              <!-- <td>- &nbsp;{{vval.coal_decreased}}</td>
                   <td> + &nbsp;{{vval.afr_increased}}</td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- <hr style="    margin-top: 10px !important;
          margin-bottom: 7px !important;
      "> -->

    <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
            margin-top: 1%;
        ">
      <div>
        <span><button style="
            font-size: 2.2vmin;
            width: 12vmin;
            height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          " *ngIf="!disablePrevButton" mat-raised-button class="get-sm-btn btn1" color="primary"
            (click)="onPrevClick()"> Previous</button></span>
        <!-- <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button disabled style="border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;">
              Previous</button></span> -->
      </div>
      <button style="
        font-size: 2.2vmin;
        width: 12vmin;
        height: 5vmin;
        cursor: pointer;
        background-color: #302c2c;
        outline: unset;
        border: 1px solid rgba(255, 255, 255, 0.38);
        align-items: center;
        justify-content: center;
        display: flex;
      " *ngIf="!disableNextButton" mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onNextclick()">
        Next</button>
    </div>
  </div>

  <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img src="../../../../assets/icons/Spinner.gif" style="width: 10vmin" />
      <p style="text-align: center;color: #fff;font-size:3vmin">Loading...</p>
    </div>
  </div>
</section>