import { Component, OnInit } from '@angular/core';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from './../../../core/balco.service';
@Component({
  selector: 'app-mushroom-prediction',
  templateUrl: './mushroom-prediction.component.html',
  styleUrls: ['./mushroom-prediction.component.scss']
})
export class MushroomPredictionComponent implements OnInit {
 

  constructor(private ApicallService: APiService,) { 
 
  }

  ngOnInit(): void {
  
  }


 


}
