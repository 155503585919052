import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit ,Input} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
// import { setInterval } from 'timers';
import { UtilsService } from 'src/app/core/utils.service';

@Component({
  selector: 'app-theme9',
  templateUrl: './theme9.component.html',
  styleUrls: ['./theme9.component.scss'],
})
export class Theme9Component implements OnInit {
  @Input() ForecastKilndata1: any[];

  [x: string]: any;
  isLoading: boolean = true;

  Realtimedata: any = [];
  Forecastdata: any = [];
  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  status1: any;
  valuestatus: any;
  checkstatus: any;
  coloval: any;
  Realtimedatadt: any;
  Forecastalertdatadt: any;
  Forecastdatatemp: any;
  ForecastdatatempAvg: any;
  ForecastKilndata:any;
  ForecastKilnRCdata:any;
  ActualForecastKilnRCdata:any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');
  colorMix1: any = [];
  disablePrevButton: boolean = false;
  disableNextButton: boolean = false;
  parsedObsTime: Date | null = null;
  parsedObsTime1: Date | null = null;

  selectedMainCoal: any;
  selectedPcCoal: any;
  selectedKilnSpeed: any;
  selectedPaFanSpeed: any;
  SimulationData: any;
  options: any=[]
  options1: any=[]
  options2: any=[]
  options3: any=[]

  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
    readonly utils: UtilsService,) {
  }

  openDialogMC() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 15,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialogKS() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 16,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialogPA() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 17,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }



  ngOnInit() {
    this.isLoading = true;
    this.kilnToggle1 = 1;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
    // if(   this.kilnToggle1 = 1) {
      this.getActualkilnforecastRC();
      this.getkilnforecastRC();
      this.getkilnforecast();
    // }else if(this.AfrToggle1 = 2){
      // this.getKilnDropdownList();
      // this.getKilnSimTable();
    //  }

    // setTimeout(() => {
     
    // }, 100);
  
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    // console.log(this.appliedDateFilters, startDate)
    // this.selectedDatenext='1660847400';
    // this.selectedForecastDatenext = parseInt(this.selectedDatenext) + 300;

    // this.selectedMainCoal = this.options[0];
    //   this.selectedPcCoal = this.options1[0];
    //   this.selectedKilnSpeed = this.options2[0];
    //   this.selectedPaFanSpeed = this.options3[0];
    
  }
  getKilnDropdownList() {

    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getKilnDropdown(this.startDate).subscribe(
      (data: any) => {
          this.ListID = data;
          console.log("this.ListID ", this.ListID );
          this.options = this.ListID[0].main_coal;
          this.options1 = this.ListID[0].pc_coal;
          this.options2 = this.ListID[0].kiln_speed;
          this.options3 = this.ListID[0].pa_fan_speed;
          this.selectedMainCoal = this.options[0];
          this.selectedPcCoal = this.options1[0];
          this.selectedKilnSpeed = this.options2[0];
          this.selectedPaFanSpeed = this.options3[0];
          console.log("this.this.selectlist ", this.selectedMainCoal, 
           this.selectedPcCoal,   this.selectedKilnSpeed,   this.selectedPaFanSpeed );
           setTimeout(() => {
            this.Submit();
          }, 1500);
        },
        (err) => {
          this.ListID = [];
          console.log(err);
        }
      );
  }


  Submit(){
    console.log("hello")
    this.ApicallService.getKilnSimulationValues(  this.startDate,this.selectedMainCoal,this.selectedPcCoal,
     this.selectedPaFanSpeed,this.selectedKilnSpeed).subscribe(
      (data: any) => {
        this.SimulationData = data;
        this.SimulationData1 = this.SimulationData.burning_zone_temp;
        this.SimulationData2 = this.SimulationData.free_lime;
        console.log("  this.SimulationData",   this.SimulationData, 'dd', this.SimulationData1)
      },
      (err) => {
        this.ListID = [];
        console.log(err);
      }
    );
  }

  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getActualkilnforecastRC();
    this.getkilnforecastRC();
    this.getkilnforecast();
    this.getKilnSimTable();
    this.getKilnDropdownList();
  }
  
  getActualkilnforecastRC(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;

        this.parsedObsTime = new Date(this.ActualForecastKilnRCdata[0].obs_time);

        console.log(this.ActualForecastKilnRCdata, "data")

        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
      },
      (err) => {
        // (err);

      }
    ) 
  }

  getkilnforecast(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getForecastKiln( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;
        this.colorMix1 = this.ForecastKilndata[0].alert_level;
        console.log(this.ForecastKilndata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }

  getkilnforecastRC(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;
        let timeVal = this.ForecastKilnRCdata[0].obs_time;

        console.log(this.ForecastKilnRCdata, "time")
      },
      (err) => {
        // (err);

      }
    ) 
  }

  getKilnSimTable() {
    this.ApicallService.getKilnsimulatorTable(this.startDate).subscribe(
      (data: any) => {
        this.KilnTabledata = data;
        this.KilnRealtimeTable = this.KilnTabledata.real_time_params;
        this.KilnRecommendedTable = this.KilnTabledata.recommended_params;

        this.parsedObsTime1 = new Date(this.KilnRealtimeTable[0].obs_time);

        
        if (this.parsedObsTime1 && this.parsedObsTime1.getHours() === 0 && this.parsedObsTime1.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime1 && this.parsedObsTime1.getHours() === 23 && this.parsedObsTime1.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }

      },
      (err) => {
        // (err);

      }
    )
  }




  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
    this.getKilnSimTable();
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;
      },
      (err) => {
        // (err);

      }
    ) 

    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;

        this.parsedObsTime = new Date(this.ActualForecastKilnRCdata[0].obs_time);


        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);

      }
    )

    this.ApicallService.getForecastKiln( this.startDate).subscribe(
        (data: any) => {
          this.ForecastKilndata = data;
          this.colorMix1 = this.ForecastKilndata[0].alert_level;
        },
        (err) => {
          // (err);
        }
      )

      this.ApicallService.getKilnDropdown(this.startDate).subscribe(
        (data: any) => {
            this.ListID = data;
            console.log("this.ListID ", this.ListID );
            this.options = this.ListID[0].main_coal;
            this.options1 = this.ListID[0].pc_coal;
            this.options2 = this.ListID[0].kiln_speed;
            this.options3 = this.ListID[0].pa_fan_speed;
            this.selectedMainCoal = this.options[0];
            this.selectedPcCoal = this.options1[0];
            this.selectedKilnSpeed = this.options2[0];
            this.selectedPaFanSpeed = this.options3[0];
            console.log("this.this.selectlist ", this.selectedMainCoal, 
             this.selectedPcCoal,   this.selectedKilnSpeed,   this.selectedPaFanSpeed );
             setTimeout(() => {
              this.Submit();
            }, 1500);
          },
          (err) => {
            this.ListID = [];
            console.log(err);
          }
        );

      // this.ApicallService.getKilnsimulatorTable( this.startDate).subscribe(
      //   (data: any) => {
      //     this.KilnTabledata = data;
      //     this.KilnRealtimeTable = this.KilnTabledata.real_time_params;
      //   this.KilnRecommendedTable = this.KilnTabledata.recommended_params;
      //   },
      //   (err) => {
      //     // (err);
      //   }
      // )
  }
  onPrevClick(){
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) - 600;
    console.log(this.startDate)
    this.getKilnSimTable();
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;
      },
      (err) => {
        // (err);

      }
    ) 

    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;


        this.parsedObsTime = new Date(this.ActualForecastKilnRCdata[0].obs_time);

        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);

      }
    ) 

    this.ApicallService.getKilnDropdown(this.startDate).subscribe(
      (data: any) => {
          this.ListID = data;
          console.log("this.ListID ", this.ListID );
          this.options = this.ListID[0].main_coal;
          this.options1 = this.ListID[0].pc_coal;
          this.options2 = this.ListID[0].kiln_speed;
          this.options3 = this.ListID[0].pa_fan_speed;
          this.selectedMainCoal = this.options[0];
          this.selectedPcCoal = this.options1[0];
          this.selectedKilnSpeed = this.options2[0];
          this.selectedPaFanSpeed = this.options3[0];
          console.log("this.this.selectlist ", this.selectedMainCoal, 
           this.selectedPcCoal,   this.selectedKilnSpeed,   this.selectedPaFanSpeed );
           setTimeout(() => {
            this.Submit();
          }, 1500);
        },
        (err) => {
          this.ListID = [];
          console.log(err);
        }
      );

    this.ApicallService.getForecastKiln( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;
        this.colorMix1 = this.ForecastKilndata[0].alert_level;
      },
      (err) => {
        // (err);
      }
    )
  }
  onItemChangekilnsim(event) {
    this.kilnToggle1 = 2;
    this.getKilnDropdownList();
      this.getKilnSimTable();
  }

  onItemChangekilnsimback(event) {
    this.kilnToggle1 = 1;
  }
}
