<section>
    <div class="card" style="margin-left: -10px;">
        <div class="row">
            <!-- Energy -->
            <div class="col-md-12" style="display:grid;">
                <div class="red1">
                    <div class="row" style="padding-left: 1%;
          padding-right: 1%;">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                                    Energy
                                   </div>
                                   <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                                    Intensity
                                     <span *ngFor="let items3 of s3Inten" style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items3.energy_intensity}}</span>
                                    </div>
                            </div>
                            <!-- <hr> -->

                            <div class="row" style="padding: 1%;">
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Electricity</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.electricity_consumption}} Gj</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Fuel</label>
                                        <div *ngFor="let items of ewwdata">
                                            <label class="per">{{items.fuel_consumption}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Renewable</label>
                                        <div *ngFor="let items of ewwdata">
                                            <label class="per">{{items.percent_recycle_waste_qty}} %</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Non Renewable</label>
                                        <div *ngFor="let items of ewwdata">
                                            <label class="per">{{items.percent_non_recycle_waste_qty}} %</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
<!-- Water -->
            <div class="col-md-12" style="display:grid;">
                <div class="red1">
                    <div class="row" style="padding-left: 1%;
          padding-right: 1%;">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                                    Water
                                   </div>
                                   <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                                    Intensity
                                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;" *ngFor="let items of s4Inten">{{items.water_intensity}}</span>
                                    </div>
                            </div>
                            <div class="row" style="padding: 1%;">
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Surface Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.surface_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Ground Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.ground_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Third Party Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.third_party_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding: 1%;">
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Sea Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.sea_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Treated Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.treated_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Discharged Water</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.discharged_water}} Ml</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" style="display:grid;">
                <div class="red1">
                    <div class="row" style="padding-left: 1%;
          padding-right: 1%;">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                                    Wastage
                                   </div>
                                   <!-- <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                                    Intensity
                                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">--</span>
                                    </div> -->
                            </div>
                            <!-- <hr> -->

                            <div class="row" style="padding: 1%;">
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Plastic Wastage</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.plastic_wasteage_qty}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">E-Waste</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.e_waste_and_water_discharge_qty}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Bio Medical Waste</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.bio_medical_waste_qty}} kg</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Construction</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.c_d_waste_qty}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding: 1%;">
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Battery Waste</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.battery_waste_qty}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Radioactive Waste</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.radioactive_waste_qty}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Recycled</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.recycled}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Reused</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.reused}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding: 1%;">
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Incineration</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.incineration}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Land Filling</label>
                                        <div  *ngFor="let items of ewwdata">
                                            <label class="per">{{items.land_filling}} Tons</label>
                                            <label class="per"></label>
                                        </div>
                                        <!-- <div ><label class="per">No data</label></div> -->
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Discharged Water</label>
                                        <div>
                                            <label class="per">--</label>
                                            <label class="per"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="red" style="border-radius: 6px;
            box-shadow: inset 7px 7px 14px black,
                        inset -7px -7px 14px #5a5a5a;">
                                        <label for="temp">Discharged Water</label>
                                        <div>
                                            <label class="per">--</label>
                                            <label class="per"></label>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
       


        <!-- </div> -->



        <!-- <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
      margin-top: 1%;
  ">
  <div>
    <span *ngIf="this.selectedDatenext>'1660847400'"><button mat-raised-button color="primary"
        (click)="onPreviousclick()"> Previous</button></span>
    <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button  disabled style="border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;">
        Previous</button></span>
  </div>
  <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
  </div> -->


    </div>
</section>