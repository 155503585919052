
import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// import { ngxCsv } from 'ngx-csv/ngx-csv';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/core/utils.service';

const baseUrl = environment['baseUrl'];
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-theme10',
  templateUrl: './theme10.component.html',
  styleUrls: ['./theme10.component.scss']
})

export class Theme10Component implements OnInit {
  // baseUrl='https://api.dev.afr4.0.livnsense.com';
  [x: string]: any;
  today: number = Date.now();
  kips: any;
  graphValue: any = [];
  graphValuefc1: any = [];
  fcgraph1: any;
  graphValuefc2: any = [];
  fcgraph2: any;
  selectedDatenext: any;
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  data: any;
  data1: any;
  data2:any;
  data3:any;
  pdfUrl:any;
  pdfUrl1:any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');
  startdatef:any
  statusval: any =[];
  plantstatusval: any;
  coloval1: string;
  valuestatus1: string;
  checkstatus1: string;
  ForecastKilndata:any;
  AfrOptData: any;
  KilnrcmdData: any;
  isLoading: boolean = true;
  disablePrevButton: boolean = false;
  disableNextButton: boolean = false;
  parsedObsTime: Date | null = null;
  tabelID: any;
  
  // KilnrcmdData1: any;
  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog,private http: HttpClient, readonly utils: UtilsService,) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.tabelID = 1;
    this.selectedDatenext = '1660847400';
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
    // setTimeout(()=>{   
      this.getSopTable();                        
      this.getSopAlerts();
        // }, 500);

        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
  }
  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getSopTable();                        
      this.getSopAlerts();
  }
  // ngOnChanges() {
  //   this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
  //   this.utils.dateFilter = this.appliedDateFilters;
  //   console.log(this.appliedDateFilters['start_date'] ,
  //   this.appliedDateFilters.start_date,
  //   "changedata")
  //   this.getClinker();
  // }


  getSopTable(){
    this.startDate  =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime()/1000
    ;
    console.log(   this.startDate , "apidate1")
    this.ApicallService.SopTable(  this.startDate ).subscribe(
      (data: any) => {
        this.SopTable = data;
        console.log(this.SopTable,"apte2")

        this.parsedObsTime = new Date(this.SopTable[0].obs_time);

        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        (err);

      }
    )
  }

  getSopAlerts(){
    this.startDate  =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime()/1000
    ;
    console.log(   this.startDate , "apidate1")
    this.ApicallService.SopAlerts(  this.startDate ).subscribe(
      (data: any) => {
        this.SopAlerts = data;
        console.log(this.SopAlerts,"ap2")
      },
      (err) => {
        (err);

      }
    )
  }


  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
    this.ApicallService.SopTable(this.startDate).subscribe(
      (data: any) => {
        this.SopTable = data;
        console.log(this.SopTable,"apte2")

        this.parsedObsTime = new Date(this.SopTable[0].obs_time);

        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        (err);

      }
    )
  }
  onPrevClick(){
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) - 600;
    console.log(this.startDate)
    this.ApicallService.SopTable(this.startDate).subscribe(
      (data: any) => {
        this.SopTable = data;
        console.log(this.SopTable,"apte2")

        this.parsedObsTime = new Date(this.SopTable[0].obs_time);

        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        (err);

      }
    )
  
  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    console.log(this.selectedDatenext)

  }

  changeDropdownTabel(types: any) {
    this.tabelID = types.target.value;
  }


}






