import { Component, OnInit } from '@angular/core';
import { MatDialog ,MatDialogRef} from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { UtilsService } from 'src/app/core/utils.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  constructor(  public dialog: MatDialog,
    readonly utils: UtilsService,) { }
  isHighlighted = false;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');
  onMouseOver() {
    this.isHighlighted = true;
    console.log(" this.isHighlighted ",  this.isHighlighted  )
  }

  onMouseOut() {
    this.isHighlighted = false;
    console.log(" this.isHighlighted ",  this.isHighlighted  )

  }

  onClick() {
    // alert('SVG rectangle clicked!');
    console.log(" this.isHighlighted ",  this.isHighlighted  )

  }
  openDialogFreelimekiln() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 11,
          ApiDate: this.appliedDateFilters["start_date"]
       },
      }
    );
  }
  ngOnInit(): void {
    

    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
  }

}
