import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { UtilsService } from 'src/app/core/utils.service';

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-theme4',
  templateUrl: './theme4.component.html',
  styleUrls: ['./theme4.component.scss']
})
export class Theme4Component implements OnInit {

  today: number = Date.now();
  kips: any;
  Apilabel: any = 'Actual vs Predicted PO4';
  label: any;
  values: any;
  listOfGraphData = [];
  alumFormVal: any;
  alumTapVal: any;
  anodeVal: any;
  temperatureVal: any;
  errMesage;
  submitted: boolean;
  potNo: any;
  selectProductType = [
    'Actual vs Predicted PO4',
    'Actual vs Predicted pH',
  ];
  selectedValue;
  xalfs: any;
  temps: any;
  boilerNo: any;
  actualVal: any;
  receivedData = [];
  receivedData1 = [];
  selectedGraphAFR = '';
  tableSettings: any = [];
  graphTableValues: any = [];
  graphValue: any;
  // appliedDateFilters: any = {};
  // maxDate: Date = new Date();
  AFRdata: any = [];
  AFRdata1: any = [];
  Coaldata: any = [];
  startRealTime = '1660847400';

  graphValueafr: any = [];
  selectedDatenext: any;

  AfrToggle1: number= 1;

  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');
  startDate: any;
  isLoading: boolean = true;
  disablePrevButton: boolean = false;
  disableNextButton: boolean = false;
  parsedObsTime: Date | null = null;
  parsedObsTime2: Date | null = null;
  // AfrToggle1: any;

  selectedAfrQty: any;
  selectedMainCoal: any;
  selectedPcCoal: any;
  selectedPaFanSpeed: any;
  areDropdownsEnabled = false;
  isButtonEnabled = false;
  cyclone6AvgTemperature: any;
  optionsMC: any=[] 
  optionsPcCoal: any=[]
  optionsPaFanSpeed: any=[] 
  optionsAfrQty: any=[]
  enableDropdowns = false;
  ListID: any;
  selectId: any;
  selectlist: any;
  ListID1: any;
  SimulationData: any;
  SimulationData1: any;
  SimulationData2: any;
  AFRTabledata: any;
  AFRRealtimeTable: any = [];
  AFRRecommendedTable: any = [];
  AFRNewTabledata: any;
  graphID: any;
  AFRChangedTable: any;
  liqiAfrFlReal: any;
  liqiAfrFlchanged: any;
  mycolor: string;
  mcChanged: any;
  mcReco: any;
  mycolor1: string;
  pcChanged: any;
  pcReco: any;
  mycolor2: string;


  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog, readonly utils: UtilsService,) {
    this.ApicallService.boilerNo.subscribe((res) => {
      this.boilerNo = res;
    });
  }

  areAllDropdownsSelected() {
    return (
      this.selectedAfrQty &&
      this.selectedMainCoal &&
      this.selectedPcCoal &&
      this.selectedPaFanSpeed
    );
  }

 
  ngOnInit() {
    this.isLoading = true;
    this.AfrToggle1 = 1;
    this.graphID = 1;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
 
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    // console.log(this.appliedDateFilters['start_date'] ,"date1")
  //  if(   this.AfrToggle1 = 1){
    // this.getAFRTable();
    // this.getAFRTable1();
    // this.getCOALTable();
    // this.getAFrGraph();
  //  }else if(this.AfrToggle1 = 2){
    // this.getDropdownList();
    // this.getAFRSimTable();
    this.getAFRNewTable();
    this.getAfrNewGraph();
  //  }
  
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);


  }
  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    // this.startDate
    // this.getAFRTable();
    // this.getAFrGraph();
    this.getAFRSimTable();
    this.getAFRNewTable();
    this.getAfrNewGraph();
    // this.getClinker();

  }

 //  list Dropdown Api Call
 getDropdownList() {
  this.ApicallService.getAfrDropdown().subscribe(
    (data: any) => {
        this.ListID = data;
        console.log("this.ListID ", this.ListID );
        this.optionsAfrQty = this.ListID[0].liq_afr_qty;
        this.selectedAfrQty = this.optionsAfrQty[0];
        console.log("this.this.selectlist ", this.selectedAfrQty,  this.optionsAfrQty );
        setTimeout(() => {
          this.getAfrBAsedDD();
        }, 1000);
        setTimeout(() => {
          this.Submit();
        }, 3000);
      },
      (err) => {
        this.ListID = [];
        console.log(err);
      }
    );
 
}

getAfrBAsedDD(){

  this.ApicallService.getAfrBasedDropdown(this.startDate).subscribe(
    (data: any) => {
      this.ListID1 = data;
      console.log("this.ListID1 ", this.ListID1 );
      this.optionsAfrQty = this.ListID1[0].liquid_afr_flow_rate;
      this.selectedAfrQty = this.optionsAfrQty[0];
      this.optionsMC = this.ListID1[0].main_coal;
      this.optionsPcCoal = this.ListID1[0].pc_coal;
      this.optionsPaFanSpeed = this.ListID1[0].pa_fan_speed;
      this.selectedMainCoal = this.optionsMC[0];
      this.selectedPcCoal = this.optionsPcCoal[0];

 

      this.selectedPaFanSpeed = this.optionsPaFanSpeed[0];

      setTimeout(() => {

        this.Submit();

      }, 3000);

    },

    (err) => {

      this.ListID = [];

      console.log(err);

    }

    );

}

changeDropdownId(types: any) {
  this.selectedAfrQty = [];
  this.ListID = types.target.value;
  this.selectedAfrQty = this.ListID;
  // console.log("this.selectedAfrQty ", this.selectedAfrQty );
this.getAfrBAsedDD();
 
}

changeDropdownGraph(types: any) {
  this.graphID = types.target.value;
  if (this.graphID == 1) {
    this.DrawAfrKilnChart();
  } else if (this.graphID == 2) {
    this.DrawAfrPccoalChart();
  } else if (this.graphID == 3) {
    this.DrawAfrPaAirChart();
  } else if (this.graphID == 4) {
    this.drawAFRChart();
  }
}

Submit(){
  console.log("hello")

  this.ApicallService.getAFRSimulationValues(  this.startDate,this.selectedMainCoal,
    this.selectedPcCoal, this.selectedPaFanSpeed,this.selectedAfrQty).subscribe(
    (data: any) => {
      this.SimulationData = data;
      this.SimulationData1 = this.SimulationData.burning_zone_temp;
        this.SimulationData2 = this.SimulationData.pc_temp;
    },
    (err) => {
      this.SimulationData = [];
      console.log(err);
    }
  );
}

  onItemChangeAfrsim(event) {
    this.AfrToggle1 = 2;
    // this.getDropdownList();
    this.getAfrBAsedDD();
    this.getAFRSimTable();
  }

  onItemChangeAfrsimback(event) {
    this.AfrToggle1 = 1;
    // this.getAFrGraph();
  }

  getAFRTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;

        this.parsedObsTime = new Date(this.AFRdata[0].obs_time);
        

        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);

      }
    )

  }

  getAFRNewTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000;
    this.ApicallService.getAFRNewTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRNewTabledata = data;

        this.parsedObsTime2 = new Date(this.AFRNewTabledata[0].obs_time);
        console.log(this.startDate,'get')
        

        
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 0 && this.parsedObsTime2.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 23 && this.parsedObsTime2.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        (err);

      }
    )

  }

  getAFRTable1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues1(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getCoalTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }

  getAFRSimTable() {
    this.ApicallService.getAFRsimulatorTable(this.startDate).subscribe(
      (data: any) => {
        this.AFRTabledata = data;
        this.AFRRealtimeTable = this.AFRTabledata.real_time_params;
        this.AFRRecommendedTable = this.AFRTabledata.recommended_params;
        this.AFRChangedTable = this.AFRTabledata.changed_params;

        this.liqiAfrFlReal = this.AFRRealtimeTable[0].liquid_afr_flow_rate
        this.liqiAfrFlchanged = this.AFRChangedTable[0].liquid_afr_flow_rate

        this.mcChanged = this.AFRChangedTable[0].main_coal_tph
        this.mcReco = this.AFRRecommendedTable[0].mc_coal_tph

        this.pcChanged = this.AFRChangedTable[0].pc_coal_tph
        this.pcReco = this.AFRRecommendedTable[0].pc_coal_tph



        console.log(this.liqiAfrFlReal,this.liqiAfrFlchanged)
        if (this.liqiAfrFlReal != this.liqiAfrFlchanged){
          this.mycolor = "#3794ff";
          console.log("true")
        }else{
          this.mycolor = "#fff"
          console.log("false")
        }

        if (this.mcChanged != this.mcReco){
          this.mycolor1 = "#2cdd2c";
          console.log("true")
        }else{
          this.mycolor1 = "#fff"
          console.log("false")
        }

        if (this.pcChanged != this.pcReco){
          this.mycolor2 = "#2cdd2c";
          console.log("true")
        }else{
          this.mycolor2 = "#fff"
          console.log("false")
        }
      },
      (err) => {
        // (err);

      }
    )
  }


  getAfrNewGraph() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    let ref = this;
    ref.plotChartsService.getAfrNewGraphData(this.startDate).subscribe((res) => {
      ref.graphValueafr = res;

      // console.log("jhbh", ref.graphValueafr)
      setTimeout(() => {
        this.graphID = 1;
        // this.isLoading = true;
        // ref.DrawAfrKilnChart();
      }, 1000);
      setTimeout(() => {
        // this.graphID = 1;
        // this.isLoading = false;
        ref.DrawAfrKilnChart();
      }, 3000);

      // ref.drawFC2Chart();

    });
  }

 

  drawAFRChart() {
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv1", am4charts.XYChart);
      chart.logo.disabled = true;
      // chart.background.fill = am4core.color('#1e1e1e');

      var chartData = this.graphValueafr;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;
      valueAxis.title.text = "AFR Increased";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = " Kiln Coal Increased";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "PC Coal Increased";
      valueAxis3.title.fontSize = "12px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      
      let valueAxis4 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis4.maxPrecision = 0;
      valueAxis4.title.text = "PA Air Increased";
      valueAxis4.title.fontSize = "12px";
      valueAxis4.title.fill = am4core.color("#FFF");
      valueAxis4.cursorTooltipEnabled = false;
      valueAxis4.renderer.opposite = true;
      valueAxis4.min = 0;
      valueAxis4.syncWithAxis = valueAxis;
      valueAxis4.renderer.labels.template.fontSize = 12;
      valueAxis4.renderer.labels.template.fill = am4core.color("#fff");
      
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "afr_increased";
      columnSeries.dataFields.categoryX = "obs_time";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      columnSeries.columns.template.tooltipText =
        "[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText = " AFR Increased:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#55FF5C");
      columnSeries.columns.template.fill = am4core.color("#55FF5C");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "afr_increased";
      columnSeries.legendSettings.valueText =
        " [#55FF5C font-size: 10px] AFR Increased[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "kiln_coal_increased";
      lineSeries.dataFields.categoryX = "obs_time";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#32CD30");
      lineSeries.fill = am4core.color("#32CD30");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = " Kiln Coal Increased:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Kiln Coal Increased";
      lineSeries.legendSettings.valueText =
        "[#32CD30 font-size:10px] Kiln Coal Increased[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "pc_coal_increased";
      lineSeries1.dataFields.categoryX = "obs_time";
      // lineSeries1.tooltipText = "Energy" + `:{${"qty_cng_perton_m3"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#D6E865");
      lineSeries1.fill = am4core.color("#D6E865");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
        " PC Coal Increased:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "PC Coal Increased";
      lineSeries1.legendSettings.valueText =
        "[#D6E865 font-size:10px ]PC Coal Increased[/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";

      let lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.dataFields.valueY = "pa_air_increased";
      lineSeries2.dataFields.categoryX = "obs_time";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      lineSeries2.yAxis = valueAxis4;
      lineSeries2.stroke = am4core.color("#00cccc");
      lineSeries2.fill = am4core.color("#00cccc");
      lineSeries2.strokeWidth = 3;
      // lineSeries2.tensionX = 0.9;
      lineSeries2.propertyFields.strokeDasharray = "lineDash";
      lineSeries2.tooltip.label.textAlign = "middle";
      lineSeries2.tooltipText = " PA Air Increased:{valueY.formatNumber('#.0')}";
      lineSeries2.name = "PA Air Increased ";
      lineSeries2.legendSettings.valueText =
        "[#00cccc  font-size:10px] PA Air Increased[/]";
      lineSeries2.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#32CD30"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 3;

      circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 3;
      circle1.strokeWidth = 3;

      let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#00cccc"); // tooltips grab fill from parent by default
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 3;
      circle1.strokeWidth = 3;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

     
 //Add scrollbar
 chart.scrollbarX = new am4core.Scrollbar();
 chart.scrollbarX.parent = chart.topAxesContainer;
 chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
 chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
 chart.scrollbarX.minHeight = 7;
 // Applied on hover
 chart.scrollbarX.startGrip.background.states.getKey(
   "hover"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.endGrip.background.states.getKey(
   "hover"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
   am4core.color("rgb(64, 60, 60)");
 // Applied on mouse down
 chart.scrollbarX.startGrip.background.states.getKey(
   "down"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.endGrip.background.states.getKey(
   "down"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
   am4core.color("rgb(64, 60, 60)");
 //Add scrollbar
 chart.scrollbarX = new am4core.Scrollbar();
 chart.scrollbarX.parent = chart.topAxesContainer;
 chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
 chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
 chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
 chart.scrollbarX.minHeight = 7;
 // Applied on hover
 chart.scrollbarX.startGrip.background.states.getKey(
   "hover"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.endGrip.background.states.getKey(
   "hover"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
   am4core.color("rgb(64, 60, 60)");
 // Applied on mouse down
 chart.scrollbarX.startGrip.background.states.getKey(
   "down"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.endGrip.background.states.getKey(
   "down"
 ).properties.fill = am4core.color("rgb(64, 60, 60)");
 chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
   am4core.color("rgb(64, 60, 60)");

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }

  DrawAfrKilnChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      let chart = am4core.create("ppediv1", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color('#1e1e1e');

      var chartData = this.graphValueafr;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;
      valueAxis.title.text = "AFR Increased";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Kiln Coal Increased";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.valueY = "afr_increased";
      columnSeries.dataFields.categoryX = "obs_time";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      columnSeries.columns.template.tooltipText ="[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText = " AFR Increased:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#55FF5C");
      columnSeries.columns.template.fill = am4core.color("#55FF5C");
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "AFR Increased";
      columnSeries.legendSettings.valueText =" [#55FF5C font-size: 10px] AFR Increased[/]";
      columnSeries.strokeWidth = 3;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "kiln_coal_increased";
      lineSeries.dataFields.categoryX = "obs_time";
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#D6E865");
      lineSeries.fill = am4core.color("#D6E865");
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "Kiln Coal Increased:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Kiln Coal Increased ";
      lineSeries.legendSettings.valueText = "[#D6E865 font-size:10px] Kiln Coal Increased[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#D6E865"); 
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 3;
      circle.strokeWidth = 3;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

    }, 100);
  }
  DrawAfrPccoalChart() {
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv1", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.graphValueafr;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;
      valueAxis.title.text = "AFR Increased";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "PC Coal Increased";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.valueY = "afr_increased";
      columnSeries.dataFields.categoryX = "obs_time";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      columnSeries.columns.template.tooltipText =
        "[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText = " AFR Increased:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#55FF5C");
      columnSeries.columns.template.fill = am4core.color("#55FF5C");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "AFR Increased";
      columnSeries.legendSettings.valueText =
        " [#55FF5C font-size: 10px] AFR Increased[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "pc_coal_increased";
      lineSeries.dataFields.categoryX = "obs_time";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#D6E865");
      lineSeries.fill = am4core.color("#D6E865");
      lineSeries.strokeWidth = 3;
      // lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText =
        " PC Coal Increased:{valueY.formatNumber('#.0')}";
      lineSeries.name = "PC Coal Increased ";
      lineSeries.legendSettings.valueText =
        "[#D6E865 font-size:10px] PC Coal Increased[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 3;

      circle.strokeWidth = 3;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  DrawAfrPaAirChart() {
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv1", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.graphValueafr;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;
      valueAxis.title.text = "AFR Increased";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "PA Air Increased";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.valueY = "afr_increased";
      columnSeries.dataFields.categoryX = "obs_time";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      columnSeries.columns.template.tooltipText =
        "[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText = " AFR Increased:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#55FF5C");
      columnSeries.columns.template.fill = am4core.color("#55FF5C");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "AFR Increased";
      columnSeries.legendSettings.valueText =
        " [#55FF5C font-size: 10px] AFR Increased[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "pa_air_increased";
      lineSeries.dataFields.categoryX = "obs_time";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#D6E865");
      lineSeries.fill = am4core.color("#D6E865");
      lineSeries.strokeWidth = 3;
      // lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = " PA Air Increased:{valueY.formatNumber('#.0')}";
      lineSeries.name = "PA Air Increased ";
      lineSeries.legendSettings.valueText =
        "[#D6E865 font-size:10px] PA Air Increased[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 3;

      circle.strokeWidth = 3;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }

  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    // console.log(this.startDate)
    this.getAFRSimTable();
    this.getAfrBAsedDD();
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;

        this.parsedObsTime = new Date(this.AFRdata[0].obs_time);
        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);
      }
    )

    this.ApicallService.getAFRNewTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRNewTabledata = data;

        this.parsedObsTime2 = new Date(this.AFRdata[0].obs_time);
        

        
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 0 && this.parsedObsTime2.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 23 && this.parsedObsTime2.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);

      }
    )

  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 600;
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) - 600;
    // console.log(this.startDate)
    this.getAFRSimTable();
    this.getAfrBAsedDD();
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;

        this.parsedObsTime = new Date(this.AFRdata[0].obs_time);
        
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 0 && this.parsedObsTime.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime && this.parsedObsTime.getHours() === 23 && this.parsedObsTime.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);
      }
    )

    this.ApicallService.getAFRNewTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRNewTabledata = data;

        this.parsedObsTime2 = new Date(this.AFRdata[0].obs_time);
        

        
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 0 && this.parsedObsTime2.getMinutes() === 0) {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.parsedObsTime2 && this.parsedObsTime2.getHours() === 23 && this.parsedObsTime2.getMinutes() === 50) {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        // (err);

      }
    )

  }

}
