<header class="asHeader">
  <!-- <nav> -->
    <span style="display: flex;flex-direction: row;justify-content: space-between;padding: 2vmin">
    <div style="background-color: white; border-radius: 50%;height: 6vmin;">
      <img src="assets\img\lns_logo.png" alt="" style="border-radius: 38%; height: 6vmin; " />
    </div>

    <span style="display: flex;flex-direction: row;justify-content: flex-start;">
      <h1 style="margin: 0px;font-size: 6vmin;">Methane - Analytics
       </h1>
    </span>

    <div class="headProfile">
      <img src="assets/img/headProfileIcon.svg" alt="" style="height: 5vmin;">
      <button style="border: unset;    background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));"
        class=" dropdown-toggle logoutdropdown" data-toggle="dropdown">
        <img src="assets\img\headProfileArrow.svg" alt="" style="height: 1vmin;">
      </button>
      <ul class="dropdown-menu">
        <li> <a class="dropdown-item" href="#" (click)="logout()"><i class="fas fa-sign-out-alt p-1"></i>Logout</a></li>
      </ul>
    </div>
  </span>
  <!-- </nav> -->
</header>