import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// import { saveAs } from 'file-saver';
// const baseUrl = environment['baseUrl'];
// const baseUrl2 = environment['baseUrl2'];
// const baseUrl3 =environment['baseUrl3'];
const baseUrl4 =environment['baseUrl4'];
const baseUrl2 =environment['baseUrl2'];
@Injectable({
  providedIn: 'root',
})
export class APiService {
  [x: string]: any;
  
  public potNo = new BehaviorSubject(1303);
  public boilerNo = new BehaviorSubject('Z701');
  getSelectedAlgoConfig: any;

  constructor(private http: HttpClient) {}
// Theme12

  getIntensityS1(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/1`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS2(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/2`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS3(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/3`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS4(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/4`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getGHGs1(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/1`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getGHGs2(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/2`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }

  getNonGHG(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/0`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getEWW() {
    return this.http.get(`${baseUrl2}brsr/eww/1?report_year=2022`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );

  }




// Theme13
getDayWisedata(starttime: any) {
  // emission_summary_data?start_time=1680460200
  return this.http.get(`${baseUrl2}emission_summary_data?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length == 0) {
          return [];
        } else { return response; }

      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

EmissionAccOG(starttime: any) {

  return this.http.get(`${baseUrl2}emission_accounting?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length == 0) {
          return [];
        } else { return response; }

      },
      (error) => {
        // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
Emissionreduction(starttime: any) {

  return this.http.get(`${baseUrl2}emission_reduction_og?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length == 0) {
          return [];
        } else { return response; }

      },
      (error) => {
        // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
  downloadExcelFile() {
    const filePath = 'assets/documentation/ESG_REPORT.xlsx'; // Replace with the actual file path in the assets folder

    this.http.get(filePath, { responseType: 'blob' }).subscribe((blob) => {
      const fileName = 'ESG_REPORT.xlsx'; // Replace with the desired file name

      // saveAs(blob, fileName);
    });
  }
// Theme 9 and 4 Apicalls (SandBox)
getAfrDropdown() {
  return this.http.get(`${baseUrl4}afr_simulator_liqafrqty`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}
getAfrBasedDropdown(sDate:any) {

  return this.http.get(`${baseUrl4}get_afr_simulator?epoch_time=${sDate}`,{headers:this.reqHeaders}).pipe(

    map(

      (response) => {

        return response;

      },

      (err) => {

         (err);

      }

    )

  );

}


getKilnDropdown(sDate:any) {
  return this.http.get(`${baseUrl4}get_kiln_simulator?epoch_time=${sDate}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}

getKilnSimulationValues(sDate:any,value:any, value1:any,value2:any,value3:any){
  return this.http.get(`${baseUrl4}kiln_simulator?epoch_time=${sDate}&main_coal=${value}&pc_coal=${value1}&pa_fan_speed=${value2}&kiln_speed=${value3} `,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}

getKilnsimulatorTable(sDate:any,) {
  return this.http.get(`${baseUrl4}kiln_simulator_table?epoch_time=${sDate}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}

getAFRSimulationValues(sDate:any,value:any, value1:any,value2:any,value3:any){

  return this.http.get(`${baseUrl4}afr_simulator?epoch_time=${sDate}&main_coal=${value}&pc_coal=${value1}&pa_fan_speed=${value2}&liq_afr_qty=${value3} `,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}

getAFRsimulatorTable(sDate:any,) {
  return this.http.get(`${baseUrl4}afr_simulator_table?epoch_time=${sDate}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
         (err);
      }
    )
  );
}

// DASHBOARD PAGE -- REALTIME PARAMETERS TABLE API INTEGRATION

getTableValues( starttime: any): Observable<any> {
  // return this.http.get(`${baseUrl2}filter_optimization_data?epoch_time=${starttime}`).pipe(getTableValues(starttime: any)
    return this.http.get(`${baseUrl4}real_time_filter_optimization_data?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
// DASHBOARD PAGE -- FORECAST TABLE API INTEGRATION
getForecastTableValues( starttime: any): Observable<any> {
  // return this.http.get(`${baseUrl2}forecast_data?date_time=${starttime}`).pipe(
    return this.http.get(`${baseUrl4}real_time_forecast?date_time=${starttime}`).pipe(

    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
getForecastAlertTableValues( starttime: any): Observable<any> {
  return this.http.get(`${baseUrl4}forecast_alert?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

getAFRTableValues(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl4}afr_table_data?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

getAFRNewTableValues(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl4}afr_new_table?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

getAFRTableValues1(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl4}daily_average_afr_per?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
getCoalTableValues(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl4}daily_average_coal_per?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}


// Theme 3 Api Calls
getCOtableValues1(starttime: any): Observable<any>{
  return this.http.get(`${baseUrl4}co_reduction_data?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

// Theme5apicalls
// uploadFilekpi(filetype:any): Observable<any>{
// return this.http.post<any>(`${baseUrl2}kpi/file_upload?type=${filetype}`,{}).pipe(map((response: any) => {
//   return response
// }, (error: any) => {
//    console.log(error);
// }
// ));
// }

 uploadFiles(_type:any, fd:any): Observable<any> {
  
   return this.http.post<any>(`${baseUrl4}kpi/file_upload?type=${_type}`,fd, {observe: "response"})
    .pipe(catchError(this.handleError))

  }




// Theme 6 Api calls
getAllUsers() {
  return this.http.get(`${baseUrl4}user`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevData() {
  return this.http.get(`${baseUrl4}temp_previously_shift_data`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevPDFData() {
  return this.http.get(`${baseUrl4}pdf_temp`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
// pdf_temp?date= this shall be used if pdftempdata is shift wise 
getpdftempdata(st_dt){
  return this.http.get(`${baseUrl4}day-wise_pdf/t?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
// pdf_co?date= this shall be used if pdfcodata is shift wise 
getpdfcodata(st_dt){
  return this.http.get(`${baseUrl4}day-wise_pdf/c?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getStatus() {
  return this.http.get(`${baseUrl4}status`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
getDaywiseSatus(st_dt){
  
  return this.http.get(`${baseUrl4}day-wise_status?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevPDFData1() {
  return this.http.get(`${baseUrl4}pdf_co`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}


getPrevData1() {
  return this.http.get(`${baseUrl4}co_previously_shift_data`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}



registerUser(body) {
  return this.http.post(`${baseUrl4}user`, body,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );

  
}

deleteDriver(identification) {
  return this.http.delete(`${baseUrl4}user?username=${identification}`, { headers: this.reqHeaders }).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

updateUserInfo(identification,body) {
  return this.http.put(`${baseUrl4}user?username=${identification}`,body,{headers:this.reqHeaders}).pipe(
    map(
   (response) => {
     return response;
   },
   (err) => {} ) );}

  //  https://api.dev.afr4.0.livnsense.com/clinker_quality?start_time=1680426900
  getClinkerValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}clinker_quality?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getClinkerRecommendedValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}real_time/parameter?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );

  }

  // Theme8

  getActualForecastKiln(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}forecast_actual?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getForecastKiln(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}kiln_forecasting?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
 


  // Theme9
  getActualForecastKilnRecmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}recommendation_actual?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getForecastKilnRecmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}kiln_recommendation?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }



  //Theme 1
  AfrDashboardoptimisation(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}afr_optimization/2?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  KilnDashboardrcmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}kiln_recommendation_data/2?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  //Theme 10

SopAlerts(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}alerts?alert_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  SopTable(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl4}kiln_constrains?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

// method to get report table and graph values

// getTableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}realtime_opt_graph?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }


// getfc2TableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}actual_forecast_graph_02?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }
// getfc1TableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}actual_forecast_graph_01?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }

// method to get report dosing table values 

// getDosingTableData(filters:any): Observable<any> {
//   const sDate = new Date(filters['start_date']).toISOString();
//   const eDate = new Date(filters['end_date']).toISOString();
//   return this.htttp.get(`${baseUrl2}dashboard/dosing_table?start_date=${sDate}&end_date=${eDate}`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }



// getROITableValues(boilerNo): Observable<any> {
//   return this.htttp.get(`${baseUrl2}dashboard/roi_data?boiler=${boilerNo}`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }

// uploadDosing(body:any): Observable<any> {
//   return this.htttp.post(`${baseUrl2}dashboard/dosing_data`,body).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }
  
}


