import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { ExcelsheetComponent } from 'src/app/components/excelsheet/excelsheet.component';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/utils.service';
import { AuthenticationService } from './../../core/authenticationn.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  lnsURL=environment.baseUrl2;
  public isValid: string;
  imageToggle = 1;
  imageFix='100%';
  selectedDatenext: any;
  isDefinedFilterSelected: boolean = false;
  selectedDefaultDate: string;
  // maxDate: any = new Date();
  isDatePickerOpened: boolean = false;
  dateRangaPicker: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now());
  minDate:  Date = new Date('06/01/2023');
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;
  Valuesdaywise: any;
  // appliedDateFilters: any = {};
  // maxDate: Date = new Date(Date.now() - 86400000);
  // minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  SelectTheme = [
    // 'Dashboard',
    // 'Kiln 4.0',
    // 'AFR 4.0',
    // 'Clinker Quality',
    // 'KPI Validation',
    // 'SOP & Alerts',
    // 'User Management',
    // 'CO Savings',
    // 'File Upload',
    'GreenOps - Data Config.',
    'Emission A/c - Cement',
    'Emission A/c - O&G',
    'Emission Analytics - Cement',
    'Emission Analytics - O&G',
    'Energy, Water & Wastage',
    'Validation - Workflow',
    'Reports Extraction',
  
  ];

  SelectedTheme = 'GreenOps - Data Config.';
  dataToggle: number =1;
  kilnToggle:number=1;
  ForecastKilndata: any;
  // startdatef: any;
  // colorMix1: string;

  constructor(private authenticationService: AuthenticationService, 
    private ApicallService: APiService , public dialog: MatDialog, 
        readonly utils: UtilsService,) {
  }

  ngOnInit(): void {
    // this.dataToggle = 1;
    this.colorMix1 = 'G'
    this.isValid = localStorage.getItem("backToken");
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
      
  }
  downloadExcel() {
    this.ApicallService.downloadExcelFile();
  }


  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getClinker();
  }
  
  getClinker() {
    let startDate =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime()/1000
    ;
    console.log( startDate, "apidate1")
    this.ApicallService.getClinkerValues(startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;
        
        console.log(this.ForecastKilndata,startDate ,"apidate2")
      },
      (err) => {
        (err);

      }
    )
  }


  // radio buttom theme event
  onItemChange(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    // this.imageToggle = 2;

    // setTimeout(()=>{                           
    //        this.imageToggle = 1;
    //     }, 5000);
   
  }
  onItemChangecontent(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.dataToggle = 2;
   
  }
  onItemChangeconten1t(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.dataToggle = 1;
   
  }
  // kilnToggle
  onItemChangecontentkiln(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.kilnToggle = 2;
   
  }
  onItemChangeconten1tkiln(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.kilnToggle = 1;
   
  }
  onItemChangeconten1tkilnsim(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.kilnToggle = 3;
   
  }
  onItemChangeconten1tkilnsimback(event) {
    this.kilnToggle = 2;
  }
  onItemChangecontentsim(event) {
    this.dataToggle = 3;
  }
  onItemChangecontentsimback(event) {
    this.dataToggle = 2;
  }
  toggleImage(value:any){
    if(value == 1){
      this.imageToggle = 1;
    }
    else{
      this.imageToggle = 2;
    }
  }
  
  cleanUp(){
    localStorage.removeItem('boilerUser');
    localStorage.removeItem('backToken');
  }

 
  logout() {
    if(this.isValid){
      // // console.log("isBackToken",this.isValid);
      localStorage.removeItem('backToken');
      localStorage.removeItem("boilerUser")
      window.location.href=environment.baseUrl2;
    }
    else{
    this.authenticationService.logout();
  }
  }
 

}
