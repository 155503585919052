<section>
  <div class="mainGridRgt11"
    style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
    <div class="asImgBoxTop pt-2">
      <div class="asImgBoxTopTxt">Kiln & AFR Savings Summary</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
          <input style="padding-top: 0.9vmin;
          color: white;
          font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate ></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
         font-size: 2.3vmin;
    width: 11vmin;
    cursor: pointer;
    background-color: #302c2c;
    outline: unset;
    /* margin-left: 4%; */
    /* padding-top: 2px; */
    border: 1px solid rgba(255, 255, 255, 0.38);
    height: 5vmin;border-radius: 0.9375em !important;display: flex;justify-content: center;align-items: center;
    " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
        Apply
      </button>
    </div> 
  </div>

  <div *ngIf="!isLoading" style=" font-size: 13px;flex-direction: column;justify-content: space-between;
  display: flex;
">
  <div>
    <div style="display: flex;flex-direction: row; justify-content:start">
      <p class="mainGridtitle1" style="     color: #fff;
      text-align: start;
      margin: 0px;
      padding: 5px;
      font-size: 2.5vmin;">Daywise Savings</p>
      <!-- <span style="display: flex;flex-direction: row; justify-content:flex-start">
        <p class="mainGridtitle1" style=" color: #fff;">Date:</p>
        <span style=" color: #fff;">
          <tr *ngFor="let vval of KilnrcmdData  ">
            <td>{{vval.obs_time | date:'MM/dd/yyyy'}}                 
                  </td>
            <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm' }}
            </td>
          </tr>
        </span>
      </span> -->
  </div>
  </div>
  <span style="display: flex;flex-direction: column;justify-content: space-between;">
    <div class="red1" style="margin: 0px;">
      <div class="row" >
        <div class="col-md-12">
          <p class="mainGridtitle1" style="font-size: 2.5vmin;font-weight: 600;margin: 0px;">Kiln Optimization</p>
          <table class="tableStyle1" *ngIf="KilnrcmdData.length != 0">
            <tr>
              <th class="table-head" colspan="1">Parameter</th>
              <th class="table-head" colspan="1">Actual</th>
              <th class="table-head" colspan="1">Optimized</th>
              <th class="table-head" colspan="1">Savings (%)</th>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th class="table-head1">Main Coal (Tons)</th>
              <td class="table-data" >{{vval.main_coal_actual}}</td>
              <td class="table-data">{{vval.main_coal_optimized}}</td>
              <td class="table-data">{{vval.main_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th class="table-head1">PC Coal (Tons)</th>
              <td class="table-data" >{{vval.pc_coal_actual}}</td>
              <td class="table-data">{{vval.pc_coal_optimized}}</td>
              <td class="table-data">{{vval.pc_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th class="table-head1">Kiln Speed (RPM)</th>
              <td class="table-data">{{vval.kiln_actual}}</td>
              <td class="table-data">{{vval.kiln_optimized}}</td>
              <td class="table-data">{{vval.kiln_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th class="table-head1">PA Fan Speed (RPM)</th>
              <td class="table-data">{{vval.pa_fan_act}}</td>
              <td class="table-data">{{vval.pa_fan_optimized}}</td>
              <td class="table-data">{{vval.pa_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th class="table-head1">Carbon Reduction (Tons)</th>
              <td class="table-data">{{vval.co2_actual}}</td>
              <td class="table-data">{{vval.co2_optimized}}</td>
              <td class="table-data">{{vval.co2_savings}}</td>
            </tr>
          </table>

          <table class="tableStyle1" *ngIf="KilnrcmdData.length == 0">
            <tr>
              <th class="table-head" colspan="1">Parameter</th>
              <th class="table-head" colspan="1">Actual</th>
              <th class="table-head" colspan="1">Optimized</th>
              <th class="table-head" colspan="1">Savings (%)</th>
            </tr>
            <tr>
              <th class="table-head1">Main Coal(Tons)</th>
              <td class="table-data" colspan="4">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">PC Coal (Tons)</th>
              <td class="table-data" colspan="4">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">Kiln Speed(RPM)</th>
              <td class="table-data" colspan="4">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">PA Fan Speed (RPM)</th>
              <td class="table-data" colspan="4">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">Carbon Reduction (Tons)</th>
              <td class="table-data" colspan="4">No Data</td>
            </tr>
          </table>
        </div>
      </div>
      </div>
  
      
    <div class="red1" style="margin: 0px;margin-top: 5px;">
      <div class="row" >
        <div class="col-md-12">
          <p class="mainGridtitle1" style="font-size: 2.5vmin;font-weight: 600;margin: 0px;">AFR Optimization</p>
          <table class="tableStyle1" *ngIf="AfrOptData.length != 0">
            <tr>
              <th colspan="1"class="table-head">Parameter</th>
              <th colspan="1"class="table-head">Actual</th>
              <th colspan="1"class="table-head">Optimized</th>
              <th colspan="1"class="table-head">Savings (%)</th>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th class="table-head1">Main Coal (Tons)</th>
              <td class="table-data">{{vval.main_coal_actual}}</td>
              <td class="table-data">{{vval.main_coal_optimized}}</td>
              <td class="table-data">{{vval.main_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th class="table-head1">PC Coal (Tons)</th>
              <td class="table-data">{{vval.pc_coal_actual}}</td>
              <td class="table-data">{{vval.pc_coal_optimized}}</td>
              <td class="table-data">{{vval.pc_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th class="table-head1">PA Fan Speed (RPM)</th>
              <td class="table-data">{{vval.pa_actual}}</td>
              <td class="table-data">{{vval.pa_opt}}</td>
              <td class="table-data">{{vval.pa_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th class="table-head1">AFR (M<sup>3</sup>)</th>
              <td class="table-data">{{vval.afr_actual}}</td>
              <td class="table-data">{{vval.afr_recom}}</td>
              <td class="table-data">{{vval.afr_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th class="table-head1">Carbon Reduction (Tons)</th>
              <td class="table-data">{{vval.co2_actual}}</td>
              <td class="table-data">{{vval.co2_optimized}}</td>
              <td class="table-data">{{vval.co2_savings}}</td>
            </tr>
          </table>

          <table class="tableStyle1" *ngIf="AfrOptData.length == 0">
            <tr>
              <th colspan="1"class="table-head">Parameter</th>
              <th colspan="1"class="table-head">Actual</th>
              <th colspan="1"class="table-head">Optimized</th>
              <th colspan="1"class="table-head">Savings (%)</th>
            </tr>
            <tr>
              <th class="table-head1">Main Coal (Tons)</th>
              <td colspan="3">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">PC Coal (Tons)</th>
              <td colspan="3">No Data</td>
            </tr>
            <tr>
              <tr>
                <th class="table-head1">PA Fan Speed (RPM)</th>
                <td colspan="3">No Data</td>
              </tr>
              <tr>
              <th class="table-head1">AFR (M<sup>3</sup>)</th>
              <td colspan="3">No Data</td>
            </tr>
            <tr>
              <th class="table-head1">Carbon Reduction (Tons)</th>
              <td colspan="3">No Data</td>
            </tr>
          </table>
        </div>
      </div>
      </div>
    </span>
  </div>

  <div *ngIf="isLoading" style=" display: grid;height: 68vh; width: 100%">
    <div style="margin: auto">
      <img
        src="../../../../assets/icons/Spinner.gif"
        style="width: 10vmin"
      />
      <p style="text-align: center;color: #fff;font-size: 3vmin;">Loading...</p>
    </div>
  </div>
</section>