<div class="container-fluid">
    <div class="row">
      <div class="col-md-8">
        <div class="info">
          <div class="p-5">
            <a target="_blank" href="https://livnsense.com/" title="LivNSense Technologies Pvt. Ltd">
              <img class="logo img-fluid" src="assets\img\lns_logo.png" alt="livnsense logo" />
            </a>
          </div>
  
          <div class="py-3 mx-auto px-2 w-75 text-light bg-white text-center" style="opacity: 0.6; height: 6rem; background-color: cornsilk;">
            <h1 class="text-maroon h1 font-weight-bold" style="padding-top: 10px;">Methane 4.0 </h1>
            <!-- <p class="text-maroon font-weight-bold h5">Digital Production Optimization - Downstream</p> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 bg-white">
        <div class="text-center">
          <!-- <img class="balcoLogo img-fluid" src="assets\img\balco-logo.png" alt="balco-logo" /> -->
        </div>
        <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
          <h1 class="h3 my-3 font-weight-normal">Sign In To Your Account</h1>
          <label for="inputEmail" class="pt-2"></label>
          <input type="text" formControlName="email_id" class="form-control pt-2 mb-4" placeholder="Username/ Email ID" autofocus="">
          <label for="inputPassword" class="mt-4">Password</label>
          <span style="display: flex;flex-direction: row;justify-content: flex-start;">
            <input type="password" [type]="hide ? 'password' : 'text'" placeholder="Password" name="password" formControlName="password" class="form-control pt-2" placeholder=""           >      <i class="fa fa-eye" aria-hidden="true" style="padding-top: 10px;" [ngClass]="{'fa-eye-slash': hide,'fa-eye': !hide}" (click)="togglePwd()"></i>
          </span>
          <!-- <input type="password" formControlName="password" id="inputPassword" class="form-control pt-2" placeholder="Password"> -->
          <p style="padding-top: 5px">Forget Password?</p>
          <div class="text-center" style="padding-top: 5px;">
            <button class="btn btn-lg btn-danger btn-block mt-2 text-white" type="submit">Sign in</button>
          </div>
          <p class="h4 text-center pt-4" style="padding-top:5rem">Powered by
            <a target="_blank" href="https://livnsense.com/" title="LivNSense Technologies Pvt. Ltd">
              <img  class="img-fluid m-2" style="width: 15rem; height: auto;padding-right: 2rem;" alt="LivNSense logo" src="assets\img\greenOps.png"/>
            </a> 
            <!-- <img src="assets\img\greenOps.png" width="100px" alt=""> -->
            <!-- <a target="_blank" href="https://livnsense.com/solutions/" title="LivNSense Technologies Pvt. Ltd">
            <img class="img-fluid" style="width: 7rem; height: auto; padding-right: 2rem;" src="assets\img\isense4i.png" alt="isense4i logo" />
            </a>
            <img class="img-fluid" style="width: 7rem; height: auto; padding-right: 2rem;" src="assets\img\sap-logo.png" alt="SAP logo" /> -->
          </p>
        </form>
      </div>
    </div>
  </div>