<section>
  <div
    class="mainGridRgt11"
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      border-bottom: 2px solid;
    "
  >
    <div class="asImgBoxTop pt-2">
      <div class="asImgBoxTopTxt">Kiln-Critical Parameters</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
          <input style="padding-top: 0.9vmin;
          color: white;
          font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate ></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
         font-size: 2.3vmin;
    width: 11vmin;
    cursor: pointer;
    background-color: #302c2c;
    outline: unset;
    /* margin-left: 4%; */
    /* padding-top: 2px; */
    border: 1px solid rgba(255, 255, 255, 0.38);
    height: 5vmin;border-radius: 0.9375em !important;display: flex;justify-content: center;align-items: center;
    " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
        Apply
      </button>
    </div> 
  </div>
  <div *ngIf="!isLoading" 
    style="
      font-size: 13px;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
        /* height: 425px; */
 
    /* overflow:scroll; */

    "
  >
    <div style="display: flex; flex-direction: row; justify-content: flex-end">
      <p
        style="
          margin: 0px;
          margin-left: 10px;
          font-weight: 400;
          font-size: 2vmin;
          color: #fff;
        "
      >
        Alert Level:
      </p>
      &nbsp;&nbsp;
      <button
        style="
        height: 1.7vmin;
          width: 3vmin;
          left: 66px;
          top: 860px;
          margin-top: 0.8vmin;
          border-radius: 10px;
          border-width: 0px;
          /* background: #0eab73; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'G' ? '#39FF14' : '#0a3207'
        }"
      ></button
      >&nbsp;&nbsp;
      <button
        style="
        height: 1.7vmin;
          width: 3vmin;
          left: 66px;
          top: 860px;
          margin-top: 0.8vmin;
          border-radius: 10px;
          border-width: 0px;
          /* background: #e5b027; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'Y' ? '#e5b027' : '#332f00'
        }"
      ></button
      >&nbsp;&nbsp;
      <button
        style="
        height: 1.7vmin;
          width: 3vmin;
          left: 66px;
          top: 860px;
          margin-top: 0.8vmin;
          border-radius: 10px;
          border-width: 0px;
          /* background: #c7152a; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'R' ? '#c7152a' : '#300'
        }"
      ></button
      >&nbsp;&nbsp;
    </div>
    <div class="red1">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          /* padding: 1%; */
        "
      >
        <p class="mainGridtitle1" style="font-size: 2.6vmin;">Real Time Critical Parameters</p>
        <span style="color: #fff;font-size: 2vmin;" >
          <tr *ngFor="let vval of ActualForecastKilndata">
            <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
          </tr>
        </span>
      </div>
      <div class="row" style="padding: 1%">
        <div class="col-md-6">
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "  
          >   
           <label for="temp">Free Lime (%)</label>
          <span *ngIf="ActualForecastKilndata.length != 0">
         
            <div *ngFor="let vval of ActualForecastKilndata">
              <label class="per" *ngIf="vval.f_cao < 0">0.00</label>
              <label class="per" *ngIf="vval.f_cao >= 0">
                {{ vval.f_cao }}
              </label>
            </div>
          </span>
           
            <div *ngIf="ActualForecastKilndata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Burning Zone Temp (°C)</label>
            <span  *ngIf="ActualForecastKilndata.length != 0" >
            <div  *ngFor="let vval of ActualForecastKilndata">
              <label class="per" *ngIf="vval.burning_zone_temp < 0">0.00</label>
              <label class="per" *ngIf="vval.burning_zone_temp >= 0">
                {{ vval.burning_zone_temp }}
              </label>
            </div>
          </span>
            <div *ngIf="ActualForecastKilndata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1%">
        <div class="col-md-6" >
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp"
              >Kiln Inlet O2 (%)
            </label>
            <span *ngIf="ActualForecastKilndata.length != 0">
            <div *ngFor="let vval of ActualForecastKilndata">
              <label class="per" *ngIf="vval.kiln_inlet_o2 < 0">0.00</label>
              <label class="per" *ngIf="vval.kiln_inlet_o2 >= 0">
                {{ vval.kiln_inlet_o2 }}
              </label>
            </div>
          </span>
            <div *ngIf="ActualForecastKilndata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>

        <div class="col-md-6" >
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Kiln Inlet CO (%)</label>
            <span *ngIf="ActualForecastKilndata.length != 0">
            <div *ngFor="let vval of ActualForecastKilndata">
              <label class="per" *ngIf="vval.kiln_inlet_co < 0">0.00</label>
              <label class="per" *ngIf="vval.kiln_inlet_co >= 0">
                {{ vval.kiln_inlet_co }}
              </label>
            </div>
          </span>
            <div *ngIf="ActualForecastKilndata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="red1">
      <div class="row" style="padding-left: 1%; padding-right: 1%">
        <div class="col-md-12">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              /* padding: 1%; */
            "
          >
            <p class="mainGridtitle1">Forecasting Values</p>
            <span style="color: #fff;font-size: 2vmin;">
              <tr *ngFor="let vval of ForecastKilndata">
                <td>Time -&nbsp;{{ vval.obs_time }}</td>
              </tr>
            </span>
          </div>

          <div class="row" style="padding: 1%">
            <div class="col-md-6" >
              <div
                class="red2"
                style="
                  border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;
                "
              >
                <label for="temp">Free Lime (%)</label>
                <span  *ngIf="ForecastKilndata.length != 0">
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.free_lime < 0">0.00</label>
                  <label class="per" *ngIf="vval.free_lime >= 0">
                    {{ vval.free_lime }}
                  </label>
                </div>
                <span
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                "
                (click)="openDialogFreelimekiln()"
              >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
font-size:1.5vmin;
                    cursor: pointer;
                  "
                >
                  View Chart
                </p>
              </span>
              </span>
                <div *ngIf="ForecastKilndata.length == 0 || null">
                  <label class="per">No data</label>
                </div>
              
              </div>
            </div>
            <div class="col-md-6">

              <div
                class="red2"
                style="
                  border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;
                "
              >
                <label for="temp">Burning Zone Temp (°C)</label>
                <span *ngIf="ForecastKilndata.length != 0">
                <div   *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.burning_zone_temp < 0"
                    >0.00</label
                  >
                  <label class="per" *ngIf="vval.burning_zone_temp >= 0">
                    {{ vval.burning_zone_temp }}
                  </label>
                </div>
                <span
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                "
                (click)="openDialogBurning()"
              >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
font-size:1.5vmin;
                    cursor: pointer;
                  "
                >
                  View Chart
                </p>
              </span>
              </span>
                <div *ngIf="ForecastKilndata.length == 0 || null">
                  <label class="per">No data</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="padding: 1%">
            <div class="col-md-6" >
              <div
                class="red2"
                style="
                  border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;
                "
              >
                <label for="temp"
                  >Kiln Inlet O2 (%)
                </label>
                <span *ngIf="ForecastKilndata.length != 0">
                <div  *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.kiln_inlet_o2 < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_inlet_o2 >= 0">
                    {{ vval.kiln_inlet_o2 }}
                  </label>
                </div>
                <span
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                "
                (click)="openDialogkilninleto2()"
              >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
font-size:1.5vmin;
                    cursor: pointer;
                  "
                >
                  View Chart
                </p>
              </span></span>
                <div *ngIf="ForecastKilndata.length == 0 || null">
                  <label class="per">No data</label>
                </div>
              
              </div>
            </div>

            <div class="col-md-6" >
              <div
                class="red2"
                style="
                  border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;
                "
              >
                <label for="temp">Kiln Inlet CO (%)</label>
                <span *ngIf="ForecastKilndata.length != 0" >
                <div *ngFor="let vval of ForecastKilndata">
                  <label class="per" *ngIf="vval.kiln_inlet_co < 0">0.00</label>
                  <label class="per" *ngIf="vval.kiln_inlet_co >= 0">
                    {{ vval.kiln_inlet_co }}
                  </label>
                </div>
                <span
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                "
                (click)="openDialogkilninletco()"
              >
                <img
                  src="../../../../assets/icons/charticon.png"
                 style="width: 1.8vmin; height: 1.8vmin; margin-top: 0.2vmin"
                />
                <p
                  style="
                    margin-bottom: 0px;
                    margin-left: 5px;
                    text-decoration: underline;
font-size:1.5vmin;
                    cursor: pointer;
                  "
                >
                  View Chart
                </p>
              </span>
            </span>
                <div *ngIf="ForecastKilndata.length == 0 || null">
                  <label class="per">No data</label>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* padding: 1%;
        margin-top: 1%; */
        margin-bottom: 0.5vmin;
      "
    >
      <div>
        <span
          ><button style="
          font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
          cursor: pointer;
          background-color: #302c2c;
          outline: unset;
          border: 1px solid rgba(255, 255, 255, 0.38);
          align-items: center;
          justify-content: center;
          display: flex;
        " *ngIf="!disablePrevButton" mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onPrevClick()">
            Previous
          </button></span
        >
      </div>
      <button style="
     font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      border: 1px solid rgba(255, 255, 255, 0.38);
      align-items: center;
      justify-content: center;
      display: flex;
    " *ngIf="!disableNextButton" mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onNextclick()">
        Next
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img
        src="../../../../assets/icons/Spinner.gif"
        style="width: 10vmin"
      />
      <p style="text-align: center;color: #fff;font-size:3vmin">Loading...</p>
    </div>
  </div>
</section>
