import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APiService } from 'src/app/core/balco.service';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
@Component({
  selector: 'app-theme13',
  templateUrl: './theme13.component.html',
  styleUrls: ['./theme13.component.scss']
})
export class Theme13Component implements OnInit {
  Valuesdaywise: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now() - 86400000);
  minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;




  today: number = Date.now();
  kips: any;
  name: string = '';
  file: any;
  selectedFile = null;
  selectedFileType = 'kiln_quality';
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  fileName = '';
  response: string;
  apiresp: any;
  // fileType:any;

  // simpleForm: FormGroup;
  submitted = true;
  alert: boolean
  files: any;
  error: String
  data: String
  res: string;
  nghg: any;
  s3Inten: any;
  selectedDatenext: any;
  constructor(private plotChartsService: PlotChartsService, private ApicallService: APiService, public dialog: MatDialog,) {
  }


  ngOnInit(): void {
    this.selectedDatenext= '1680373800';
    let dte = new Date(Date.now() - 86400000);
    this.appliedDateFilters['start_date'] = dte
    this.getDaywisedata();
    this.getCoGraph();
    this.getCoGraph1();
    setTimeout(() => {
      this.drawCOChart();
      this.drawCOChart1();
    }, 2000);
  
   
  }


  getDaywisedata() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getDayWisedata(this.startdatef).subscribe(
      (data: any) => {
        this.Valuesdaywise = data;
        console.log(this.Valuesdaywise, "data")
      },
      (err) => {
        // (err);

      }
    )

  }


  getCoGraph() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    let ref = this;
    ref.plotChartsService.getCOdata1(this.startdatef).subscribe((res) => {
      ref.graphValueafr = res;
      // ref.graphValueafr.map((cvalue: any) => {
      //   let dt = cvalue.obs_time;
      //   let forcast = cvalue.coal_before;
      //   let temp = cvalue.coal_after;
      // })
      console.log("jhbh", ref.graphValueafr)
      // ref.drawCOChart();
      // ref.drawCOChart1();
      // ref.drawFC2Chart();

    });
  }
  getCoGraph1() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    let ref = this;
    ref.plotChartsService.getCOdata2(this.startdatef).subscribe((res) => {
      ref.graphValueafr1 = res;
      // ref.graphValueafr1.map((cvalue: any) => {
      //   let dt = cvalue.obs_time;
      //   let forcast = cvalue.co_before;
      //   let temp = cvalue.co_after;
      // })
      console.log("jhb1h", ref.graphValueafr1)
      // ref.drawCOChart();
      // ref.drawCOChart1();
      // ref.drawFC2Chart();

    });
  }



  drawCOChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("divCOChart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graphValueafr1;
      console.log("  chart.data",   chart.data)



      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 250;
      valueAxis.title.text = "Kg";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');
      valueAxis.renderer.minGridDistance = 20;
      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      series.dataFields.valueY = "ch4_kg";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "CH4 Before GreenOps" + `:{${"ch4_kg"}}`;
      // series.strokeWidth = 2;
      series.stroke = am4core.color("#FFD1A2");
      series.fill = am4core.color("#FFD1A2");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.labelText = "[#FFD1A2 font-size:10px ] CH4 Before GreenOps [bold][/]";
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "CO2_after_greenops";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "CH4 Saved" + `:{${"CO2_after_greenops"}}`;
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#00FFC7");
      series1.fill = am4core.color("#00FFC7");
      series1.legendSettings.labelText = "[#00FFC7 font-size:10px ]CH4 Saved [bold][/]";

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "ch4_reduced_kg";
      series2.dataFields.categoryX = "obs_time";
      series2.tooltipText = "CH4 After GreenOps" + `:{${"ch4_reduced_kg"}}`;
      series2.strokeWidth = 2;
      series2.stroke = am4core.color("#b3ff66");
      series2.fill = am4core.color("#b3ff66");
      series2.legendSettings.labelText = "[#b3ff66 font-size:10px ]CH4 After GreenOps [bold][/]";

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 2;

      let bullet = series2.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#3BFF00");
      let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet1.circle.radius = 2;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color("#fff");

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      //Add scrollbar
      // chart.scrollbarX = new am4core.Scrollbar();
      // chart.scrollbarX.parent = chart.topAxesContainer
      // chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.minHeight = 7;
      // // Applied on hover
      // chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // // Applied on mouse down
      // chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }
  drawCOChart1() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("COChart1", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graphValueafr;
      console.log("  chart.da1ta",   chart.data)


      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Kg";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      series.dataFields.valueY = "CO_kg";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "CO before GreenOps" + `:{${"CO_kg"}}`;
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#9AAFFF");
      series.fill = am4core.color("#9AAFFF");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.labelText = "[#9AAFFF font-size:10px]CO before GreenOps [bold][/]";

      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "CO_reduced_kg";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "CO Saved" + `:{${"CO_reduced_kg"}}`;
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#00FFC7");
      series1.fill = am4core.color("#00FFC7");
      series1.legendSettings.labelText = "[#00FFC7 font-size:10px]CO Saved [bold {}][/]";

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "CO_after_greenops";
      series2.dataFields.categoryX = "obs_time";
      series2.tooltipText = "CO After GreenOps" + `:{${"CO_after_greenops"}}`;
      series2.strokeWidth = 2;
      series2.stroke = am4core.color("#b3ff66");
      series2.fill = am4core.color("#b3ff66");
      series2.legendSettings.labelText = "[#b3ff66 font-size:10px]CO After GreenOps [bold][/]";

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color("#fff");

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 2;

      let bullet = series2.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#3BFF00");
      let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet1.circle.radius = 2;

chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      //Add scrollbar
      // chart.scrollbarX = new am4core.Scrollbar();
      // chart.scrollbarX.parent = chart.topAxesContainer
      // chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.minHeight = 7;
      // // Applied on hover
      // chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // // Applied on mouse down
      // chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }
}
