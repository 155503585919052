import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
// import { setInterval } from 'timers';
import { UtilsService } from 'src/app/core/utils.service';
@Component({
  selector: 'app-theme2',
  templateUrl: './theme2.component.html',
  styleUrls: ['./theme2.component.scss'],
})
export class Theme2Component implements OnInit {
  [x: string]: any;
  isLoading: boolean = true;

  Realtimedata: any = [];
  Forecastdata: any = [];
  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  status1: any;
  valuestatus: any;
  checkstatus: any;
  coloval: any;
  Realtimedatadt: any;
  Forecastalertdatadt: any;
  Forecastdatatemp: any;
  ForecastdatatempAvg: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');



  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
    readonly utils: UtilsService,
  ) {
  }

  openDialog() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log( this.startDate, "forecast")
    this.ApicallService.getForecastAlertTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '700px',
          height: '280px',
          data: { TableData: this.Forecastalertdata, clicked: 0 },
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      (err) => {
        // (err);

      }
    )
  }
  openDialog1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getForecastAlertTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '700px',
          height: '280px',
          data: { TableData1: this.Forecastalertdata, clicked: 1 },
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      (err) => {
        // (err);

      }
    )
  }

  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getTable();
    this.getforecastAlertTable();
    this. getAFRTable1();
    this.getCOALTable();
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
      this.getTable();
      this.getforecastTable();
    this.selectedForecastDatenext = parseInt(this.selectedDatenext) + 300;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

  }

  getTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getTableValues( this.startDate ).subscribe(
      (data: any) => {
        this.Realtimedata = data;
        this.Realtimedatadt = this.Realtimedata[0].dateandtime
      },
      (err) => {
        // (err);

      }
    )
  }

  getStatusApi() {
    this.ApicallService.getStatus().subscribe(
      (data: any) => {
        this.status1 = data;
        this.valuestatus = this.status1[0].plant_status
        // console.log(this.valuestatus)
        if (this.valuestatus == 'In Operation') {
          this.checkstatus = 'In Operation'
          this.coloval = '#39FF14'
          // this.checkstatus = 'Shutdown'
          // this.coloval = '#FD1C03'
          // } else if (this.valuestatus == 'Complained') {
          //   this.checkstatus = 'In Operation'
          //   this.coloval = '#39FF14'
        } else if (this.valuestatus == 'Unstable') {
          this.checkstatus = 'Unstable'
          this.coloval = 'orange'
        }
      },
      (err) => {
        // (err);

      }
    )
  }

  getforecastTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastdata = data;
        this.Forecastdatatemp = this.Forecastdata[0].forecast_cyc_6_outlet_co;
        this.ForecastdatatempAvg = this.Forecastdata[0].forecast_cyc_6_avg_temp;
        this.Forecastalertdatadt = this.Forecastalertdata[0].dateandtime;
      },
      (err) => {
        // (err);

      }
    )
  }
  getforecastAlertTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastwda")
    this.ApicallService.getForecastAlertTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;

      },
      (err) => {
        // (err);

      }
    )
  }

  getAFRTable1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues1(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getCoalTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Coaldata = data;
      },
      (err) => {
        // (err);

      }
    )

  }
  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    );
    this.ApicallService.getTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
  }
  onPreviousclick() {
    console.log(this.startDate, "--5mns")
    this.startDate = parseInt(this.startDate) - 600;
    this.selectedDatenext = parseInt(this.selectedDatenext) - 600;
    console.log(this.startDate)
    this.ApicallService.getTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
  
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
  }
}
