import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authenticationn.service';



@Component({
  selector: 'app-marketplacelogin',
  templateUrl: './marketplacelogin.component.html',
  styleUrls: ['./marketplacelogin.component.scss']
})
export class MarketplaceloginComponent implements OnInit {
    lnskey: any;
    [x: string]: any;
    loginForm: FormGroup;
    public receivedToken:any;
    
    currentUser = {};
    errMsg: string;
    forgot : FormGroup;
    hide = true;
    message: string;
    isSubmitted: boolean;
    validurl: boolean = false;
    decodeToken: any;
    passwordType: string='password';
    passwordShown: boolean = false;
    getToken:any;
    public flag: any;
    public apicallstatus: any = false;
    isLoading: boolean = false;
    
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {

    }


    ngOnInit() {   
      // this.isValid = localStorage.getItem("backToken");
      localStorage.setItem("backToken","1"); 
      localStorage.removeItem('boilerUser');
      // this.href = this.router.url;
      // this.receivedToken=this.href.split('%20').pop()
      this.route.params.subscribe(params => {
      this.receivedToken = params['id'];         
      // console.log("LNSKEY ID INIT",this.receivedToken);
       });
      // console.log('print',this.receivedToken);
      // console.log(this.router.url);
      this.ValidateURL();

      
      // this. getTokenData();
  
        this.loginForm = this.formBuilder.group({
          email_id: ["", Validators.required],
            password: ["", Validators.required],
        });
        this.forgot = this.formBuilder.group({
          email_id: ['', Validators.required]
          });

        const marginTops: any = document.querySelector(".footer").clientHeight;
        (document.querySelector(".content") as HTMLElement).style.marginBottom =
            "-" + marginTops + "px";

        const headerhegiht: any =
            document.querySelector(".header").clientHeight;
        const loginheight: any =
            window.innerHeight - marginTops - 60 - headerhegiht;

        (
            document.querySelector(".login-row-height") as HTMLElement
        ).style.minHeight = loginheight + "px";

        const dom: any = document.querySelector("body");
        dom.classList.remove("sidemenu-open");
        
        setInterval(() => this.login(), 5000);
    }

   /* Method to login */
   login() {
    this.ValidateURL();
  //  if (this.loginForm.valid) {
    if (this.validurl) {
      this.isLoading = true;
      this.loginForm['value']['email_id'] = 'admin';
      this.loginForm['value']['password'] = 'lns@123';
      this.apiCallsService.login(this.loginForm['value']).subscribe(data => {
        localStorage.setItem('user', JSON.stringify(data));
        this.isLoading = false;
        this.router.navigate(['unit']);
      }, err => {
        this.isLoading = false;
        this.authFailed = err['error']['message'];
        // console.log(this.authFailed);
        this.loginForm.controls['email_id'].setErrors({ incorrect: true });
        this.loginForm.controls['password'].setErrors({ incorrect: true });
      })
    }
    else
      return;
  }
  
 
  getUrl() {
    this.url = this.router.url;
    this.oUrl = this.url.split('/')[1];
  }

  public  ValidateURL(){
      // console.log('receivedToken',this.receivedToken);
      // console.log('getToken',this.getToken);
    if(this.receivedToken==this.getToken){
      // console.log('pass')
      this.flag=1;
    }else{
      // console.log('failed')
      this.flag=0;
    }
  
  }
async getTokenFromDb(){
  this.isLoading = true;
  var response = await this.authenticationService.marketLogin(this.receivedToken).toPromise();

    this.isLoading = false;
    this.getToken = response[0]['token'];
    this.apicallstatus = true;
  
    const emptyToken = "";
    const newData = {project_code:'demo1', token:this.receivedToken};

         this.authenticationService.updateToken(newData).subscribe((res)=>{
         console.log(emptyToken);
         console.log(res);
        console.log(newData);
         })
  
}
async  navHome() {

    await this.getTokenFromDb();
    this.ValidateURL();
    // console.log('print',this.flag);
    // console.log('print',this.receivedToken);
    // console.log('print',this.getToken);
    if(this.flag=1){
    this.authenticationService.tempLogin().subscribe((res)=>{
      // console.log("working");
      localStorage.setItem('boilerUser', JSON.stringify(res));
      localStorage.setItem("backToken","1");
      // console.log("woksFOr")
    this.router.navigateByUrl('dashboard')
  })}else{
    // console.log("woksFOr")
    this.router.navigateByUrl('login')
  }
  } 
 }


function body(body: any) {
  throw new Error('Function not implemented.');
}

