import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APiService } from 'src/app/core/balco.service';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
@Component({
  selector: 'app-theme15',
  templateUrl: './theme15.component.html',
  styleUrls: ['./theme15.component.scss']
})
// export class Theme15Component implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
export class Theme15Component implements OnInit {
  Valuesdaywise: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now() - 86400000);
  minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;




  today: number = Date.now();
  kips: any;
  name: string = '';
  file: any;
  selectedFile = null;
  selectedFileType = 'kiln_quality';
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  fileName = '';
  response: string;
  apiresp: any;
  // fileType:any;

  // simpleForm: FormGroup;
  submitted = true;
  alert: boolean
  files: any;
  error: String
  data: String
  res: string;
  nghg: any;
  s3Inten: any;
  selectedDatenext: any;
  EmissionAccOGdata: any;
  TotalmetahaneProdData: any;
  TotalmetahaneEMISSIONData: any;
  constructor(private plotChartsService: PlotChartsService, private ApicallService: APiService, public dialog: MatDialog,) {
  }


  ngOnInit(): void {
    this.selectedDatenext= '1680373800';
    let dte = new Date(Date.now() - 86400000);
    this.appliedDateFilters['start_date'] = dte
    this.getEmissionAcc();
    this.getTotametahaneProd();
    this.getTotametahaneEmission();

    setTimeout(() => {
      this.DrawTotametahaneProd();
      this.DrawTotametahaneeMISSION();
    }, 300);
  
   
  }
  // emission_accounting
  // methane_production_og
getEmissionAcc(){
  this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
  this.ApicallService.EmissionAccOG(this.startdatef).subscribe(
    (data: any) => {
      this.EmissionAccOGdata = data;
      console.log(this.EmissionAccOGdata, "EmissionAccOGdata")
    },
    (err) => {
      (err);

    }
  )
}

getTotametahaneProd(){
  let ref = this;
  ref.plotChartsService.TotalmetahaneProd(this.selectedDatenext).subscribe((res) => {
    ref.TotalmetahaneProdData = res;

    console.log("TotalmetahaneProdData", ref.TotalmetahaneProdData)
    ref.DrawTotametahaneProd();
    // ref.drawCOChart1();
    // ref.drawFC2Chart();

  });
}

// methane_emission_og
getTotametahaneEmission(){
  let ref = this;
  ref.plotChartsService.TotalmetahaneEmission(this.selectedDatenext).subscribe((res) => {
    ref.TotalmetahaneEMISSIONData = res;

    console.log("TotalmetahaneEMISSIONData", ref.TotalmetahaneEMISSIONData)
    ref.DrawTotametahaneeMISSION();
    // ref.drawCOChart1();
    // ref.drawFC2Chart();

  });
}




  DrawTotametahaneProd() {
   
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      var chart = am4core.create("DivTotalMEthane", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.marginRight = 15;
      chart.paddingLeft = 10;
      chart.marginLeft = 10;
      var chartData = this.TotalmetahaneProdData;
      chart.data = chartData;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 75;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
  
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 1800;
      valueAxis.marginRight = 30;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.title.text = "Total Methane Prod ";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "methane_per_day_tft3";
      series.dataFields.categoryX = "date";
      series.name = "Total Methane Prod ";
      series.columns.template.tooltipText =
        "[font-size: 12px]{name}:{valueY.formatNumber('#.')}[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.propertyFields.url = "url";
      series.strokeWidth = 3;
      series.stroke = am4core.color("#FFD1A2");
      series.fill = am4core.color("#FFD1A2");
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      
      
      
      
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color("#fff");
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";
    
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;

    
      
    }, 100);
  }
  DrawTotametahaneeMISSION() {
  
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      var chart = am4core.create("DivTotalMEthaneeMISSION", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.marginRight = 15;
      chart.paddingLeft = 10;
      chart.marginLeft = 10;
      var chartData = this.TotalmetahaneEMISSIONData;
      chart.data = chartData;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 75;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
  
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.marginRight = 30;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.title.text = "Total Methane Emission ";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "total_loss_tft3";
      series.dataFields.categoryX = "date";
      series.name = "Total Methane Emission ";
      series.columns.template.tooltipText =
        "[font-size: 12px]{name}:{valueY.formatNumber('#.')}[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.propertyFields.url = "url";
      series.strokeWidth = 3;
      series.stroke = am4core.color("#9AAFFF");
      series.fill = am4core.color("#9AAFFF");
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      
      
      
      
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color("#fff");

    
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;

      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";
      
    }, 100)
  }
}