<section>

  <div *ngIf="this.AfrToggle1 == 1">
    <div class="mainGridRgt11" style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        border-bottom: 2px solid;
      ">
      <div class="asImgBoxTop pt-2 pb-2">
        <div class="asImgBoxTopTxt">AFR Recommendation</div>
      </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
            <input style="padding-top: 0.9vmin;
            color: white;
            font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
        </div>
        <button title="Select the date and click." style="
           font-size: 2.3vmin;
      width: 11vmin;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      /* margin-left: 4%; */
      /* padding-top: 2px; */
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 5vmin;border-radius: 0.9375em !important;display: flex;justify-content: center;align-items: center;
      " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
          Apply
        </button>
      </div>
    </div>

    <div *ngIf="!isLoading">
      <span style=" flex-direction: column;
display: flex;
justify-content: space-between;">
        <div class="col-md-12" style="display: flex;flex-direction: row;justify-content: flex-end;padding-top: 10px;">
          <!-- <div style="display: flex">
          <button
            class="btn1-button"
            style="
              font-size: 12px;
              width: 120px;
              background-color: #302c2c;
              outline: unset;
              float: right;
              border: 1px solid rgba(255, 255, 255, 0.38);
            "
            (click)="onItemChangeAfrsim($event)"
            *ngIf="this.AfrToggle1 == 1"
          >
            Simulator
          </button>
        </div> -->
          <div style="display: flex">
            <span style="color: #fff; float: right;font-size:2vmin;">
              <tr *ngFor="let vval of AFRdata">
                <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
              </tr>
            </span>
          </div>
        </div>

        <div class="theme1PlotBox">
          <div class="row">
            <div class="col-md-12">
              <span
                style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;border-bottom:1px solid rgba(255, 255, 255, 0.38);">

                <h1 *ngIf="this.graphID == 1" style="
                  font-weight: bold;
                  margin-left: 2px;
                  color: #ffffff;
                  font-size: 2vmin;
                  margin: 0px;
                  padding-bottom: 5px;
                ">
                  Liquid AFR vs Kiln Coal
                </h1>
                <h1 *ngIf="this.graphID == 2" style="
                  font-weight: bold;
                  margin-left: 2px;
                  color: #ffffff;
                  font-size: 2vmin;
                  margin: 0px;
                  padding-bottom: 5px;
                ">
                  Liquid AFR vs PC Coal
                </h1>
                <h1 *ngIf="this.graphID == 3" style="
                  font-weight: bold;
                  margin-left: 2px;
                  color: #ffffff;
                  font-size: 2vmin;
                  margin: 0px;
                  padding-bottom: 5px;
                ">
                  Liquid AFR vs PA Air
                </h1>
                <h1 *ngIf="this.graphID == 4" style="
                  font-weight: bold;
                  margin-left: 2px;
                  color: #ffffff;
                  font-size: 2vmin;
                  margin: 0px;
                  padding-bottom: 5px;
                ">
                  Liquid AFR vs All
                </h1>

                <select class="select-dropdown" name="select" id="" style="
                        padding: 5px;
                        border-radius: 4px;
                        border: 1px solid rgba(255, 255, 255, 0.38);
                        font-weight: 600;
                        font-size: 2vmin;
                        width: 15vmin;
                        margin-bottom:10px;
                        box-shadow: 3px 3px 5px #5a5a5a;
                      " (change)="changeDropdownGraph($event)">
                  <option value="1" >Kiln Coal</option>
                  <option value="2" > PC Coal</option>
                  <option value="3" >Primary Air</option>
                  <option value="4" >All</option>
                </select>

              </span>
              <div class="theme1PlotBoxGraph">
                <div id="ppediv1"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <table class="tableStyle1" *ngIf="AFRNewTabledata.length != 0">
              <tr>
                <th colspan="2" style="
                  font-size: 1.8vmin;
                  color: yellow;
                  text-align: center;
                  border-radius: 10px;
                ">
                  Constrain Pre Calciner
                </th>
                <th colspan="4" style="
                  font-size: 1.8vmin;
                  color: yellow;
                  text-align: center;
                  border-radius: 10px;
                ">
                  Recommendation From The Actual (%)
                </th>
              </tr>
              <tr>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">Temperature (°C)</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">CO (PPM)</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">Kiln Coal</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">PC Coal</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">PA Air</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">AFR</td>
              </tr>
              <tr *ngFor="let vval of AFRNewTabledata">
                <td>{{ vval.constrained_temp }}</td>
                <td>{{ vval.constrained_co }}</td>
                <td>{{ vval.kiln_coal_increased }}</td>
                <td>{{ vval.pc_coal_increased }}</td>
                <td>{{ vval.pa_air_increased }}</td>
                <td>{{ vval.afr_increased }}</td>
              </tr>
            </table>
            <table class="tableStyle1" *ngIf="AFRNewTabledata.length == 0">
              <tr>
                <th colspan="2" style="
                  font-size: 1.8vmin;
                  color: yellow;
                  text-align: center;
                  border-radius: 10px;
                ">
                  Constrain Pre Calciner
                </th>
                <th colspan="4" style="
                  font-size: 1.8vmin;
                  color: yellow;
                  text-align: center;
                  border-radius: 10px;
                ">
                  Recommendation From The Actual (%)
                </th>
              </tr>
              <tr>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">Temperature (°C)</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">CO (PPM)</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">Kiln Coal</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">PC Coal</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">PA Air</td>
                <td style=" font-size: 1.8vmin;
                  color: yellow;">AFR/td>
              </tr>

              <tr>
                <td colspan="4">No Data</td>
              </tr>
            </table>
          </div>
        </div>
        <div style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
         /* padding: 1%; */
          margin-top: 1vmin;
          margin-bottom: 1vmin;
        ">
          <div>
            <button class="btn1-button" style="
               font-size: 1.7vmin;width: 17vmin;
                height: 5vmin;
                background-color: #302c2c;
                outline: unset;
                float: right;
                border: 1px solid rgba(255, 255, 255, 0.38);
              " (click)="onItemChangeAfrsim($event)" *ngIf="this.AfrToggle1 == 1">
              Simulator
            </button>
          </div>
          <div>
            <span><button style="
            font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          " *ngIf="!disablePrevButton" mat-raised-button class="get-sm-btn btn1" color="primary"
                (click)="onPreviousclick()">
                Previous
              </button></span>
          </div>

          <button style="
            font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          " *ngIf="!disableNextButton" mat-raised-button class="get-sm-btn btn1" color="primary"
            (click)="onNextclick()">
            Next
          </button>
        </div>
      </span>
    </div>
  </div>

  <div *ngIf="this.AfrToggle1 == 2">
    <div class="mainGridRgt11" style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        border-bottom: 2px solid;
      ">
      <div class="asImgBoxTop pt-2 pb-1">
        <div class="asImgBoxTopTxt">AFR-Simulator</div>
      </div>
    </div>

    <span *ngIf="!isLoading" style="
       /* height: 425px; */

        /* overflow-y: auto;
        overflow-x: hidden; */
        display: flex;
        flex-direction: column;
        /* padding-top: 10px; */
      ">
      <!-- <div style="width: 100%">
        <button
          class="btn1-button"
          style="
            font-size: 12px;
            width: 120px;
            background-color: #302c2c;
            outline: unset;
            float: right;
            border: 1px solid rgba(255, 255, 255, 0.38);
          "
          (click)="onItemChangeAfrsimback($event)"
          *ngIf="this.AfrToggle1 == 2"
        >
          Recommended
        </button>
      </div> -->



      <div class="red1">
        <div class="row" style="padding: 5px">
          <div class="col-md-12">
            <div style="display: flex;justify-content:end;padding-bottom:5px">
            <table class="tableStyle2">
              <tr *ngFor="let vval of AFRNewTabledata">
                <td>Date -&nbsp;{{ vval.obs_time | date : "MM/dd/yyyy" }}</td>
                <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
              </tr>
            </table>
          </div>

            <table class="tableStyle1">
              <tr>
                <th class="table-head" colspan="1">Parameters</th>
                  <th class="table-head" colspan="1">
                    AFR (M<sup>3</sup>)
                  </th>
                  <th colspan="1" class="table-head">
                    MC (TPH)
                  </th>
                  <th colspan="1" class="table-head">
                    PC (TPH)
                  </th>
                  <th colspan="1" class="table-head">
                    PA Fan (RPM)
                  </th>
                  <th colspan="1" class="table-head">
                    PCT (°C)
                  </th>
                  <th colspan="1" class="table-head">
                    BZT (°C)
                  </th>
              </tr>
              <tr *ngFor="let vval of AFRRealtimeTable">
                <th class="table-head1"> Real Time Parameters</th>
                <td class="table-data" >{{ vval.liquid_afr_flow_rate }}</td>
                <td class="table-data">{{ vval.main_coal_tph }}</td>
                <td class="table-data">{{ vval.pc_coal_tph }}</td>
                <td class="table-data" >{{ vval.pa_fan_speed }}</td>
                <td class="table-data">{{ vval.cyc_6_avg_temp }}</td>
                <td class="table-data">{{ vval.burning_zone_temp}}</td>
              </tr>
              <tr *ngFor="let vval of AFRChangedTable">
                <th class="table-head1">Changes Against AFR</th>
                <td class="table-data" [style.color]="mycolor" colspan="1">
                  {{ vval.liquid_afr_flow_rate }}
                </td>
                <td class="table-data" colspan="1">
                  {{ vval.main_coal_tph }}
                </td>
                <td class="table-data" colspan="1">
                  {{ vval.pc_coal_tph }}
                </td>
                <td class="table-data" colspan="1">
                  {{ vval.pa_fan_speed }}
                </td>
                <td class="table-data" style="color: #3794ff ;" colspan="1">
                  {{ vval.change_pct_temp }}
                </td>
                <td class="table-data" style="color: #3794ff;" colspan="1">
                  {{ vval.change_bzt_temp }}
                </td>
              </tr>
              <tr *ngFor="let vval of AFRRecommendedTable">
                <th class="table-head1"> GreenOps Recommendation</th>
                <td class="table-data" style="color: #2cdd2c;" colspan="1">
                  {{ vval.liquid_afr_flow_rate }}
                </td>
                <td class="table-data" [style.color]="mycolor1" colspan="1">
                  {{ vval.mc_coal_tph }}
                </td>
                <td class="table-data" [style.color]="mycolor2" colspan="1">
                  {{ vval.pc_coal_tph }}
                </td>
                <td class="table-data" colspan="1">
                  {{ vval.pa_fan_speed }}
                </td>
                <td class="table-data" style="color: #2cdd2c;" colspan="1">
                  {{ vval.pc_temp }}
                </td>
                <td class="table-data" style="color: #2cdd2c;" colspan="1">
                  {{ vval.burning_zone_temp }}
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>

      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">Simulator Inputs (User Inputs)</p>
          <!-- <span style="color: #fff">
            <tr *ngFor="let vval of AFRdata">
              <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
            </tr>
          </span> -->
          <button style="
          font-size: 2.2vmin;
       width: 12vmin;
       height: 5vmin;
           cursor: pointer;
           background-color: #302c2c;
           outline: unset;
           border: 1px solid rgba(255, 255, 255, 0.38);
           align-items: center;
           justify-content: center;
           display: flex;
         " class="get-sm-btn btn1" mat-raised-button color="primary" (click)="Submit()" [disabled]="
         !selectedAfrQty &&
         !selectedMainCoal &&
         !selectedPcCoal &&
         !selectedPaFanSpeed
       ">Apply</button>
        </div>

        <div class="row" style="padding: 5px">
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">

              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">AFR QTY (M<sup>3</sup>)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [(ngModel)]="selectedAfrQty">

                    <option *ngFor="let option of optionsAfrQty" >
                      {{ option }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">

              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">Main Coal (TPH)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                     font-size: 2vmin;
                      width: 15vmin; 
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [disabled]="!selectedAfrQty" [(ngModel)]="selectedMainCoal">
                    <option *ngFor="let option of optionsMC" >{{ option }}</option>
                  </select>

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 5px">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">

              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">PC Coal (TPH)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [disabled]="!selectedAfrQty" [(ngModel)]="selectedPcCoal">
                    <option *ngFor="let option of optionsPcCoal" >
                      {{ option }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="padding-top: 5px">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">

              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                ">
                <div class="col-md-7" style="display: flex; justify-content: flex-start">
                  <label style="margin: unset" for="temp">PA Fan Speed (RPM)</label>
                </div>
                <div class="col-md-5" style="justify-content: flex-end; display: flex">
                  <select class="select-dropdown" name="select" id="" style="
                      padding: 5px;
                      border-radius: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.38);
                      font-weight: 600;
                      font-size: 2vmin;
                      width: 15vmin;
                      box-shadow: 3px 3px 5px #5a5a5a;
                    " [disabled]="!selectedAfrQty" [(ngModel)]="selectedPaFanSpeed">
                    <option *ngFor="let option of optionsPaFanSpeed" >
                      {{ option }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>


        </div>
        <!-- <div
          class="col-md-12"
          style="padding-top: 10px; display: flex; justify-content: center"
        >
        <div style="padding-top: 10px;">

          <button style="
          font-size: 2vmin; 
          width: 90px;
          cursor: pointer;
          background-color: #302c2c;
          outline: unset;
          border: 1px solid rgba(255, 255, 255, 0.38);
          align-items: center;
          justify-content: center;
          display: flex;
        " class="get-sm-btn btn1"  mat-raised-button color="primary" (click)="Submit()">Apply</button>

    </div>
        </div> -->
      </div>

      <div class="red1">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <p class="mainGridtitle1">Simulator Output (Model Outcome)</p>
          <span style="color: #fff">
          </span>
        </div>
        <div class="row" style="padding: 2px 5px 2px 5px;">
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Burning Zone Temperature (°C)</label>
              <span>
                <div>
                  <label class="per">
                    {{ SimulationData1 }}
                  </label>
                </div>
              </span>

            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="
                border-radius: 6px;
                box-shadow: inset 7px 7px 14px black,
                  inset -7px -7px 14px #5a5a5a;
              ">
              <label for="temp">Pre Calciner Temperature (°C)</label>
              <span>
                <div>
                  <label class="per">
                    {{ SimulationData2 }}
                  </label>
                </div>
              </span>

            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;justify-content:space-between;padding-top:2px;padding-bottom:3px">
        <div>
          <button style="
          font-size: 2.2vmin;
    width: 12vmin;
    height: 5vmin;
          cursor: pointer;
          background-color: #302c2c;
          outline: unset;
          border: 1px solid rgba(255, 255, 255, 0.38);
          align-items: center;
          justify-content: center;
          display: flex;
        " *ngIf="!disablePrevButton" mat-raised-button class="get-sm-btn btn1" color="primary"
            (click)="onPreviousclick()">
            Previous
          </button>
        </div>

        <div>
          <button style="
          font-size: 2.2vmin;
    width: 12vmin;
    height: 5vmin;
          cursor: pointer;
          background-color: #302c2c;
          outline: unset;
          border: 1px solid rgba(255, 255, 255, 0.38);
          align-items: center;
          justify-content: center;
          display: flex;
        " *ngIf="!disableNextButton" mat-raised-button class="get-sm-btn btn1" color="primary"
            (click)="onNextclick()">
            Next
          </button>
        </div>
      </div>

    </span>
  </div>

  <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img src="../../../../assets/icons/Spinner.gif" style="width: 10vmin" />
      <p style="text-align: center; color: #fff;font-size:3vmin;">Loading...</p>
    </div>
  </div>
</section>