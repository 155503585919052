<!-- <footer
  class="font-small text-light w-100 "
  style="
    background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
    /* z-index: 1023; */
    box-shadow: 0 -2px 10px rgb(0 0 0);
    /* margin-top: 1%; */
    width: 100%;
  "
>
  <div
    class="footer-copyright text-left py-3"
    style="color: white; padding: 0.7%"
  >
    All Rights Reserved | &copy; {{ today | date : "yyyy" }} LivNSense &reg;
    Technologies Pvt Ltd
    <span class="footer-copyright text-right py-3" style="float: right"
      >Powered by
      <img src="../../../assets/images/gopslogowht.png" style="width: 13%"
    /></span>
  </div>
</footer> -->
<!-- <footer class="font-small text-light w-100 mt-2" style="    background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23)); z-index: 1023; box-shadow: 0 -2px 10px rgb(0 0 0); margin-top: 1%;width:100%;">
    <div class="col-md-6">
        <span class="text-left text-white d-inline-block">&copy; {{today | date:'yyyy'}} LivNSense &reg; </span>
    </div>
    <div class="col-md-6">
        <span class="text-right text-white d-inline-block pr-2" style="float: right;">Powered by 
            <img
            src="../../../assets/images/gopslogowht.png" style="width: 20%;"></span>
    </div>
</footer> -->
<div class="row footer-element ">
    <div class="footer-container">
      <span class="text-left text-white d-inline-block"  style="color: white; padding: 0.7%"
        >   All Rights Reserved | &copy; {{ today | date : "yyyy" }} LivNSense &reg;
        Technologies Pvt Ltd
      </span>
    <!-- </div> -->
    <!-- <div class="footer-container"> -->
      <span
        class="text-right text-white d-inline-block"
        style="float: right;color: white;"
        >Powered by
        <img src="../../../assets/images/gopslogowht.png" style="height: 4vmin;"
        /></span>
    </div>
  </div>