<section style="display: flex;flex-direction: row;">
    <div class="card" style="width: 50%;margin-right: 5px;margin-left: -10px;">
        <div class="row">
            <div class="col-md-12"
          >
            <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">Methane Accounting - O&G </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div class="col-md-12">
                                    <p class="mainGridtitle4" style="padding-bottom: 0px !important;">Total Production Per Day </p>
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-12">
                                        <table class="tableStyle1">
                                            <tr *ngFor="let vvald of EmissionAccOGdata">
                                                <th colspan="1"
                                                style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                                Methane (Tons)</th>
                                                <td>{{vvald.methane_per_day_tft3}}</td>
                                                <!-- <td>{{ item.sox }}</td>
                                                <td>{{ item.pm }}</td>
                                                <td>{{ item.voc }}</td>
                                                <td>{{ item.poc }}</td>
                                                <td>{{ item.hap }}</td> -->
                                            </tr>
                                        </table>
                                    </div>
                                 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div  class="col-md-12" >
                                <p class="mainGridtitle4" style="padding-bottom: 0px !important;">Vented Emission - Upstream</p>
                          <table class="tableStyle1">
                            <tr>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    Source</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Methane Emission Per Day (Tons)
                                </th>
                            </tr>
                            <tr *ngFor="let item of EmissionAccOGdata">
                                <th style="font-size: 16px;color: yellow;">Pneaumatic Control</th>
                                <td>{{ item.pneaumatic_control_b_tft3 }}</td>
                                <!-- <td>{{ item.pumps_b_tft3 }}</td>
                                <td>{{ item.other_equipment_b_tft3 }}</td> -->
                             
                            </tr>
                            <tr *ngFor="let item of EmissionAccOGdata">
                                <th style="font-size: 16px;color: yellow;">Pumps</th>
                                <!-- <td>{{ item.pneaumatic_control_b_tft3 }}</td> -->
                                <td>{{ item.pumps_b_tft3 }}</td>
                                <!-- <td>{{ item.other_equipment_b_tft3 }}</td> -->
                             
                            </tr>
                            <tr *ngFor="let item of EmissionAccOGdata">
                                <th style="font-size: 16px;color: yellow;">Other Equipments</th>
                                <!-- <td>{{ item.pneaumatic_control_b_tft3 }}</td>
                                <td>{{ item.pumps_b_tft3 }}</td> -->
                                <td>{{ item.other_equipment_b_tft3 }}</td>
                             
                            </tr>
                        </table>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div  class="col-md-12" >
                                <p class="mainGridtitle4" style="padding-bottom: 0px !important;">Fugitive Emission - Midstream</p>
                          <table class="tableStyle1">
                            <tr>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    Source</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Methane Emission Per Day (Tons)
                                </th>
                            </tr>
                            <tr *ngFor="let item of EmissionAccOGdata">
                                <th style="font-size: 16px;color: yellow;">Compressor Station</th>
                                <td>{{ item.compressor_station_b_tft3 }}</td>
                                <!-- <td>{{ item.pumps_b_tft3 }}</td>
                                <td>{{ item.other_equipment_b_tft3 }}</td> -->
                             
                            </tr>
                            <tr *ngFor="let item of EmissionAccOGdata">
                                <th style="font-size: 16px;color: yellow;">Pipeline Leaks</th>
                                <!-- <td>{{ item.pneaumatic_control_b_tft3 }}</td> -->
                                <td>{{ item.pipe_leakage_b_tft3 }}</td>
                                <!-- <td>{{ item.other_equipment_b_tft3 }}</td> -->
                             
                            </tr>
                          
                        </table>
                    </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div style="width: 100%;padding-top: 3%;">
                <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                    style="width:100%; margin:auto; height: 542px;">
            </div> -->
        </div>
          
        </div>
    </div>
    <div class="card " style="width: 50%; margin-left: 5px;">
        <div class="row">
            <div class="col-md-12"> 
                <div class="mainGridRgtTitl"
                  style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">
                 Methane Production
           
                </div>
          
                <div class="theme1PlotBox">
                  <div class="row">
                    <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                        Total Methane Production (Tons)
                    </div>
                    <div *ngFor="let sval of Valuesdaywise  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;float: right;">
                      <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 15px;">{{sval.co2_saving_per_ton}}</span> kg/Ton of kiln feed
                     </div>
                  </div>
                  <div class="theme1PlotBoxGraph">
                    <div id="DivTotalMEthane">
            
                    </div>
                  </div>
                  
                </div>
                <div class="theme1PlotBox">
                  <div class="row">
                    <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                        Total Methane Emission (Tons)
                    </div>
                    <div *ngFor="let sval of Valuesdaywise  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{sval.co_saving_per_ton}}</span> kg/Ton of kiln feed
                    </div>
                  </div>
                  <div class="theme1PlotBoxGraph">
                    <div id="DivTotalMEthaneeMISSION">
            
                    </div>
                  </div>
                  
                </div>
              
            
            
              </div>
        </div>
    </div>
    
    </section>