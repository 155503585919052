<section>
  <div class="mainGridRgt11"
  style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
      <div class="asImgBoxTop pt-2 pb-2">
        <div class="asImgBoxTopTxt">AFR Injection -Critical
          Process Controls</div>
      </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
            <input style="padding-top: 0.9vmin;
            color: white;
            font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate ></mat-datepicker>
          </mat-form-field>
        </div>
        <button title="Select the date and click." style="
           font-size: 2.3vmin;
      width: 11vmin;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      /* margin-left: 4%; */
      /* padding-top: 2px; */
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 5vmin;border-radius: 0.9375em !important;display: flex;justify-content: center;align-items: center;
      " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
          Apply
        </button>
      </div>  
    </div>
    <div *ngIf="!isLoading" style=" font-size: 13px;flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    /* height: 560px; */
    ">
    <span style="flex-direction: column;
    display: flex;
    justify-content: space-between;
   /* height: 425px; */
">
      <div class="red1">
        <div class="row" style="padding-left: 1%;
            padding-right: 1%;">
          <div class="col-md-12">

            <div style="display:flex ;flex-direction: row;justify-content: space-between;">
              <p class="mainGridtitle1"  style="font-size: 2.6vmin;">RealTime Parameters </p>
              <span style=" color: #fff;font-size: 2vmin;">
                <tr *ngFor="let vval of Realtimedata  ">
                  <td>Time -&nbsp;{{vval.dateandtime | date:'HH:mm'}}
                  </td>
                </tr>
              </span>
            </div>
            <!-- <hr> -->

            <div class="row" style="padding: 1%;">
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Cyc-6 Avg Temp (°C)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">{{vval.cyc_6_avg_temp}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Cyc-6 Outlet CO (PPM)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.cyc_6_outlet_co < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_outlet_co >=  0 ">{{vval.cyc_6_outlet_co}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Kiln Feed (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.kiln_feed < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.kiln_feed >=  0 ">{{vval.kiln_feed}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
            </div>
            <div class="row" style="padding: 1%;">


              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">AFR Flowrate (M<sup>3</sup>/Hr)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate >=  0 ">{{vval.liquid_afr_flow_rate}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">PC Coal (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.pc_coal < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.pc_coal >=  0 ">{{vval.pc_coal}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Main Coal (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.main_coal_tph < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.main_coal_tph >=  0 ">{{vval.main_coal_tph}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="red1">
        <div style="display:flex ;flex-direction: row;justify-content: space-between;">
          <p class="mainGridtitle1" style="font-size: 2.6vmin;">Forecasting Values </p>
          <span style=" color: #fff;font-size: 2vmin;">
            <tr *ngFor="let vval of Forecastdata  ">
              <td>Time -&nbsp;{{vval.date_time | date:'HH:mm'}}
              </td>
            </tr>
          </span>
        </div>

        <div class="row" style="padding: 1%;">
          <div class="col-md-6">
            <div class="red" style="border-radius: 6px;
        box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;">
              <label for="temp">Cyc-6 avg Temp (°C)</label>
              <span *ngIf="Forecastdata.length != 0">
              <div *ngFor="let vval of Forecastdata  ">
                <label class="per" *ngIf="vval.forecast_cyc_6_avg_temp < 0 ">0.00</label>
                <label class="per" *ngIf="vval.forecast_cyc_6_avg_temp >= 0 ">{{vval.forecast_cyc_6_avg_temp}}</label>
              </div>
            </span>
              <div *ngIf="Forecastdata.length ==0 || null "><label class="per">No data</label></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="border-radius: 6px;
        box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;">
              <label for="temp">Cyc-6 outlet CO (PPM)</label>
              <span *ngIf="Forecastdata.length != 0">
              <div *ngFor="let vval of Forecastdata">
                <label class="per" *ngIf="vval.forecast_cyc_6_outlet_co < 0 ">0.00</label>
                <label class="per" *ngIf="vval.forecast_cyc_6_outlet_co >= 0 ">{{vval.forecast_cyc_6_outlet_co}}</label>
              </div>
            </span>
              <div *ngIf="Forecastdata.length ==0 || null"><label class="per">No data</label></div>
            </div>
          </div>


        </div>
      </div>

      <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
            margin-top: 1%;
        ">
        <div>
          <span ><button style="
             font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
            cursor: pointer;
            background-color: #302c2c;
            outline: unset;
            border: 1px solid rgba(255, 255, 255, 0.38);
            align-items: center;
            justify-content: center;
            display: flex;
          " mat-raised-button class="get-sm-btn btn1" color="primary"
              (click)="onPreviousclick()"> Previous</button></span>
        </div>
        <button style="
        font-size: 2.2vmin;
          width: 12vmin;
          height: 5vmin;
        cursor: pointer;
        background-color: #302c2c;
        outline: unset;
        border: 1px solid rgba(255, 255, 255, 0.38);
        align-items: center;
        justify-content: center;
        display: flex;
      " mat-raised-button class="get-sm-btn btn1" color="primary" (click)="onNextclick()"> Next</button>
      </div>
    </span>
    </div>

    <div *ngIf="isLoading" style="height: 68vh; display: grid; width: 100%">
      <div style="margin: auto">
        <img
          src="../../../../assets/icons/Spinner.gif"
          style="width: 10vmin"
        />
        <p style="text-align: center;color: #fff;font-size:3vmin">Loading...</p>
      </div>
    </div>
</section>