<section style="display: flex;flex-direction: row;">
    <div class="card" style="width: 50%;margin-right: 5px;margin-left: -10px;">
        <div class="row">
            <div class="col-md-12"
          >
            <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">Daywise - Emission Reduction </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div class="col-md-12">

                                <!-- <div -->
                                    <!-- style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;"> -->
                                    <p class="mainGridtitle4" style="padding-bottom: 0px !important;
margin-bottom: 0px !important;">Day Feeds </p>

                                <!-- </div> -->
                                <!-- <hr> -->

                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Kiln Feed (Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.kiln_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Main Coal(Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.main_coal_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">PC Coal(Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.pc_coal_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div class="col-md-12">

                                <!-- <div -->
                                    <!-- style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;"> -->
                                    <p class="mainGridtitle4" style="padding-bottom: 0px !important;
margin-bottom: 0px !important;">Emission Summary </p>

                                <!-- </div> -->
                                <!-- <hr> -->
                                <!-- headings for the table  -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Flue gas</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Before GreenOps</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">After GreenOps</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Savings</label>
                                            <!-- <div>
                                                    <label class="per">328.71</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Units</label>
                                            <!-- <div>
                                                    <label class="per">328.71</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>

                                </div>
   <!-- ch4 -->
   <div class="row" style="padding: 1%;">
    <div class="col-md-2">
        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;">
            <label for="temp">CH<sub>4</sub></label>
            <!-- <div>
                    <label class="per"></label>
                    <label class="per"></label>
                </div> -->
            <!-- <div ><label class="per">No data</label></div> -->
        </div>
    </div>
    <div class="col-md-3">
        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;">
            <label for="temp" style="color: #fff;">{{vvald.ch4_kg}}</label>
        </div>
    </div>
    <div class="col-md-3">
        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;">
            <!-- <label for="temp">PC Coal(Ton)</label> -->
            <label for="temp" style="color: #fff;">{{vvald.ch4_reduction_kg}}</label>
            <!-- <div ><label class="per">No data</label></div> -->
        </div>
    </div>
    <div class="col-md-2">
        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;">
            <!-- <label for="temp">PC Coal(Ton)</label> -->
            <label for="temp" style="color: #fff;">{{vvald.ch4_per}}</label>
            <!-- <div ><label class="per">No data</label></div> -->
        </div>
    </div>
    <div class="col-md-2">
        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
          inset -7px -7px 14px #5a5a5a;">
            <!-- <label for="temp">PC Coal(Ton)</label> -->
            <label for="temp" style="color: #fff;">Kg</label>
            <!-- <div ><label class="per">No data</label></div> -->
        </div>
    </div>
</div>
                                <!-- co2 -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">CO<sub>2</sub></label>
                                            <!-- <div>
                                                    <label class="per"></label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">{{vvald.before_CO2}}</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_CO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_CO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">Tons</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- so2 -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">SO<sub>2</sub></label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- nox -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">NO<sub>x</sub> </label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- co -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">CO</label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div style="width: 100%;padding-top: 3%;">
                <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                    style="width:100%; margin:auto; height: 542px;">
            </div> -->
        </div>
          
        </div>
    </div>
    <div class="card " style="width: 50%; margin-left: 5px;">
        <div class="row">
            <div class="col-md-12"> 
                <div class="mainGridRgtTitl"
                  style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">
                  Methane Savings
           
                </div>
          
                <div class="theme1PlotBox">
                  <div class="row">
                    <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;">
                     Methane Savings
                    </div>
                    <!-- <div *ngFor="let sval of Valuesdaywise  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 15px;float: right;">
                      <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 15px;">{{sval.co2_saving_per_ton}}</span> kg/Ton of kiln feed
                     </div> -->
                  </div>
                  <div class="theme1PlotBoxGraph">
                    <div id="divCOChart">
            
                    </div>
                  </div>
                  
                </div>
                <div class="theme1PlotBox">
                  <div class="row">
                    <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
                      CO Savings
                    </div>
                    <!-- <div *ngFor="let sval of Valuesdaywise  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{sval.co_saving_per_ton}}</span> kg/Ton of kiln feed
                    </div> -->
                  </div>
                  <div class="theme1PlotBoxGraph">
                    <div id="COChart1">
            
                    </div>
                  </div>
                  
                </div>
              
            
            
              </div>
        </div>
    </div>
    
    </section>