import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { environment } from '@env/environment';
import { environment } from 'src/environments/environment';

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  maxDate: Date = new Date(Date.now());
  selectedDateFilter: any;

  selectedRole: any;

  qrData: any;

 
  date: any;
  constructor(readonly http: HttpClient) {

  }
   /* Getter for date filter in reports */
 get dateFilter(): any {
  return this.selectedDateFilter;
}

/* Setter for date filter in reports */
set dateFilter(filter) {
  this.selectedDateFilter = filter;
}

/* Getter for selected role in assessment form */
get role() {
  return this.selectedRole;
}

/* Setter for selected role in assessment form */
set role(role) {
  this.selectedRole = role;
}


/* Getter for selected role in assessment form */
get qrdata() {
  return this.qrData;
}

/* Getter for selected role in assessment form */
set qrdata(data) {
  this.qrData = data;
}


  formAmchartData(data, keys, parameter) {

    const dataSet = data['graph_data'][0];

    const timestamps = [];

    /* Push all the timeinstance array from all the data */

    keys.sort().forEach(e => {
      timestamps.push(dataSet[e]['timeinstance']);
    });

    /* Merge all the dates */
    const mergedArray = [].concat.apply([], timestamps);

    /* After merging get the unique dates */
    const uniqueDates = [...new Set(mergedArray)];

    /* Sort the dates in ascending order */
    uniqueDates.sort((a: Date, b: Date) => {

      if (new Date(a).getTime() < new Date(b).getTime()) {
        return -1;
      }
      if (new Date(a).getTime() > new Date(b).getTime()) {
        return 1;
      }

      return 0;
    });

    /* Iterate the sorted dates to form required JSON */
    let d = uniqueDates
      .map((e, i) => Object.assign({},
        ...keys.map((k) => {

          const index = dataSet[k]['timeinstance'].findIndex(el => el === e);

          return ({
            [k]: index > -1 ? dataSet[k][parameter][index] : null,
            date: e
          })
        })
      )
      );
    return d;
  }

  /* Method which returns data for scatter chart */
  formScatterChartData1(data, keys, isDate?: boolean) {

    // // // console.log('keys', keys);
    // // // console.log('data', data);


    return data['x-axis']
      .map((e, i) => Object.assign({},
        ...keys.map((k) => ({
          [k]: (isDate && k === 'x-axis')
            ? this.convertDateToCST(data[k][i]) : data[k][i]
        }))));
  }

  /* Method to convert date to MST */
  convertDateToCST(d) {
    return typeof d !== 'undefined' ? `${new Date(d).toLocaleString("en-US", { timeZone: 'America/Denver' })}` : null;
  }

  formChartData(data, keys) {
    return data['x-axis']
      .map((e, i) => Object.assign({},
        ...keys.map((k) => ({
          [k]: k !== 'x-axis'
            ? data[k][i]
            : data[k][i]
        }))));
  }

  getGraphData(parameter): Observable<any> {
    return this.http
      .get(
        `${baseUrl}demo/graph?parameters=${parameter}`
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // // // console.log(error);
          }
        )
      );
  }

  /* Tmt trends data to plot the graph*/
  getTmTTrendsData(parameter) {
    return this.http
      .get(
        `${baseUrl}demo/last_10runs?parameters=${parameter}`
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // // // console.log(error);
          }
        )
      );
  }

}
