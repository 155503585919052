import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APiService } from 'src/app/core/balco.service';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
@Component({
  selector: 'app-theme16',
  templateUrl: './theme16.component.html',
  styleUrls: ['./theme16.component.scss']
})
// export class Theme16Component implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
export class Theme16Component implements OnInit {
  Valuesdaywise: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now() - 86400000);
  minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;




  today: number = Date.now();
  kips: any;
  name: string = '';
  file: any;
  selectedFile = null;
  selectedFileType = 'kiln_quality';
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  fileName = '';
  response: string;
  apiresp: any;
  // fileType:any;

  // simpleForm: FormGroup;
  submitted = true;
  alert: boolean
  files: any;
  error: String
  data: String
  res: string;
  nghg: any;
  s3Inten: any;
  selectedDatenext: any;
  EmissionReducdata: any;
  TotalmetahanePnuematicData: any;
  TotalmetahanePipeData: any;
  selectone: any=[];
  ListOne: any;
  StatusType: any;
  TotalmetahanSavingsData: any;
  TotalmetahaneSavingsData: any;
  TotalmetahanePumpsData: any;
  TotalmetahaneCompressorData: any;
  TotalmetahaneOtherData: any;
  TotalmetahanePipeFCData: any;
  TotalmetahanforecastData: any;
  TotalmetahanePnuematicFCData: any;
  TotalmetahanePumpsFCData: any;
  TotalmetahaneCompressorFCData: any;
  TotalmetahaneOtherFCData: any;
  constructor(private plotChartsService: PlotChartsService, private ApicallService: APiService, public dialog: MatDialog,) {
  }



  changeDropdown(types:any){
    this.selectone = [];
    this.ListOne = types.target.value;
    this.selectone = this.ListOne;
    console.log("sdc", this.selectone)
this.getTotamethaneSavings();
this.getTotamethaneForecast();
  }
  ngOnInit(): void {
    this.selectedDatenext= '1680373800';
    this.selectone = "1";
    console.log("sdc", this.selectone)
    let dte = new Date(Date.now() - 86400000);
    this.appliedDateFilters['start_date'] = dte
    this.getEmissionReduc();
    this.getTotamethaneSavings();
    this.getTotamethaneForecast();
    // this.getTotametahanePipeline();
    // this.getTotametahanePnuematic();
    // this.DrawTotametahanePipe();
    // this.DrawTotametahanePnuematic();
   
  }
  getEmissionReduc(){
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.Emissionreduction(this.startdatef).subscribe(
      (data: any) => {
        this.EmissionReducdata = data;
        console.log(this.EmissionReducdata, "EmissionReducdata")
      },
      (err) => {
        (err);
  
      }
    )
  }

  getTotamethaneSavings(){
    this.plotChartsService.TotalmetahaneSavings(this.selectedDatenext, this.selectone).subscribe((res) => {
      this.TotalmetahanSavingsData = res;
  
      console.log("TotalmetahanSavingsData", this.TotalmetahanSavingsData, this.selectone)

if( this.selectone == 1){
  this.TotalmetahanePipeData = this.TotalmetahanSavingsData;
  console.log("  this.TotalmetahanePipeData",  this.TotalmetahanePipeData)
  setTimeout(() => {
    this.DrawTotametahanePipe();
  }, 500);
 
}else if( this.selectone == 2){
  this.TotalmetahanePnuematicData = this.TotalmetahanSavingsData;
  setTimeout(() => {
    this.DrawTotametahanePnuematic();
  }, 500);

}
else if( this.selectone == 3){
  this.TotalmetahanePumpsData = this.TotalmetahanSavingsData;
  setTimeout(() => {
    this.DrawTotametahanePumps();
  }, 500);
  
}
else if( this.selectone == 4){
  this.TotalmetahaneCompressorData = this.TotalmetahanSavingsData;
  setTimeout(() => {
    this.DrawTotametahaneCompressor();
  }, 500);

}
else if( this.selectone == 5){
  this.TotalmetahaneOtherData = this.TotalmetahanSavingsData;
  setTimeout(() => {
    this.DrawTotametahaneOther();
  }, 500);

}
  
      // ref.drawCOChart1();
      // ref.drawFC2Chart();
  
    });
  }

  getTotamethaneForecast(){
    this.plotChartsService.TotalmetahaneForecast( this.selectone).subscribe((res) => {
      this.TotalmetahanforecastData = res;
  
      console.log("TotalmetahanSavingsData", this.TotalmetahanSavingsData, this.selectone)

if( this.selectone == 1){
  this.TotalmetahanePipeFCData = this.TotalmetahanforecastData;
  console.log("  this.TotalmetahanePipeData",  this.TotalmetahanePipeData)
  setTimeout(() => {
    this.DrawTotametahaneFCPipe();
  }, 500);
 
}else if( this.selectone == 2){
  this.TotalmetahanePnuematicFCData = this.TotalmetahanforecastData;
  setTimeout(() => {
    this.DrawTotametahaneFCPnuematic();
  }, 500);

}
else if( this.selectone == 3){
  this.TotalmetahanePumpsFCData = this.TotalmetahanforecastData;
  setTimeout(() => {
    this.DrawTotametahaneFCPumps();
  }, 500);
  
}
else if( this.selectone == 4){
  this.TotalmetahaneCompressorFCData = this.TotalmetahanforecastData;
  setTimeout(() => {
    this.DrawTotametahaneFCCompressor();
  }, 500);

}
else if( this.selectone == 5){
  this.TotalmetahaneOtherFCData = this.TotalmetahanforecastData;
  setTimeout(() => {
    this.DrawTotametahaneFCOther();
  }, 500);

}
  
      // ref.drawCOChart1();
      // ref.drawFC2Chart();
  
    });
  }

  getTotametahanePipeline(){
    let ref = this;
    ref.plotChartsService.Totalmetahanepipe(this.selectedDatenext).subscribe((res) => {
      ref.TotalmetahanePipeData = res;
  
      console.log("TotalmetahanePipeData", ref.TotalmetahanePipeData)
      ref.DrawTotametahanePipe();
      // ref.drawCOChart1();
      // ref.drawFC2Chart();
  
    });
  }
  
  // methane_emission_og
  getTotametahanePnuematic(){
    let ref = this;
    ref.plotChartsService.Totalmetahanepmatic(this.selectedDatenext).subscribe((res) => {
      ref.TotalmetahanePnuematicData = res;
  
      console.log("TotalmetahanePnuematicData", ref.TotalmetahanePnuematicData)
      // ref.DrawTotametahaneeMISSION();
      // ref.drawCOChart1();
      // ref.drawFC2Chart();
  
    });
  }


  // methane_pipeline_leakage_og
  // methane_pneumatic_control_og



  // "pipe_leakage_b_tft3": 6.12,

  //       "pipe_leakage_a_tft3": 1.83,

  //       "pipe_leakage_s_tft3": 4.29


  // "pneaumatic_control_b_tft3": 4.2,

  //       "pneaumatic_control_a_tft3": 3.54,

  //       "pneaumatic_control_s_tft3": 0.66

  DrawTotametahaneFCPipe() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePipeFCDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePipeFCData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Forecast (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

    
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pipe_leakage";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Forecast:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFD1A2");
      columnSeries.columns.template.fill = am4core.color("#FFD1A2");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Forecast (Tons)";
      columnSeries.legendSettings.valueText =
        " [#FFD1A2 font-size: 10px] Forecast[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#FFD1A2");
      columnSeries.fill = am4core.color("#FFD1A2");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
     
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }

  DrawTotametahaneFCPnuematic() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePnuematicFCDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePnuematicFCData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Forecast (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pneaumatic_control";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Forecast:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFD1A2");
      columnSeries.columns.template.fill = am4core.color("#FFD1A2");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Forecast (Tons)";
      columnSeries.legendSettings.valueText =
        " [#FFD1A2 font-size: 10px] Forecast[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#FFD1A2");
      columnSeries.fill = am4core.color("#FFD1A2");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
    
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  DrawTotametahaneFCPumps() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePumpsFCDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePumpsFCData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Forecast (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

   
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pumps";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Forecast:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFD1A2");
      columnSeries.columns.template.fill = am4core.color("#FFD1A2");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Forecast (Tons)";
      columnSeries.legendSettings.valueText =
        " [#FFD1A2 font-size: 10px] Forecast[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#FFD1A2");
      columnSeries.fill = am4core.color("#FFD1A2");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
    
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  DrawTotametahaneFCCompressor() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanecompressorFCDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahaneCompressorFCData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Forecast (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

    
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "compressor_station";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Forecast:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFD1A2");
      columnSeries.columns.template.fill = am4core.color("#FFD1A2");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Forecast (Tons)";
      columnSeries.legendSettings.valueText =
        " [#FFD1A2 font-size: 10px] Forecast[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#FFD1A2");
      columnSeries.fill = am4core.color("#FFD1A2");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
     
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  DrawTotametahaneFCOther() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methaneotherFCDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahaneOtherFCData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Forecast (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

     
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "other_equipment";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Forecast:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFD1A2");
      columnSeries.columns.template.fill = am4core.color("#FFD1A2");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Forecast (Tons)";
      columnSeries.legendSettings.valueText =
        " [#FFD1A2 font-size: 10px] Forecast[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#FFD1A2");
      columnSeries.fill = am4core.color("#FFD1A2");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
     
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
































  DrawTotametahanePipe() {
   
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePipeDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePipeData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "After GreenOps (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Before GreenOps (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Savings (Tons)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      // valueAxis3.extraMin = 0.2;
      // valueAxis3.extraMax = 0.2;
      //       valueAxis3.max = 1;
      // valueAxis.strictMinMax = true;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 25;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pipe_leakage_a_tft3";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] After GreenOps:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#9AAFFF");
      columnSeries.columns.template.fill = am4core.color("#9AAFFF");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "After GreenOps (Tons)";
      columnSeries.legendSettings.valueText =
        " [#9AAFFF font-size: 10px] After GreenOps[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#9AAFFF");
      columnSeries.fill = am4core.color("#9AAFFF");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "pipe_leakage_b_tft3";
      lineSeries.dataFields.categoryX = "date";
      // lineSeries.tooltipText = "Before GreenOps" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#00FFC7");
      lineSeries.fill = am4core.color("#00FFC7");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "[font-size: 12px] Before GreenOps:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Before GreenOps (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#00FFC7 font-size:10px] Before GreenOps (Tons)[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "pipe_leakage_s_tft3";
      lineSeries1.dataFields.categoryX = "date";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#b3ff66");
      lineSeries1.fill = am4core.color("#b3ff66");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
      // "[font-size: 12px]{name}:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        " [font-size: 12px]Savings:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Savings (Tons) (m3)";
      lineSeries1.legendSettings.valueText =
        "[#b3ff66 font-size:10px ] Savings [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#00FFC7"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 2;

      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#b3ff66"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 2;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  DrawTotametahanePnuematic(){
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePmaticDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePnuematicData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "After GreenOps (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Before GreenOps (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Savings (Tons)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      // valueAxis3.extraMin = 0.2;
      // valueAxis3.extraMax = 0.2;
      //       valueAxis3.max = 1;
      // valueAxis.strictMinMax = true;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 25;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pneaumatic_control_a_tft3";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] After GreenOps:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#9AAFFF");
      columnSeries.columns.template.fill = am4core.color("#9AAFFF");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "After GreenOps (Tons)";
      columnSeries.legendSettings.valueText =
        " [#9AAFFF font-size: 10px] After GreenOps[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#9AAFFF");
      columnSeries.fill = am4core.color("#9AAFFF");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "pneaumatic_control_b_tft3";
      lineSeries.dataFields.categoryX = "date";
      // lineSeries.tooltipText = "Before GreenOps" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#00FFC7");
      lineSeries.fill = am4core.color("#00FFC7");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "[font-size: 12px] Before GreenOps:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Before GreenOps (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#00FFC7 font-size:10px] Before GreenOps[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "pneaumatic_control_s_tft3";
      lineSeries1.dataFields.categoryX = "date";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#b3ff66");
      lineSeries1.fill = am4core.color("#b3ff66");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
      // "[font-size: 12px]{name}:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        " [font-size: 12px]Savings:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Savings (Tons) (m3)";
      lineSeries1.legendSettings.valueText =
        "[#b3ff66 font-size:10px ] Savings [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#00FFC7"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 2;

      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#b3ff66"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 2;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);

  }

  DrawTotametahanePumps(){
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methanePumpsDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahanePumpsData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "After GreenOps (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Before GreenOps (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Savings (Tons)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      // valueAxis3.extraMin = 0.2;
      // valueAxis3.extraMax = 0.2;
      //       valueAxis3.max = 1;
      // valueAxis.strictMinMax = true;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 25;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "pumps_a_tft3";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] After GreenOps:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#9AAFFF");
      columnSeries.columns.template.fill = am4core.color("#9AAFFF");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "After GreenOps (Tons)";
      columnSeries.legendSettings.valueText =
        " [#9AAFFF font-size: 10px] After GreenOps[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#9AAFFF");
      columnSeries.fill = am4core.color("#9AAFFF");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "pumps_b_tft3";
      lineSeries.dataFields.categoryX = "date";
      // lineSeries.tooltipText = "Before GreenOps" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#00FFC7");
      lineSeries.fill = am4core.color("#00FFC7");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "[font-size: 12px] Before GreenOps:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Before GreenOps (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#00FFC7 font-size:10px] Before GreenOps[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "pumps_s_tft3";
      lineSeries1.dataFields.categoryX = "date";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#b3ff66");
      lineSeries1.fill = am4core.color("#b3ff66");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
      // "[font-size: 12px]{name}:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        " [font-size: 12px]Savings:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Savings (Tons) (m3)";
      lineSeries1.legendSettings.valueText =
        "[#b3ff66 font-size:10px ] Savings [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#00FFC7"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 2;

      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#b3ff66"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 2;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);

  }

  DrawTotametahaneCompressor(){
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methaneCompressorDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahaneCompressorData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "After GreenOps (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Before GreenOps (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Savings (Tons)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      // valueAxis3.extraMin = 0.2;
      // valueAxis3.extraMax = 0.2;
      //       valueAxis3.max = 1;
      // valueAxis.strictMinMax = true;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 25;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "compressor_station_a_tft3";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] After GreenOps:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#9AAFFF");
      columnSeries.columns.template.fill = am4core.color("#9AAFFF");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "After GreenOps (Tons)";
      columnSeries.legendSettings.valueText =
        " [#9AAFFF font-size: 10px] After GreenOps[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#9AAFFF");
      columnSeries.fill = am4core.color("#9AAFFF");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "compressor_station_b_tft3";
      lineSeries.dataFields.categoryX = "date";
      // lineSeries.tooltipText = "Before GreenOps" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#00FFC7");
      lineSeries.fill = am4core.color("#00FFC7");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "[font-size: 12px] Before GreenOps:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Before GreenOps (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#00FFC7 font-size:10px] Before GreenOps[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "compressor_station_s_tft3";
      lineSeries1.dataFields.categoryX = "date";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#b3ff66");
      lineSeries1.fill = am4core.color("#b3ff66");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
      // "[font-size: 12px]{name}:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        " [font-size: 12px]Savings:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Savings (Tons) (m3)";
      lineSeries1.legendSettings.valueText =
        "[#b3ff66 font-size:10px ] Savings [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#00FFC7"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 2;

      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#b3ff66"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 2;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);

  }
  DrawTotametahaneOther(){
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("methaneOtherDiv", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.TotalmetahaneOtherData;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 100;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "After GreenOps (Tons)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Before GreenOps (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Savings (Tons)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      // valueAxis3.extraMin = 0.2;
      // valueAxis3.extraMax = 0.2;
      //       valueAxis3.max = 1;
      // valueAxis.strictMinMax = true;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 25;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0";
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "other_equipment_a_tft3";
      columnSeries.dataFields.categoryX = "date";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 12px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] After GreenOps:{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#9AAFFF");
      columnSeries.columns.template.fill = am4core.color("#9AAFFF");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "After GreenOps (Tons)";
      columnSeries.legendSettings.valueText =
        " [#9AAFFF font-size: 10px] After GreenOps[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#9AAFFF");
      columnSeries.fill = am4core.color("#9AAFFF");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "other_equipment_b_tft3";
      lineSeries.dataFields.categoryX = "date";
      // lineSeries.tooltipText = "Before GreenOps" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#00FFC7");
      lineSeries.fill = am4core.color("#00FFC7");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "[font-size: 12px] Before GreenOps:{valueY.formatNumber('#.0')}";
      lineSeries.name = "Before GreenOps (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#00FFC7 font-size:10px] Before GreenOps[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "other_equipment_s_tft3";
      lineSeries1.dataFields.categoryX = "date";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#b3ff66");
      lineSeries1.fill = am4core.color("#b3ff66");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
      // "[font-size: 12px]{name}:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        " [font-size: 12px]Savings:{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Savings (Tons) (m3)";
      lineSeries1.legendSettings.valueText =
        "[#b3ff66 font-size:10px ] Savings [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#00FFC7"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 2;

      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#b3ff66"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 2;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

 

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);

  }
}