// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-theme11',
//   templateUrl: './theme11.component.html',
//   styleUrls: ['./theme11.component.scss']
// })
// export class Theme11Component implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// import { ngxCsv } from 'ngx-csv/ngx-csv';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/core/utils.service';
// const baseUrl = environment['baseUrl'];
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-theme11',
  templateUrl: './theme11.component.html',
  styleUrls: ['./theme11.component.scss']
})

export class Theme11Component implements OnInit {
  baseUrl='https://api.afr4.0.livnsense.com';

  today: number = Date.now();
  kips: any;
  graphValue: any = [];
  graphValuefc1: any = [];
  fcgraph1: any;
  graphValuefc2: any = [];
  fcgraph2: any;
  selectedDatenext: any;
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  data: any;
  data1: any;
  data2:any;
  data3:any;
  pdfUrl:any;
  pdfUrl1:any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate:  Date = new Date('06/01/2023');
  startdatef:any
  statusval: any =[];
  plantstatusval: any;
  coloval1: string;
  valuestatus1: string;
  checkstatus1: string;
  
  

  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog,private http: HttpClient,
    readonly utils: UtilsService) {
  }

  ngOnInit() {
    this.selectedDatenext = '1660847400';
    // let dte = new Date();
    // this.appliedDateFilters['start_date'] =  dte
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    // this.selectedDate1Next='1660933799';
    this.getKpiValues();
    this.getKpi2Values();
    this.getStatusApival();
    // this.drawDummyChart();
    this.drawFC1Chart();
    this.drawFC2Chart();
    // this.fileDownload2();
    // this.fileDownload3();
    // this.testChart();
  }

  fileDownload() {
    this.ApicallService.getPrevData().subscribe((res) => {
      this.data = res;
    })
    // new ngxCsv(this.data, "Report_Cyc6-Avg-Temp");
  }

  fileDownload1() {
    this.ApicallService.getPrevData1().subscribe((res) => {
      this.data1 = res;
    })
    // new ngxCsv(this.data1, "Report_Cyc6-Outlet-CO");
  }

  // fileDownload2() {
  //   this.ApicallService.getPrevPDFData().subscribe((res) => {
  //     this.data2 = res;
  //     console.log(this.data2)
  //     this.pdfUrl1 = `${this.baseUrl}${this.data2.path}`;
  //   })
  // }

  fileDownload3() {
    this.ApicallService.getPrevPDFData1().subscribe((res) => {
      this.data3 = res;
      this.pdfUrl = `${this.baseUrl}${this.data3.path}`;
      console.log(this.data3,this.pdfUrl)
    })
  }

  getTableGraphValues(){
     this.startdatef =new Date( new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0) ).getTime() / 1000; 
    this.ApicallService.getpdftempdata(this.startdatef).subscribe((res) => {
      this.data2 = res;
      console.log(this.data2,this.appliedDateFilters)
      this.pdfUrl1 = `${this.baseUrl}${this.data2.path}`;
      this.downloadPdf1();
    })
    
   
  }

  getTableGraphValues1(){
    this.startdatef =new Date( new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0) ).getTime() / 1000; 
    this.ApicallService.getpdfcodata(this.startdatef).subscribe((res) => {
      this.data3 = res;
      this.pdfUrl = `${this.baseUrl}${this.data3.path}`;
      console.log(this.data3,this.pdfUrl)
      this.downloadPdf();
    })
   
   
  }

  getStatusApival() {
    this.startdatef =new Date( new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0) ).getTime() / 1000; 
    this.ApicallService.getDaywiseSatus(this.startdatef).subscribe(
      (data: any) => {
        this.statusval = data;
        this.plantstatusval = this.statusval[0].plant_status
        // console.log(this.statusval,this.plantstatusval)
        if (this.statusval[0].plant_status == 'Stable') {
          console.log("rishav1")
          this.checkstatus1 = 'Stable'
            this.coloval1 = '#39FF14'
            this.valuestatus1 = 'enable'
        } else if (this.statusval[0].plant_status == 'Unstable') {
          console.log("rishav2")
          this.checkstatus1 = 'Unstable'
          this.coloval1 = 'orange'
          this.valuestatus1 = 'disable'
        }
        // else if(this.statusval.length == 0){
        //   console.log("rishav",this.statusval)
        //   // this.checkstatus1 = 'null'
        //   // this.coloval1 = 'orange'
        //   // this.valuestatus1 = 'disable'
        // }
      },
      (err) => {
        // (err);

      }
    )
  }

  public downloadPdf() {
    this.http.get(this.pdfUrl, { responseType: 'blob' })
      .subscribe(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Outlet-CO-'+ new Date(this.appliedDateFilters["start_date"]).toLocaleDateString();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  public downloadPdf1() {
    this.http.get(this.pdfUrl1, { responseType: 'blob' })
      .subscribe(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Temperature-'+ new Date(this.appliedDateFilters["start_date"]).toLocaleDateString();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  changeDropdown(types: any) {
    this.selectedDatenext = types.target.value
    // console.log(this.selectedDatenext, "jhv")
    let ref = this;
    ref.plotChartsService.getFC1GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc1 = res;
      console.log(this.graphValuefc1[0].forecast_cyc_6_avg_temp)
      ref.graphValuefc1.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValuefc1)
      ref.drawFC1Chart();
      // ref.testChart();
      // ref.drawFC2Chart();

    });

    ref.plotChartsService.getFC2GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc2 = res;
      ref.graphValuefc2.map((cvalue: any) => {
        let dt2 = cvalue.obs_time;
        let forcast2 = cvalue.forecast_cyc_6_outlet_co;
        let temp2 = cvalue.cyc_6_outlet_co;
      })
      // console.log("jhbh", ref.graphValuefc2)
      ref.drawFC2Chart();
      // ref.drawFC2Chart();

    });

  }

  getKpiValues() {
    this.startdatef =new Date( new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0) ).getTime() / 1000;
    let ref = this;
    ref.plotChartsService.getFC1GraphData(this.startdatef).subscribe((res) => {
      ref.graphValuefc1 = res;

      ref.graphValuefc1.map((cvalue: any) => {
        let dt = cvalue.date_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValuefc1)
      ref.drawFC1Chart();
      // ref.testChart();
      ref.drawFC2Chart();

    });

  }
  getKpi2Values() {
    this.startdatef =new Date( new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0) ).getTime() / 1000;
    let ref = this;
    ref.plotChartsService.getFC2GraphData(this.startdatef).subscribe((res) => {
      ref.graphValuefc2 = res;
      ref.graphValuefc2.map((cvalue: any) => {
        let dt2 = cvalue.date_time;
        let forcast2 = cvalue.forecast_cyc_6_outlet_co;
        let temp2 = cvalue.cyc_6_outlet_co;
      })
      // console.log("jhbh", ref.graphValuefc2)
      ref.drawFC2Chart();
      // ref.drawFC2Chart();

    });
  }

  drawFC1Chart() {

    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color('#1e1e1e');

      // Add data
      chart.data = ref.graphValuefc1;
      chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.min = 870;
      valueAxis.title.text = " Temperature (°C)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.min = 0;
      valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Feed (TPH)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.title.fontSize = 12;
      valueAxis2.title.fontWeight = 'bold';
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color('#fff');
      // valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;

      // valueAxis1.syncWithAxis = valueAxis;


      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "cyc_6_avg_temp";
      series.dataFields.categoryX = "date_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#94b6e6");
      series.fill = am4core.color("#94b6e6");
      series.legendSettings.valueText = "cyc_6_avg_temp [bold][/]";
      series.tooltipText = "Actual" + `:{${"cyc_6_avg_temp"}}`;
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      // series1.columns.template.width = am4core.percent(30);
      // series1.clustered = false;  
      // series1.columns.template.dx = -20;
      series1.dataFields.valueY = "forecast_cyc_6_avg_temp";
      series1.dataFields.categoryX = "date_time";
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#b3ff66");
      series1.fill = am4core.color("#b3ff66");
      series1.tooltipText =
        "Forecasted" + `:{${"forecast_cyc_6_avg_temp"}}`;
      series1.legendSettings.valueText = "forecast_cyc_6_avg_temp [bold][/]";
      // series1.connect = false;

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 3;

      // kiln_feed

       let series2 = chart.series.push(new am4charts.LineSeries());
      // series1.columns.template.width = am4core.percent(30);
      // series1.clustered = false;  
      // series1.columns.template.dx = -20;
      series2.dataFields.valueY = "kiln_feed";
      series2.dataFields.categoryX = "date_time";
      series2.strokeWidth = 2;
      series2.stroke = am4core.color("#ff9225");
      series2.fill = am4core.color("#ff9225");
      series2.tooltipText =
        "Kiln Feed" + `:{${"kiln_feed"}}`;
      series2.legendSettings.valueText = "kiln_feed [bold][/]";
      // series2.connect = false;
      series2.yAxis = valueAxis2;

      let bullet1 = series1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#ff9225");
      let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet1.circle.radius = 3;



      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;


      chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);



  }
  drawFC2Chart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("fc2div", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color('#1e1e1e');
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graphValuefc2;



      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.min = 0;
      valueAxis.max = 600;
      valueAxis.title.text = "CO Level (PPM)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(50);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "cyc_6_outlet_co";
      series.dataFields.categoryX = "date_time";
      series.tooltipText = "Actual" + `:{${"cyc_6_outlet_co"}}`;
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#f8cecc");
      series.fill = am4core.color("#f8cecc");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.valueText = "cyc_6_outlet_co [bold][/]";
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      // series1.columns.template.width = am4core.percent(50);
      // series1.clustered = false;  
      // series1.columns.template.dx = -20;
      series1.dataFields.valueY = "forecast_cyc_6_outlet_co";
      series1.dataFields.categoryX = "date_time";
      series1.tooltipText = "Forecasted" + `:{${"forecast_cyc_6_outlet_co"}}`;
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#b3ff66");
      series1.fill = am4core.color("#b3ff66");
      series1.legendSettings.labelText = "forecast_cyc_6_outlet_co [bold][/]";
      // series1.connect = false;

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 3;


      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;
    
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }




}







