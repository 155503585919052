import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../authenticationn.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authenticationService.currentUserValue;
    let testToken= localStorage.getItem("boilerUser");
    if (testToken==null) {
      // authorised so return true
      return false;
    } else {
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/dmlogin'], { queryParams: { returnUrl: state.url }});
    this.router.navigate(['/dashboard']);
    return false;
  }
}
