import { APiService } from "src/app/core/balco.service";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PlotChartsService } from "src/app/core/charts/plot-charts.service";
import { DialogboxComponent } from "src/app/components/dialogbox/dialogbox.component";
// import { setInterval } from 'timers';
import { UtilsService } from "src/app/core/utils.service";

@Component({
  selector: "app-theme7",
  templateUrl: "./theme7.component.html",
  styleUrls: ["./theme7.component.scss"],
})
export class Theme7Component implements OnInit {
  [x: string]: any;

  Realtimedata: any = [];
  Forecastdata: any = [];
  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  status1: any;
  valuestatus: any;
  checkstatus: any;
  coloval: any;
  Realtimedatadt: any;
  Forecastalertdatadt: any;
  Forecastdatatemp: any;
  ForecastdatatempAvg: any;
  Recommendeddata: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('08/31/2023');
  minDate: Date = new Date("06/01/2023");
  isLoading: boolean = true;
  disablePrevButton: boolean = false;
  disableNextButton: boolean = false;
  parsedObsTime: Date | null = null;

  startDate: any;
  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
    readonly utils: UtilsService
  ) {}

  openDialogfreelime() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 7,
        ApiDate: this.appliedDateFilters["start_date"] },
      }
    );
  }
  openDialogLsf() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 8,   ApiDate: this.appliedDateFilters["start_date"] },
      }
    );
  }
  openDialogSR() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 9 ,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialogAF() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 10,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialog1() {
    this.ApicallService.getForecastAlertTableValues(
      this.selectedDatenext
    ).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: "700px",
          height: "280px",
          data: { TableData1: this.Forecastalertdata, clicked: 1 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},
        });
        dialogRef.afterClosed().subscribe((result) => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);
      }
    );
  }


  changeDate() {
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(
      this.appliedDateFilters["start_date"],
      this.appliedDateFilters.start_date,
      "changedata"
    );
    this.getClinker();
    this.getClinkerRecommended();
    // this.GetGraphs();
  }
  ngOnInit() {
    this.isLoading = true;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      this.appliedDateFilters["start_date"] = this.maxDate;
    }
    console.log(this.appliedDateFilters["start_date"], "date1");

    this.getClinker();
    this.getClinkerRecommended();
    // this.GetGraphs();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
  getClinker() {
    this.startDate =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    console.log(this.startDate, "apidate1");
    this.ApicallService.getClinkerValues(this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;

        console.log(this.ForecastKilndata, this.startDate, "apidate2");
      },
      (err) => {
        err;
      }
    );
  }
  getClinkerRecommended() {
    this.startDate =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    console.log(this.startDate, "apidate1");
    this.ApicallService.getClinkerRecommendedValues(this.startDate).subscribe(
      (data: any) => {
        this.Recommendeddata = data;
        console.log(this.Recommendeddata, this.startDate, "api");

        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "00:00") {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "23:50") {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        err;
      }
    );
  }

  openDialogGraphs(clicked: number, graphData: any) {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: {   clicked: clicked,
          graphData: graphData,
        ApiDate: this.appliedDateFilters["start_date"] },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  GetGraphs(){
    this.plotChartsService.getDialogGraphs(this.startDate).subscribe(
      (data: any) => {
        this.clinkerData = data;
        console.log(
          this.clinkerData,
          this.startDate,
          "ClinkerData"
        );
        this.Freelimedata = this.clinkerData.free_lime;
        this.LSFdata = this.clinkerData.lsf;
        this.SRdata = this.clinkerData.sr;
        this.AFdata = this.clinkerData.af
        this.Accuracy = this.clinkerData.accuracy;
        console.log(
          "ClinkerData", this.Freelimedata, this.LSFdata,
          this.SRdata,  this.AFdata, this.Accuracy
        );
      },
      (err) => {
        err;
      }
    );
  }

 
  onNextclick() {
    console.log(this.startDate, "5mns");
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate);
    this.ApicallService.getClinkerValues(this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;

        console.log(this.ForecastKilndata, this.startDate, "apidate2");
      },
      (err) => {
        err;
      }
    );
    this.ApicallService.getClinkerRecommendedValues(this.startDate).subscribe(
      (data: any) => {
        this.Recommendeddata = data;
        console.log(this.Recommendeddata, this.startDate, "api");

        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "00:00") {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "23:50") {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        err;
      }
    );
  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    console.log(this.selectedDatenext);
    this.selectedForecastDatenext =
      parseInt(this.selectedForecastDatenext) - 300;
    console.log(this.selectedForecastDatenext);
    this.ApicallService.getForecastTableValues(
      this.selectedForecastDatenext
    ).subscribe(
      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    );
    this.getforecastAlertTable();
  }
  onPrevClick() {
    console.log(this.startDate, "5mns");
    this.startDate = parseInt(this.startDate) - 600;
    console.log(this.startDate);
    this.ApicallService.getClinkerValues(this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;

        console.log(this.ForecastKilndata, this.startDate, "apidate2");
      },
      (err) => {
        err;
      }
    );
    this.ApicallService.getClinkerRecommendedValues(this.startDate).subscribe(
      (data: any) => {
        this.Recommendeddata = data;
        console.log(this.Recommendeddata, this.startDate, "api");

        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "00:00") {
          this.disablePrevButton = true;
        } else {
          this.disablePrevButton = false;
        }
        if (this.Recommendeddata.length > 0 && this.Recommendeddata[0].obs_time === "23:50") {
          this.disableNextButton = true;
        } else {
          this.disableNextButton = false;
        }
      },
      (err) => {
        err;
      }
    );
  }
}
