<router-outlet></router-outlet>

<!-- <div class="container" style="padding: 0px;margin: 0px; width: 100%;height: 100%;">
    <div class="header">
   <app-navbar></app-navbar>
    </div>
    <div class="content">
      
      <app-dashboard></app-dashboard> 
    </div>
    <div class="footer">
     <app-footer></app-footer>
    </div>
  </div> -->