<section>
  <div class="mainGridRgt11"
    style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 1px solid;">
    <div class="asImgBoxTop pt-2">
      <div class="asImgBoxTopTxt">KPI Validation</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white;font-size: 2vmin;">Date</mat-label>
          <input style="padding-top: 0.9vmin;color: white;font-size: 2.2vmin;" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
        font-size: 2.3vmin;
        width: 11vmin;
   cursor: pointer;
   background-color: #302c2c;
   outline: unset;
   /* margin-left: 4%; */
   /* padding-top: 2px; */
   border: 1px solid rgba(255, 255, 255, 0.38);
   height: 5vmin;border-radius: 0.9375em !important;
   display:flex;
   justify-content:center;
   align-items:center
   " mat-raised-button color="primary" class="get-sm-btn btn1"
        (click)="getKpiValues();getKpi2Values();getStatusApival();">
        Apply
      </button>
    </div>

  </div>

  <span style="
        font-size: 13px;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
       

      ">

    <div class="theme1PlotBox">
      <div class="row">
        <div class="col-md-12 "
          style="display: flex;justify-content: space-between;align-items: center;font-weight: bold; margin-left: 2px; color:white; font-size: 2.4vmin;border-bottom:1px solid rgba(255, 255, 255, 0.38);padding-bottom: 2.5px;">
          <div>Pre Calciner Temp. - Actual Vs Forecasted
            <span *ngIf="statusval.length != 0" style="margin-left:6px;margin-right:6px;"
              [ngStyle]="{'color': coloval1}">
              {{checkstatus1}}</span>
          </div>
          <span *ngIf="statusval.length == 0" style="margin-left:6px;margin-right:6px;color: white;"> --</span>
          <span style="float: right;">
            <button title="Click to download the reports pdf." style="
            font-size: 2vmin;
            width: 7vmin;
            cursor: pointer;
            background-color: #302c2c !important;
            border-radius: 0.9375em !important;
            outline: unset;
            margin-left: 4%;
            padding-top: 2px;
            border: 1px solid rgba(255, 255, 255, 0.38);
            height: 5vmin;
          " class="get-sm-btn btn2" [disabled]="valuestatus1 == 'disable' || statusval.length == 0"
              (click)="getTableGraphValues();">
              <img src="../../../../assets/images/pdfimg.png" style="width:100%;height: 100%;" />
            </button>

            <!-- <button type="button" (click)="fileDownload()">Prev. Day Report</button> -->
            <!-- <button type="button" (click)="downloadPdf1()">Prev. Day Report</button> -->
          </span>

        </div>
        <div class="col-md-5">

        </div>
      </div>
      <div class="theme1PlotBoxGraph">

        <div id="ppediv">

        </div>
      </div>
    </div>
    <div class="theme1PlotBox1">
      <div class="row">
        <div class="col-md-12 "
          style="display: flex;justify-content: space-between;align-items: center;font-weight: bold; margin-left: 2px; color:white; font-size: 2.4vmin;border-bottom:1px solid rgba(255, 255, 255, 0.38);padding-bottom: 2.5px;">
          <div>Pre Calciner Outlet CO - Actual Vs Forecasted
            <span *ngIf="statusval.length != 0" style="margin-left:6px;margin-right:6px;"
              [ngStyle]="{'color': coloval1}">
              {{checkstatus1}}</span>
          </div>
          <span *ngIf="statusval.length == 0" style="margin-left:6px;margin-right:6px;color: white;"> --</span>
          <span style="float: right;">
            <button title="Click to download the reports pdf." style="
            font-size: 2vmin;
            width: 7vmin;
            cursor: pointer;
            background-color: #302c2c !important;
            border-radius: 0.9375em !important;
            outline: unset;
            margin-left: 4%;
            padding-top: 2px;
            border: 1px solid rgba(255, 255, 255, 0.38);
            height: 5vmin;
              " class="get-sm-btn btn2" [disabled]="valuestatus1 == 'disable' || statusval.length == 0"
              (click)="getTableGraphValues1();">
              <img src="../../../../assets/images/pdfimg.png" style="width:100%;height: 100%;" />
            </button>
            <!-- <button type="button" (click)="fileDownload1()">Prev. Day Report</button> -->
            <!-- <button type="button" (click)="downloadPdf()">Prev. Day Report</button> -->
          </span>

        </div>

      </div>
      <div class="theme1PlotBoxGraph">

        <div id="fc2div">

        </div>
      </div>
    </div>
  </span>
</section>